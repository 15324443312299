import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CustomTabBar from '../../custom/CustomTabBar';
import CustomTab from '../../custom/CustomTab';
import TabPanel from '../../custom/TabPanel';
import PagePaper from '../../custom/PagePaper';
import CaseGeneralTab from './CaseGeneralTab';
import CaseUsersTab from './CaseUsersTab'
import CaseActivityTab from './CaseActivityTab'
import CaseApi from '../../../libs/EdgeVMSCloudApi/Cases'
import CustomCircularProgress from '../../custom/CustomCircularProgress';
import { CASE_SETTINGS } from '../../../libs/EdgeVMSCloudApi/Constants'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

class CaseDetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      caseSettings: {},
      isLoading: true,
      tabIndex: this.getTabIndexFromPathname(this.props.location.pathname),
    }
  }

  async componentDidMount () {
    this.setState({isLoading: true})

    this.unlisten = this.props.history.listen(async (location, action) => {
      if (location.pathname.includes('/mainmenu/exchange/details/')) {
        const tabIndex = this.getTabIndexFromPathname(location.pathname)
        this.setState({tabIndex})
      }
    })

    const response = await CaseApi.FetchSettings(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return response
    }

    this.setState({caseSettings: response.ok && response.body ? response.body : {}}, () => {
      this.setState({isLoading: false})
    })
  }

  componentWillUnmount () {
    if (this.unlisten) {
      this.unlisten()
    }
  }

  getTabIndexFromPathname(pathname) {
    return pathname.includes('/activity') ? 2 :
           pathname.includes('/users') ? 1 : 0
  }

  handleTabChange (event, newValue) {
    const { tabIndex } = this.state
    const pathname = tabIndex === 0 ? this.props.location.pathname : this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/'))

    if (newValue === this.state.tabIndex) {
      return
    }

    if (newValue === 2) {
      this.props.history.push(pathname + '/activity', this.props.location.state)
    } else if (newValue === 1) {
      this.props.history.push(pathname + '/users', this.props.location.state)
    } else {
      this.props.history.push(pathname, this.props.location.state)
    }
  }

  render () {
    const { session, classes, newCase } = this.props
    const { isLoading, caseSettings, tabIndex } = this.state
    const bIsTempUser = session.isLoggedIn && (session.accessToken.decoded.sub.temporary !== undefined) && (session.accessToken.decoded.sub.temporary !== 0)
    const bRestrictView = bIsTempUser && (caseSettings[CASE_SETTINGS.RESTRICT_TEMPORARY_USER] == null || caseSettings[CASE_SETTINGS.RESTRICT_TEMPORARY_USER] === true)

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <PagePaper>
            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                { bRestrictView ? (
                  <Fragment>
                    <CaseGeneralTab {...this.props} classes={{}}/>
                  </Fragment>
                  ) : (
                  <Fragment>
                    <CustomTabBar
                      value={tabIndex}
                      onChange={this.handleTabChange.bind(this)}
                    >
                      <CustomTab id="general" label="General"/>
                      <CustomTab id="users" label="Users" disabled={newCase}/>
                      <CustomTab id="activity" label="Activity" disabled={newCase}/>
                    </CustomTabBar>

                    <TabPanel value={tabIndex} index={0}>
                      <CaseGeneralTab {...this.props} classes={{}}/>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1}>
                      <CaseUsersTab {...this.props} classes={{}}/>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={2}>
                      <CaseActivityTab {...this.props} classes={{}}/>
                    </TabPanel>
                  </Fragment>
                )}
              </Fragment>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(CaseDetails)
