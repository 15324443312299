import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = theme => ({
  button: {
    minWidth: '100px',
  },
  actions: {
    justifyContent: 'center',
  },
})

class InfoDialog extends Component {

  async onCloseClick () {
    await this.props.actionCallback()
  }

  async onOkClick () {
    await this.props.actionCallback()
  }

  render () {
    const { classes, open, title, children} = this.props

    return (
      <Dialog
      disableScrollLock
      open={open}
      onClose={this.onCloseClick.bind(this)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
        <Fragment>
          {title/*optional*/ && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              id="ok-button"
              autoFocus
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={this.onOkClick.bind(this)}
              startIcon={<CheckIcon />}
            >
              Ok
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    )
  }
}

export default withStyles(useStyles)(InfoDialog)
