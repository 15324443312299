import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import IdleTimer from 'react-idle-timer'

class IdleChecker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      logoutTimeout: props.promptTimeout,
    }

    this.logoutTimerId = null
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    clearInterval(this.logoutTimerId);
  }

  tick() {
    let currentTimeout = this.state.logoutTimeout
    if (currentTimeout > 1) {
      currentTimeout--
      this.setState({logoutTimeout: currentTimeout});
    } else {
      clearInterval(this.logoutTimerId);
      this.logoutTimerId = null

      this.setState({open: false})
      this.props.onPromptTimerElapsed()
    }
  }

  onIdle() {
    const { open } = this.state

    if (!open) {
      if (this.logoutTimerId) {
        clearInterval(this.logoutTimerId);
        this.logoutTimerId = null
      }

      this.setState({open: true, logoutTimeout: this.props.promptTimeout})
      this.logoutTimerId = setInterval(() => this.tick(), 1000);
    }
  }

  onContinueClick ()
  {
    if (this.logoutTimerId) {
      clearInterval(this.logoutTimerId);
      this.logoutTimerId = null
    }

    this.setState({open: false})
  }

  render () {
    const { idleTimeoutSec } = this.props
    const { open } = this.state

    return (
      <div>
        <IdleTimer
          timeout={idleTimeoutSec * 1000}
          onIdle={this.onIdle.bind(this)}
        />

        <Dialog
          disableScrollLock
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are You Still Here ?"}</DialogTitle>
          <DialogContent>
            {this.props.logout ?
              <DialogContentText id="alert-dialog-description">
                You will be automatically logged out in {this.state.logoutTimeout} seconds. <br/>
                Press CONTINUE if you wish to remain logged in.
              </DialogContentText> :
              <DialogContentText id="alert-dialog-description">
                You will be automatically redirected in {this.state.logoutTimeout} seconds. <br/>
                Press CONTINUE if you wish to remain on this page.
              </DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.onContinueClick.bind(this)}
              startIcon={<CheckIcon />}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

IdleChecker.propTypes  = {
  idleTimeoutSec: PropTypes.number.isRequired,
  promptTimeout: PropTypes.number.isRequired,
  onPromptTimerElapsed: PropTypes.func.isRequired
};

export default IdleChecker
