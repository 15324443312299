import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import RoleApi from '../../../../libs/EdgeVMSCloudApi/Roles'
import { FetchAccountList } from '../../../../libs/EdgeVMSCloudApi/Accounts'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import RoleActions from './RoleActions'
import { ConfirmGoBack } from '../../../dialogs'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class RoleGeneralTab extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: this.props.newRole ? false : true,
      hasChanged: false,
      hasDataError: false,
      role: {},
      accountResponse:{},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (action === "REPLACE") {
        await this.handleRefresh()
      }
    });

    await this.handleRefresh()
  }

  componentWillUnmount () {
    window.onbeforeunload = null;

    if (this.unlisten) {
      this.unlisten()
    }

    this.abortController.abort();
  }

  async handleRefresh () {
    const { session } = this.props
    let response = {ok: true}

    this.setState({isLoading: true})

    if (!this.props.newRole && this.props.match.params.role) {
      response = await RoleApi.FetchRole(this.signal, this.props.match.params.role)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState ({role: response.ok ? response.body : {}})
    } else {
      this.setState ({role: {}})
    }

    if (response.ok &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ]) {
      response = await FetchAccountList(this.signal)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({accountResponse: response.ok ? response.body : {}})
    } else {
      this.setState({accountResponse: {}})
    }

    this.setState({hasDataError: response.error ? true : false}, () => this.setState({isLoading: false}))
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      hasChanged: true,
     });
  }

  async handleSubmit(event) {
    event.preventDefault()

    let role = {...this.state.role}

    if (this.state.role_name != null) {
      role.name = this.state.role_name
    }

    if (this.state.account_id != null) {
      role.account_id = this.state.account_id
    }

    if (this.state.description != null) {
      role.description = this.state.description
    }

    if (role.description == null) {
      role.description = ""
    }

    this.setState({isLoading: true})
    let response = {}
    if (this.props.newRole) {
      response = await RoleApi.CreateRole(this.signal, role)
    } else {
      response = await RoleApi.UpdateRole(this.signal, role)
    }

    if (response.error && response.error.name === "AbortError") {
      return;
    }

    role = response.ok ? response.body : {}
    if (response.ok) {
      this.setState({
        hasChanged: false,
        name: null,
        account_id: null,
        description: null,
      })

      if (this.props.newRole) {
        this.props.history.replace('/mainmenu/admin/roles/details/' + role.id, this.props.location.state)
      } else {
        this.setState({ role: role })
      }
    }

    this.setState({isLoading: false})
  }

  render () {
    const { classes, session, newRole } = this.props
    const { isLoading, hasChanged, hasDataError, role, accountResponse } = this.state
    const { role_name, description, account_id } = this.state
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    const hasCreatePermission = session.permissions[SERVICES.USER][OPERATIONS.CREATE]
    const hasUpdatePermission = session.permissions[SERVICES.USER][OPERATIONS.UPDATE]
    const allowChanges = (!newRole && hasUpdatePermission) || (newRole && hasCreatePermission)
    const submitButtonText = newRole ? 'Create Role' : 'Submit Changes'

    if (hasChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    let { accounts } = accountResponse
    if (accounts == null) {
      accounts = []
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Toolbar>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
              <Link component="button" variant="h6" href="/" color="inherit" title='Back To Roles'
                onClick={() => originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/admin/roles')}
              >
                Roles
              </Link>
              { (newRole || role.name) &&
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  { newRole ? 'New role' : role.name }
                </Typography>
              }
            </Breadcrumbs>
           { !newRole && role.id &&
              <RoleActions
                dropDownVariant
                roleId={role.id}
                session={session}
                onConfirmedDelete={() => originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/admin/roles')}
              />
            }
           <Tooltip title="Back">
              <span>
                <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                  <ArrowBackIcon />
                </IconButton>
              </span>
            </Tooltip>
           { allowChanges &&
              <Tooltip title={submitButtonText}>
                <span>
                  <IconButton id="submit-icon" disabled={isLoading || !hasChanged} onClick={() => document.getElementById('submit-button').click()}>
                    <PublishIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }
           <Tooltip title="Refresh">
              <span>
                <IconButton id="refresh-icon" disabled={isLoading || newRole} onClick={this.handleRefresh.bind(this)}>
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
         { isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Fragment>
              <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                <fieldset className={classes.fieldset} disabled={hasDataError || !allowChanges}>
                  <Grid container spacing={1}>
                    <Grid container spacing={1} item xs={12}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        margin="normal"
                        required
                        id="role_name"
                        label="Role Name"
                        name="role_name"
                        autoComplete="role_name"
                        onChange={this.handleChange.bind(this)}
                        fullWidth
                        value = {role_name != null ? role_name : role.name ? role.name : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        margin="normal"
                        name="description"
                        label="Description"
                        type="description"
                        id="description"
                        autoComplete="description"
                        onChange={this.handleChange.bind(this)}
                        fullWidth
                        value = {description != null ? description : role.description ? role.description : ''}
                      />
                    </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12}>
                    { accounts.length > 0 &&
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          required
                          margin="normal"
                          id="account_id"
                          label="Account"
                          name="account_id"
                          autoComplete="account_id"
                          onChange={this.handleChange.bind(this)}
                          fullWidth
                          value = {account_id != null ? account_id : role.account_id ? role.account_id : ''}
                          SelectProps={{ native: true }}
                        >
                          <option value=""/>
                          {accounts.map((account) => {
                            return <option key={account.id} value={account.id}>{account.name}</option>
                          })}
                        </TextField>
                      </Grid>
                    }
                  </Grid>
                  </Grid>
                  { allowChanges &&
                    <div className={classes.form}>
                      <Button
                        id="submit-button"
                        startIcon={<PublishIcon/>}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!hasChanged}
                      >
                        {submitButtonText}
                      </Button>
                    </div>
                  }
                </fieldset>
              </form>
            </Fragment>
          )}
        </Container>

        <ConfirmGoBack open={hasChanged}/>

      </div>
    )
  }
}

export default withStyles(useStyles)(RoleGeneralTab)
