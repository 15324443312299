import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import { encodeRFC5987ValueChars } from '../Utils'

const baseUrl = appConfig.apiUri + '/api/m2m/credentials'

async function FetchCredentials(signal, navParams) {
  let url = baseUrl

  if (navParams) {
    const { currentPage, rowsPerPage, searchString, accountFilter } = navParams
    const offset = (currentPage * rowsPerPage)
    url += '?offset=' + offset + '&limit=' + rowsPerPage

    if (accountFilter && accountFilter.length > 0)
    {
      url += ("&account_id=" + accountFilter)
    }

    if (searchString && searchString.length > 0) {
      url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
    }
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function UpdateCredential(signal, credential) {
  let url = baseUrl + '/' + credential.id

  return await BaseApi.DoFetch(signal, url, 'PUT', credential)
}

async function CreateCredential(signal, credential) {
  let url = baseUrl

  return await BaseApi.DoFetch(signal, url, 'POST', credential)
}

async function DeleteCredential(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function AssignUser(signal, credentialId, userId) {
  let url = baseUrl + '/' + credentialId + '/users/' + userId

  return await BaseApi.DoFetch(signal, url, 'POST')
}

async function DeassignUser(signal, credentialId, userId) {
  let url = baseUrl + '/' + credentialId + '/users/' + userId

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

const exportsM2M = {
  FetchCredentials,
  UpdateCredential,
  CreateCredential,
  DeleteCredential,
  AssignUser,
  DeassignUser,
};
export default exportsM2M;

