import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import PermissionsApi from '../../libs/EdgeVMSCloudApi/Permissions'
import RoleApi from '../../libs/EdgeVMSCloudApi/Roles'
import CustomTable from '../custom/CustomTable';
import CustomTableHead from '../custom/CustomTableHead';
import CustomCircularProgress from '../custom/CustomCircularProgress';

import ConfirmAction from './confirmAction'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  systemPermissionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  button: {
    minWidth: '125px',
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  menuItem: {
    minWidth : 100
  },
  menuButton: {
    textTransform: 'none',
    minWidth: 100
  }

});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'name', disablePadding: true, label: 'Permission' },
  { id: 'service', disablePadding: false, label: 'Service' },
  { id: 'operation', disablePadding: false, label: 'Operation' },
];

class ScopeMenu extends Component {
  constructor (props) {
    super(props)

    this.state = {
      confirmChangeScopeOpen: false,
      newScope: null,
      userMenuAnchor: null,
    }
  }

  async onMenuItemClick (newScope) {
    this.setState({
      userMenuAnchor: null,
      newScope : newScope !== this.props.selectedScope ? newScope : null},
      async () => {
        if (this.props.isChecked) {
          if (newScope !== this.props.selectedScope){
            this.setState({confirmChangeScopeOpen: true})
          }
        } else {
          await this.props.updateScopeSelection(newScope)
          this.setState({newScope : null})
        }
      })
  }

  async changeScopeCallback (userAnswer, newScope) {
    this.setState({confirmChangeScopeOpen: false})
    if (userAnswer && userAnswer === 'ok' && newScope) {
      await this.props.updateScopeSelection(newScope)
    }
    this.setState({newScope: null})
  };


  render () {
    const { classes, systemPermission, selectedScope } = this.props
    const { userMenuAnchor, newScope } = this.state

    if (!selectedScope || !systemPermission.scope || !systemPermission.scope.length) {
      return <Fragment>{"N/A"}</Fragment>
    }

    if (systemPermission.scope.length === 1) {
      return <Fragment>{selectedScope}</Fragment>
    }

    return (
      <Fragment>
        <Button
          className={classes.menuButton}
          size={"small"}
          variant='outlined'
          color="inherit"
          endIcon={Boolean(userMenuAnchor) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
          onClick={(event) => this.setState({userMenuAnchor: event.currentTarget})}
        >
          {newScope ? newScope : selectedScope}
        </Button>
        <Menu
          id="actions-menu"
          anchorEl={userMenuAnchor}
          keepMounted
          TransitionComponent={Fade}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(userMenuAnchor)}
          onClose={() => this.setState({userMenuAnchor: null})}
        >
          { systemPermission.scope.map( (scope, key) => {
            return (
              <MenuItem key={key} className={classes.menuItem} onClick={async () => await this.onMenuItemClick(scope)}>
                {scope}
              </MenuItem>
          )})}
        </Menu>

        <ConfirmAction
          title="Changing Permission Scope ?"
          open={this.state.confirmChangeScopeOpen === true}
          actionCallback={this.changeScopeCallback.bind(this)}
          callbackContext={newScope}
        >
          { `Are you sure you want to change "${systemPermission.name}" permission scope from "${selectedScope}" to "${newScope}" ?`}
        </ConfirmAction>

      </Fragment>
    )
  }
}


class SelectPermissionDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      systemPermissions: [],
      rolePermissions: [],
      order: 'asc',
      orderBy: 'service',
      showActionProgress: false,
      confirmDeleteActionOpen: false,
    }

    this.permissionInAction = null
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefresh () {
    const { parentRole } = this.props

    this.setState({isLoading: true})

    let response = await PermissionsApi.FetchPermissionActions(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({ systemPermissions: response.ok ? response.body : [] })

    if (response.ok && parentRole && parentRole.id) {
      response = await RoleApi.FetchRolePermissions(this.signal, parentRole.id, { currentPage: 0, rowsPerPage: 1000 })
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      const rolePermissions = (response.ok && response.body && response.body.permissions) ? response.body.permissions : []
      this.setState({ rolePermissions })
    } else {
      this.setState({ rolePermissions: {} })
    }

    this.setState({ isLoading: false })
  };

  async onRowClick(isChecked, context, scopeSelection) {
    const { parentRole } = this.props

    if (parentRole) {
      if (!isChecked && context.systemPermission) {
        this.setState({isLoading: true})
        let response = await RoleApi.AssignPermission(this.signal, parentRole.id, {
          service   : context.systemPermission.service,
          operation : context.systemPermission.operation,
          rescource : scopeSelection ? scopeSelection : this.GetResourceValue(context.systemPermission, null)
        })

        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({isLoading: false})
        await this.handleRefresh()
      } else {
        if (context.permissionId)
        {
          this.permissionInAction = context
          this.setState({showActionProgress: false})
          this.setState({confirmDeleteActionOpen: true})
        }
      }
    }
  }

  async DeleteActionCallback (userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete) {
      this.setState({showActionProgress: true})

      let response = await RoleApi.DeassignPermission(this.signal, this.props.parentRole.id, toDelete)
      if (response.error && response.error.name === "AbortError") {
        return
      }

      await this.handleRefresh()
    }
    this.setState({confirmDeleteActionOpen: false})
  };

  handleRequestSort (event, property) {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order : isAsc ? 'desc' : 'asc',
      orderBy : property
    })
  };

  async handleScopeChange (isChecked, selectedPermission, systemPermission, scopeSelection) {
    if (isChecked) {
      if (selectedPermission && selectedPermission.id && (selectedPermission.rescource !== scopeSelection)) {
        this.setState({isLoading: true})
        let response = await RoleApi.DeassignPermission(this.signal, this.props.parentRole.id, selectedPermission.id)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        await this.onRowClick(!isChecked, {systemPermission}, scopeSelection)
        this.setState({isLoading: false})
      }
    } else {
      await this.onRowClick(isChecked, {systemPermission}, scopeSelection)
    }
  }

  GetResourceValue(systemPermission, selectedPermission) {
    let selectedScope = undefined
    if (selectedPermission && selectedPermission.rescource && systemPermission.scope.indexOf(selectedPermission.rescource) > -1 ) {
      selectedScope = selectedPermission.rescource
    } else {
      if (systemPermission.scope.length > 0) {
        selectedScope = systemPermission.scope[0]
      }
    }

    return selectedScope
  }

  render () {
    const { classes, session, open, parentRole } = this.props
    const { systemPermissions, rolePermissions, isLoading, order, orderBy } = this.state

    if (!session.isLoggedIn){
      return null
    }

    return (
      <div>
        <Dialog
          fullWidth
          maxWidth='md'
          disableScrollLock
          open={open}
          onEnter={() => this.handleRefresh()}
          onClose={this.props.doneCallback}
        >
          <DialogTitle>Add Permission</DialogTitle>

          <DialogContent>
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CustomCircularProgress/>
            </Backdrop>

            <Box display="flex" justifyContent="flex-end">
              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>

            <CustomTable>
              <CustomTableHead>
                <TableRow selected={true}>
                  <TableCell padding="checkbox" align="center"/>

                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={(event) => this.handleRequestSort(event, headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align="center">Scope</TableCell>
                </TableRow>
              </CustomTableHead>
              { systemPermissions.length ? (
                <TableBody>

                  { stableSort(systemPermissions, getComparator(order, orderBy)).map((systemPermission, key) => {
                    let isChecked = false
                    let permissionId = undefined
                    let selectedPermission = undefined

                    if ( rolePermissions ) {
                      selectedPermission = rolePermissions.find(rolePermission => (systemPermission.service === rolePermission.service) && (systemPermission.operation === rolePermission.operation))

                      if (selectedPermission) {
                        isChecked = true
                        permissionId = selectedPermission.id
                      }
                    } else {
                      return null
                    }

                    return (
                      <TableRow
                        hover
                        key={key}
                        role="checkbox"
                        tabIndex={-1}
                        selected={isChecked}
                      >
                        <TableCell
                          title={systemPermission.description}
                          onClick={this.onRowClick.bind(this, isChecked, {systemPermission, permissionId}, null)}
                          padding="checkbox"
                          align="center"
                        >
                          <Checkbox checked={isChecked} color='primary'/>
                        </TableCell>
                        <TableCell
                          title={systemPermission.description}
                          onClick={this.onRowClick.bind(this, isChecked, {systemPermission, permissionId}, null)}
                          padding="none"
                        >
                          {systemPermission.name}
                        </TableCell>
                        <TableCell
                          title={systemPermission.description}
                          onClick={this.onRowClick.bind(this, isChecked, {systemPermission, permissionId}, null)}
                        >
                          {systemPermission.service}
                        </TableCell>
                        <TableCell
                          title={systemPermission.description}
                          onClick={this.onRowClick.bind(this, isChecked, {systemPermission, permissionId}, null)}
                        >
                          {systemPermission.operation}
                        </TableCell>
                        <TableCell align="center">
                          <ScopeMenu
                            {...this.props}
                            systemPermission={systemPermission}
                            isChecked={isChecked}
                            selectedScope={this.GetResourceValue(systemPermission, selectedPermission)}
                            updateScopeSelection={async (scopeSelection) => await this.handleScopeChange(isChecked, selectedPermission, systemPermission, scopeSelection)}
                          />
                        </TableCell>
                      </TableRow>
                    )})}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={5}>No Permissions</TableCell>
                  </TableRow>
                </TableBody>
              )}

            </CustomTable>
          </DialogContent>
          <DialogActions>
            <Button
              id="done-button"
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={this.props.doneCallback}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmAction
          title="Remove Permission From Role ?"
          open={this.state.confirmDeleteActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to remove permission from role..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.permissionInAction && this.permissionInAction.permissionId ? this.permissionInAction.permissionId : undefined}
        >
          { `Are you sure you want to remove ${this.permissionInAction && this.permissionInAction.systemPermission ? "\"" + this.permissionInAction.systemPermission.name + "\"" : "this"} permission from ${parentRole ? "\"" + parentRole.name + "\"" : ""} role ?`}
        </ConfirmAction>
      </div>
    )
  }
}

SelectPermissionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  parentRole: PropTypes.object.isRequired,
  doneCallback: PropTypes.func,
  showActionProgress: PropTypes.bool,
};

export default withCookies(withStyles(useStyles)(SelectPermissionDialog))
