import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
import { withStyles } from '@material-ui/core/styles';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from '@material-ui/core/Checkbox';
import UsersApi from '../../libs/EdgeVMSCloudApi/Users'
import CustomTable from '../custom/CustomTable';
import CustomTableHead from '../custom/CustomTableHead';
import CustomCircularProgress from '../custom/CustomCircularProgress';
import SearchBox from '../custom/SearchBox'
import { NavTableFooter } from '../table'
import ROWS_PER_PAGE_OPTIONS from '../../constants/navParams'
import { COOKIES } from '../../constants/cookieDefs'
import ConfirmAction from './confirmAction'
import { Typography } from '@material-ui/core';

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  button: {
    minWidth: '125px',
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export const PARENT_TYPES = {
  GROUP: 'group',
  ACCESS_KEY: 'access key',
}

class SelectUserDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      userResponse: {},
      currentPage: '0',
      rowsPerPage: props.cookies.get(COOKIES.ROWS_PER_PAGE.name),
      searchString: '',
      showActionProgress: false,
      confirmDeleteActionOpen: false,
      confirmAssignActionOpen: false,
    }

    this.userInAction = null
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefresh () {
    const { currentPage, rowsPerPage, searchString } = this.state
    const { accountFilter, parentGroup } = this.props

    this.setState({isLoading: true})

    const response = await UsersApi.FetchUserList(this.signal, { currentPage, rowsPerPage, searchString, accountFilter }, parentGroup ? true : false)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({
      userResponse: response.ok ? response.body : {},
      isLoading: false,
    })
  };

  handleRequestSearch (event, value) {
    this.setState({ currentPage: '0', searchString: value.length ? value : "" }, () => this.handleRefresh())
    this.handleRefresh()
  };

  onPageChange(newValue) {
    this.setState({currentPage: newValue.toString()}, () => this.handleRefresh())
  };

  onRowsPerPageChange(newValue) {
    this.setState({rowsPerPage: newValue.toString()}, () => this.handleRefresh())
  };

  async onRowClick(isChecked, user) {
    let response = {}

    if (!isChecked) {
      if (this.props.parentType === PARENT_TYPES.ACCESS_KEY) {
        this.confirmAssignUser(user)
      }
      else if (this.props.assignUser) {
        this.setState({isLoading: true})
        response = await this.props.assignUser(this.signal, user.id)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({isLoading: false})
        await this.handleRefresh()
      }
    } else {
      if (this.props.deassignUser) {
        this.onDeleteActionClick(user)
      }
    }
  }

  onDeleteActionClick (userInAction) {
    if (userInAction)
    {
      this.userInAction = userInAction
      this.setState({showActionProgress: false})
      this.setState({confirmDeleteActionOpen: true})
    }
  }

  async DeleteActionCallback (userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete && toDelete.id) {
      this.setState({showActionProgress: true})

      let response = await this.props.deassignUser(this.signal, toDelete.id)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      if (this.props.parentType === PARENT_TYPES.ACCESS_KEY) {
        this.props.doneCallback()
      }

      await this.handleRefresh()
    }
    this.setState({confirmDeleteActionOpen: false})
  };

  confirmAssignUser (userInAction) {
    if (userInAction) {
      this.userInAction = userInAction
      this.setState({showActionProgress: false})
      this.setState({confirmAssignActionOpen: true})
    }
  }

  async AssignActionCallback (userAnswer, toAssign) {
    if (userAnswer && userAnswer === 'ok' && toAssign && toAssign.id) {
      this.setState({showActionProgress: true})

      let response = await this.props.assignUser(this.signal, toAssign.id)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      if (this.props.parentType === PARENT_TYPES.ACCESS_KEY) {
        this.props.doneCallback()
      }

      await this.handleRefresh()
    }
    this.setState({confirmAssignActionOpen: false})
  };


  render () {
    const { classes, session, accountFilter, open, parentGroup, parentType, parentUserId } = this.props
    const { userResponse, isLoading } = this.state
    const { currentPage, rowsPerPage, searchString } = this.state
    const accessKeyParent = parentType === PARENT_TYPES.ACCESS_KEY

    let { total, offset, users } = userResponse
    if (users == null) {
      users = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    if (!session.isLoggedIn){
      return null
    }

    return (
      <div>
        <Dialog
          fullWidth
          maxWidth='md'
          disableScrollLock
          open={open}
          onEnter={() => this.handleRefresh()}
          onClose={this.props.doneCallback}
        >
          <DialogTitle>{accessKeyParent ? "Assign/Remove User" : "Add User"}</DialogTitle>

          <DialogContent>
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CustomCircularProgress/>
            </Backdrop>

            {accessKeyParent &&
              <Typography>
                Only one user can be assigned to a key.
              </Typography>
            }

            <Box display="flex" justifyContent="flex-end">
              <SearchBox
                idKey={"user-dialog"}
                margin={"dense"}
                className={classes.formControl}
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>

            <CustomTable>
              <CustomTableHead>
                <TableRow selected={true}>
                  <TableCell padding="checkbox">Select</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Username</TableCell>
                </TableRow>
              </CustomTableHead>
              {users.length ? (
                <TableBody>
                  {users.map((user) => {
                    let isChecked = false

                    if (accessKeyParent) {
                      if (parentUserId === user.id) {
                        isChecked = true
                      }
                    } else if (parentGroup && user.groups ) {
                      isChecked = user.groups.findIndex(groupId => groupId === parentGroup.id) > -1
                    } else {
                      return null
                    }

                    if (accountFilter && accountFilter.length) {
                      if (user.account_id.toString() !== accountFilter) {
                        return null
                      }
                    }

                    return (
                      <TableRow
                        hover
                        key={user.id}
                        role="checkbox"
                        tabIndex={-1}
                        onClick={this.onRowClick.bind(this, isChecked, user)}
                        selected={isChecked}
                      >
                        <TableCell padding="checkbox" align="center">
                          <Checkbox checked={isChecked} color='primary'/>
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.username}</TableCell>
                      </TableRow>
                  )})}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={3}>No Users</TableCell>
                  </TableRow>
                </TableBody>
              )}

              <NavTableFooter
                noURLUpdate
                labelRowsPerPage='Users per page'
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={total}
                rowsPerPage={parseInt(rowsPerPage, 10)}
                currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                onRowsPerPageChange={this.onRowsPerPageChange.bind(this)}
                onPageChange={this.onPageChange.bind(this)}
              />
            </CustomTable>
          </DialogContent>
          <DialogActions>
            <Button
              id={accessKeyParent ? "cancel-button" : "done-button"}
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={this.props.doneCallback}
            >
              {accessKeyParent ? "Cancel" : "Done"}
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmAction
          title={`Remove User From ${accessKeyParent ? "Key" : "Group"} ?`}
          open={this.state.confirmDeleteActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg={`Processing request to remove user from ${accessKeyParent ? "key" : "group"}..."`}
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.userInAction}
        >
          {accessKeyParent ?
            `Are you sure you want to remove ${this.userInAction ? "\"" + this.userInAction.name + "\"": "this user"} from this key ?`
            : `Are you sure you want to remove ${this.userInAction ? "\"" + this.userInAction.name + "\"": "this user"} from ${parentGroup ? "\"" + parentGroup.name + "\"" : "this"} group ?`
          }
        </ConfirmAction>

        <ConfirmAction
          title="Assign User To Key ?"
          open={this.state.confirmAssignActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to assign user to key..."
          actionCallback={this.AssignActionCallback.bind(this)}
          callbackContext={this.userInAction}
        >
          {`Are you sure you want to assign ${this.userInAction ? "\"" + this.userInAction.name + "\"": "this user"} to this key ?`}
        </ConfirmAction>

      </div>
    )
  }
}

SelectUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  parentType: PropTypes.string.isRequired,
  parentGroup: PropTypes.object,
  parentUserId: PropTypes.string,
  accountFilter: PropTypes.string,
  assignUser: PropTypes.func,
  deassignUser: PropTypes.func,
  doneCallback: PropTypes.func,
  showActionProgress: PropTypes.bool,
};

export default withCookies(withStyles(useStyles)(SelectUserDialog))
