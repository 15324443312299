import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import { encodeRFC5987ValueChars } from '../Utils'

const baseUrl = appConfig.apiUri + '/api/admin/users'

async function FetchUserList(signal, navParams, includeGroups) {
  const { currentPage, rowsPerPage, searchString, accountFilter } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '?offset=' + offset + '&limit=' + rowsPerPage

  if (navParams.userType === 'tmp') {
    url += '&show_temp=1'
  }

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  if (accountFilter && accountFilter.length > 0) {
    url += ('&account_id=' + accountFilter)
  }

  if (includeGroups) {
    url += ('&include_groups=yes')
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function FetchUserGroups(signal, id, navParams) {
  const { currentPage, rowsPerPage, searchString } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '/' + id + '/groups?offset=' + offset + '&limit=' + rowsPerPage

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}


async function FetchUser(signal, id, includeGroups, flags) {
  let url = baseUrl + '/' + id

  if (includeGroups) {
    url += ('?include_groups=yes')
  }

  return await BaseApi.DoFetch(signal, url, 'GET', null, flags)
}

async function UpdateUser(signal, user) {
  let url = baseUrl + '/' + user.id

  return await BaseApi.DoFetch(signal, url, 'PUT', user)
}

async function CreateUser(signal, user) {
  return await BaseApi.DoFetch(signal, baseUrl, 'POST', user)
}

async function DeleteUser(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function GetUserActivities(signal, navParams) {
  const { rowsPerPage, nextKey, prevKey } = navParams
  let url = baseUrl + '/activities?limit=' + rowsPerPage

  if (nextKey) {
    url += '&after=' + encodeURIComponent(nextKey)
  } else if (prevKey) {
    url += '&before=' + encodeURIComponent(prevKey)
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function DownloadActivityLog(signal, timeLimit) {
  let url = baseUrl + '/activities/download'

  if (timeLimit) {
    const timeLimitValue = timeLimit.toISOString().split('T')[0]
    if (timeLimitValue) {
      url += '?time_limit=' + timeLimitValue
    }
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

const exportsUser = {
  FetchUserList,
  FetchUserGroups,
  FetchUser,
  UpdateUser,
  CreateUser,
  DeleteUser,
  GetUserActivities,
  DownloadActivityLog
};
export default exportsUser;

export {
  FetchUser,
  DeleteUser,
}

