import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/face/collections'

async function FetchCollectionList(signal) {
  return await BaseApi.DoFetch(signal, baseUrl, 'GET')
}

const exportsFace = {
  FetchCollectionList,
};
export default exportsFace;
