import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';

const useStyles = (theme) => ({
  tabBar: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: (theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText),
    marginBottom: theme.spacing(1),
  },
  bigIndicator: {
    height: 5,
  }
});

function CustomTabBar(props) {
  const { classes, children, value, onChange } = props;

  return (
    <Fragment>
      <AppBar
        position="static"
        className={classes.tabBar}
        elevation={0}
      >
        <Tabs
          centered
          classes={{indicator: classes.bigIndicator}}
          indicatorColor="primary"
          value={value}
          onChange={onChange}
        >
          {children}
        </Tabs>
      </AppBar>
    </Fragment>
  );
}

CustomTabBar.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default withStyles(useStyles)(CustomTabBar)