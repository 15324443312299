import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import { ConfirmAction } from '../../dialogs'


function SignOut(props) {
  const [showSignOutConfirm, setShowSignOutConfirm] = useState(true)
  const { doSignOut } = props
  const originLocation = props.location.state ? props.location.state.originLocation : undefined

  const SignOutActionCallback = async (answer) => {
    setShowSignOutConfirm(false)

    if (answer && answer === 'ok') {
      await doSignOut()
    } else {
      props.history.replace(originLocation ? originLocation : '/')
    }
  };

  return (
    <Fragment>
      <ConfirmAction
        title="Leaving ?"
        open={showSignOutConfirm === true}
        actionCallback={SignOutActionCallback}
      >
        Are you sure you want to sign out from EdgeVMS Cloud?
      </ConfirmAction>
    </Fragment>
  )
}

export default withRouter(SignOut)

