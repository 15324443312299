import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
//import FaceIcon from '@material-ui/icons/Face';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BuildIcon from '@material-ui/icons/Build';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { Dashboard, ExchangeRouter, /*Face,*/ AdminRouter, OperationsRouter } from '../views'
import { NotFound } from '../errors/'
import { SERVICES, OPERATIONS } from '../../constants/permissions'

const MenuDefs = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    permissions: [], // unrestricted
  },
  {
    path: "exchange",
    name: "Video Xchange",
    icon: SwitchVideoIcon,
    component: ExchangeRouter,
    permissions: [
      {
        service: SERVICES.CASE,
        operation: OPERATIONS.READ
      }
    ],
  },
  /*
  {
    path: "face",
    name: "Face Recognition",
    icon: FaceIcon,
    component: Face,
    permissions: [
      {
        service: SERVICES.FACE,
        operation: OPERATIONS.READ
      }
    ],
  },
  */
  {
    path: "admin",
    name: "Administration",
    icon: SecurityIcon,
    nestedItems: [
      {
        path: "accounts",
        name: "Account Settings",
        icon: AccountBalanceIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.ACCOUNT,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "users",
        name: "Users",
        icon: PersonIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.USER,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "roles",
        name: "Roles",
        icon: AssignmentIndIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.USER,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "groups",
        name: "Groups",
        icon: GroupIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.USER,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "accesskeys",
        name: "Access Keys",
        icon: VerifiedUserIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.USER,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "licenses",
        name: "Licenses",
        icon: VpnKeyIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.LICENSE,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "notifications",
        name: "Notifications",
        icon: NotificationsIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.MAINTENANCE,
            operation: OPERATIONS.READ
          }
        ],
      },
      {
        path: "activities",
        name: "Activity Log",
        icon: ListAltIcon,
        component: AdminRouter,
        permissions: [
          {
            service: SERVICES.USERACTIVITY,
            operation: OPERATIONS.READ
          }
        ],
      },
    ]
  },
  {
    path: "operations",
    name: "Operations",
    icon: BuildIcon,
    nestedItems: [
      {
        path: "accountcreator",
        name: "Account Creator",
        icon: PowerSettingsNewIcon,
        component: OperationsRouter,
        permissions: [
          {
            service: SERVICES.ACCOUNT,
            operation: OPERATIONS.CREATE
          }
        ],
      },
    ]
  },
];

function GetMenuDef (path) {
  for (let idx = 0; idx < MenuDefs.length; idx++) {
    if (path.includes('/' + MenuDefs[idx].path)) {
      return MenuDefs[idx]
    }
  }
  return null
}

function IsMenuAuthorised (menuDef, session) {
  if (!session.isLoggedIn) {
    return false
  }

  if (menuDef.nestedItems) {
    for (let idx=0; idx<menuDef.nestedItems.length; idx++) {
      const isAuth = IsMenuAuthorised(menuDef.nestedItems[idx], session)
      if (isAuth) {
        return isAuth
      }
    }
    return false
  } else {

    if (menuDef.permissions.length === 0) {
      return true
    }

    for (let idx=0; idx < menuDef.permissions.length; idx++) {
      const service   = menuDef.permissions[idx].service
      const operation = menuDef.permissions[idx].operation
      if (session.permissions[service][operation]) {
        return true
      }
    }
  }

  return false
}

const useStyles = theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class _GetMenuItems extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    const menuDef = GetMenuDef(this.props.pathname)
    if (menuDef) {
      this.setState({ [ menuDef.path ]: true })
    }
  }

  handleClick( item ) {
    this.setState( prevState => (
      { [ item ]: !prevState[ item ] }
    ) )
  }

  render () {
    const { classes, pathname, session } = this.props

    return (
      <MenuList>
        { MenuDefs.map((menuDef) => {
          if (IsMenuAuthorised(menuDef, session)) {
            const topPath = '/mainmenu/' + menuDef.path
            if (menuDef.nestedItems) {
              const open = this.state[menuDef.path]
              return (
                <div key={topPath}>
                  <ListItem id={menuDef.path} key={topPath} button onClick={ () => this.handleClick( menuDef.path ) }>
                    <ListItemIcon>
                      <menuDef.icon />
                    </ListItemIcon>
                    <ListItemText primary={menuDef.name}/>
                    { open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <MenuList component="div" disablePadding>
                      {menuDef.nestedItems.map((nested) => {
                        if (IsMenuAuthorised(nested, session)) {
                          const subPath = topPath + '/' + nested.path
                          return (
                            <MenuItem id={nested.path} className={classes.nested} key={subPath} component={Link} to={subPath} selected={pathname.indexOf(subPath) === 0}>
                              <ListItemIcon>
                                <nested.icon />
                              </ListItemIcon>
                              <ListItemText primary={nested.name}/>
                            </MenuItem>
                          )
                        }
                        return null
                      })}
                    </MenuList>
                  </Collapse>
                </div>
              )
            } else {
              return (
                <MenuItem id={menuDef.path} key={topPath} component={Link} to={topPath} selected={pathname.indexOf(topPath) === 0}>
                  <ListItemIcon>
                    <menuDef.icon />
                  </ListItemIcon>
                  <ListItemText primary={menuDef.name}/>
                </MenuItem>
              )
            }
          }
          return null
        })}
      </MenuList>
    )
  }
}

const GetMenuItems = withStyles(useStyles)(_GetMenuItems);

class GetMenuRoutes extends Component {

  render() {
    const { session } = this.props
    return (
      <Switch>
        {MenuDefs.map((menuDef) => {
          const topPath = '/mainmenu/' + menuDef.path
          if (IsMenuAuthorised(menuDef, session)) {
            if (menuDef.nestedItems) {
              return menuDef.nestedItems.map((nested) => {
                if (IsMenuAuthorised(nested, session)) {
                  const subPath = topPath + '/' + nested.path
                  return (
                    <Route
                      key={subPath}
                      path={subPath}
                      render={(props) => <nested.component {...props} session={session}/>}
                    />
                  )
                }
                return null
              })
            } else {
            return (
              <Route
                key={topPath}
                path={topPath}
                render={(props) => <menuDef.component {...props} session={session}/>}
              />
            )}
          }
          return null
        })}

        <Route component={NotFound} />
      </Switch>
    )}
}


export {
  MenuDefs,
  GetMenuDef,
  IsMenuAuthorised,
  GetMenuItems,
  GetMenuRoutes,
}
