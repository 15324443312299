import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions'
import KeysetPagination from './KeysetPagination';

class NavTableFooter extends Component {

  handleChangePageOffset (event, newPage) {
    if (this.props.onPageChange) {
      this.props.onPageChange(newPage)
    }

    if (!this.props.noURLUpdate) {
      let currentParams = new URLSearchParams(window.location.search)
      currentParams.set('currentPage', newPage)
      currentParams.set('rowsPerPage', this.props.rowsPerPage)
      window.location.search = currentParams.toString()
    }
  };

  handleChangePageKeyset (event, prevKey, nextKey) {
    if (!this.props.noURLUpdate) {
      let currentParams = new URLSearchParams(window.location.search)

      // keyset pagination controls whether "next" or "prev" should appear in URL
      if (prevKey) {
        currentParams.set('prevKey', prevKey)
      } else {
        currentParams.delete('prevKey')
      }
      if (nextKey) {
        currentParams.set('nextKey', nextKey)
      } else {
        currentParams.delete('nextKey')
      }

      currentParams.set('rowsPerPage', this.props.rowsPerPage)
      window.location.search = currentParams.toString()
    }
  };

  handleChangeRowsPerPage (event) {
    if (this.props.onRowsPerPageChange) {
      this.props.onRowsPerPageChange(event.target.value)
    }

    if (!this.props.noURLUpdate) {
      let currentParams = new URLSearchParams(window.location.search)
      currentParams.set('currentPage', '0')
      currentParams.set('rowsPerPage', event.target.value)
      window.location.search = currentParams.toString()
    }
  };

  render() {
    const {
      variant,
      nextkey,
      prevkey,
      labelRowsPerPage,
      rowsPerPageOptions,
      count,
      rowsPerPage,
      currentPage,
      disabled } = this.props

    let keysetVariant = (variant === 'keyset')

    return (
      <TableFooter>
        <TableRow>
          { keysetVariant ? 
          <KeysetPagination
            labelRowsPerPage={labelRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            onChangePage={this.handleChangePageKeyset.bind(this)}
            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
            nextkey={nextkey}
            prevkey={prevkey}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
              disabled: disabled
            }}
          />
          :
          <TablePagination
            labelRowsPerPage={labelRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
              disabled: disabled
            }}
            nextIconButtonProps={{
              disabled: disabled
            }}
            backIconButtonProps={{
              disabled: disabled
            }}
            onChangePage={this.handleChangePageOffset.bind(this)}
            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
            ActionsComponent={TablePaginationActions}
          />
          }
        </TableRow>
      </TableFooter>
    )
  }
}

NavTableFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  labelRowsPerPage: PropTypes.string.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onRowsPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  noURLUpdate: PropTypes.bool,
  variant: PropTypes.string
};

export default NavTableFooter
