import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import PwdResetApi from '../../../libs/EdgeVMSCloudApi/PasswordReset'
import PasswordRequirements from '../../custom/PasswordRequirements'
import IdleChecker from '../../../pages/Home/IdleChecker'
import { InfoDialog } from '../../dialogs'
import { SetPasswordCustomValidity } from '../../../libs/Utils'


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  description: {
    marginBottom: theme.spacing(3),
  },
});

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tokenValid: true,
      user: {},
      error: "",
      alertSuccess: false,
    };

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount() {
    await this.handleRefresh()
  }

  componentWillUnmount () {
    this.abortController.abort();
  }

  async handleRefresh() {
    let tokenValid = false;
    let user = {}
    this.setState({isLoading: true})

    if (this.props.match.params.reset) {

      const response = await PwdResetApi.FetchPasswordReset(this.signal, this.props.match.params.reset);

      if (response.error && response.error.name === "AbortError") {
        return;
      }

      if (response.ok) {
        if (response.body.username) {
          user = {"name": response.body.username}
        }
        // Response ok means token is valid
        tokenValid = true;
      }

    }

    this.setState({
      user: user,
      tokenValid: tokenValid,
    })

    this.setState({isLoading: false})
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({isLoading: true})
    const response = await PwdResetApi.CreateNewPassword(this.signal, this.props.match.params.reset, this.state.newPassword)

    if (response.ok) {
      this.setState({
        alertSuccess: true,
        error: ""
      })
    } else if (response.error && response.error.message) {
      this.setState({error: response.error.message})
      await this.handleRefresh()
    } else {
      this.setState({error: 'Something went wrong.'})
      await this.handleRefresh()
    }

    this.setState({isLoading: false})
  }

  setPasswordConfirmValidity() {
    SetPasswordCustomValidity('newPassword', 'confirm-password');
  }

  infoAck() {
    // Replace instead of push to prevent going back.
    this.props.history.replace("/");
  }

  render () {
    const { classes } = this.props
    const { isLoading, error, user } = this.state

    if (this.props.session && this.props.session.isLoggedIn) {
      return <Redirect to="/mainmenu" />
    }

    let username = "(unknown!)"
    if (user.name) {
      username = user.name;
    }

    return (
      <Container maxWidth="xs">
        <Paper className={classes.paper}>
          <div className={classes.wrapper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            {isLoading && <CircularProgress size={68} className={classes.fabProgress} color="primary"/>}
          </div>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          { (error && (error.length > 0)) &&
            <Box border={2} color="error.main" borderColor="error.main" fontFamily="h6.fontFamily" p={1} mt={1} mb={1} width={1}>
              {error}
            </Box>
          }
          <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
            <Typography className={classes.description}>
              Enter a new password below for {username}.
            </Typography>

            <PasswordRequirements />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="newPassword"
              autoFocus
              onChange={this.handleChange.bind(this)}
              onInput={this.setPasswordConfirmValidity.bind(this)}
              disabled={isLoading}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm-password"
              label="Confirm New Password"
              type="password"
              id="confirm-password"
              autoComplete="confirm-password"
              onChange={this.handleChange.bind(this)}
              onInput={this.setPasswordConfirmValidity.bind(this)}
              disabled={isLoading}
            />

            <Button
              id="submit-button"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              Submit
            </Button>
          </form>
        </Paper>

        <InfoDialog
          open={!this.state.tokenValid}
          actionCallback={this.infoAck.bind(this)}
        >
          This password reset request is invalid or has expired.
          <br />
          Please request a new one from the login page.
        </InfoDialog>

        <InfoDialog
          open={this.state.alertSuccess}
          actionCallback={this.infoAck.bind(this)}
        >
          Password reset successful!
          <br />
          Please login using your new password.
        </InfoDialog>

        <IdleChecker
          idleTimeoutSec={1*60}  // 1 minute
          promptTimeout={10}  // 10 seconds
          onPromptTimerElapsed={this.infoAck.bind(this)}
        />

      </Container>
    );
  }
}

export default withRouter(withStyles(useStyles)(PasswordReset))