import React from 'react'
import { Prompt } from 'react-router-dom'
import ConfirmAction from './confirmAction'
import InfoDialog from './info'
import UploadProgress from './uploadProgress'
import SelectUserDialog from './SelectUserDialog'
import SelectRoleDialog from './SelectRoleDialog'
import SelectPermissionDialog from './SelectPermissionDialog'
import SelectGroupDialog from './SelectGroupDialog'
import PreferencesDialog from './PreferencesDialog'
import ChangePasswordDialog from './ChangePasswordDialog'
import NotificationDialog from './NotificationDialog'
import VideoPlayerDialog from './VideoPlayerDialog'
import PromptDialog from './PromptDialog'
import DownloadActivityLogsDialog from './DownloadActivityLogsDialog'

function ConfirmGoBack (props) {
  const { message } = props

  return (
    <Prompt
      when={props.open}
      message={message ? message : "Are you sure you want to leave this page now?\n\nYou will lose all unsubmitted changes!"}
    />
  )
}

export {
  ConfirmAction,
  ConfirmGoBack,
  InfoDialog,
  UploadProgress,
  SelectUserDialog,
  SelectRoleDialog,
  SelectGroupDialog,
  SelectPermissionDialog,
  PreferencesDialog,
  ChangePasswordDialog,
  NotificationDialog,
  VideoPlayerDialog,
  PromptDialog,
  DownloadActivityLogsDialog
}
