import React, { Fragment } from 'react';
import { withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import { GetMenuDef } from '../../layouts/MenuItems';
import BarChart from '../../custom/BarChart';
import { GetLicenseSetting } from '../../../libs/Utils';
import Title from '../../custom/Title';
import ElevatedPaper from '../../custom/ElevatedPaper'
import PagePaper from '../../custom/PagePaper'


function CollectionsChart (props) {
  const { collections} = props

  let barChartData = {
    datasets: [],
  }

  collections.forEach( (item) => {
    barChartData.datasets.push({
      label: item.name,
      data: item.face_count,
    })
  })

  return (
    <BarChart
      {...props}
      chartData={barChartData}
      barDirection="horizontal"
      barColor="#fa6e1e"
      barLabel="Face Count"
      chartTitle="Face Count per Collection"
    />
  )
}

function FaceStats (props) {
  const { faceCollections, licenseResp, classes } = props
  const menuDef = GetMenuDef('/face')

  if (!faceCollections || !menuDef || !licenseResp) {
    return null
  }

  let enrollPctUsed = 0;
  let collectionPctUsed = 0;
  const maxEnrollLicenceItem = GetLicenseSetting(licenseResp, 'FACE_MAX_ENROLL_FACES');
  const maxCollectionLicenseItem = GetLicenseSetting(licenseResp, 'FACE_MAX_COLLECTION');
  if (maxEnrollLicenceItem) {
    enrollPctUsed = (maxEnrollLicenceItem.value_current * 100) / maxEnrollLicenceItem.value_max;
  }
  if (maxCollectionLicenseItem) {
    collectionPctUsed = (maxCollectionLicenseItem.value_current * 100) / maxCollectionLicenseItem.value_max;
  }

  return (
    <Fragment>
      <Divider className={classes.divider} />

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Title variant="h5">
            {menuDef.name}
          </Title>
        </Grid>

        <Grid item xs={12}>
          <ElevatedPaper>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="inherit">
                  License Limits
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PagePaper>
                  <LinearProgress variant="determinate" value={collectionPctUsed} />
                  <Typography variant="body1" color="inherit">
                    Face Collections: {maxCollectionLicenseItem.value_current} (max: {maxCollectionLicenseItem.value_max})
                  </Typography>
                </PagePaper>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PagePaper>
                  <LinearProgress variant="determinate" value={enrollPctUsed} />
                  <Typography variant="body1" color="inherit">
                    Total Face Count: {maxEnrollLicenceItem.value_current} (max: {maxEnrollLicenceItem.value_max})
                  </Typography>
                </PagePaper>
              </Grid>
            </Grid>
          </ElevatedPaper>
        </Grid>

        <Grid item xs={12}>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <ElevatedPaper>
            <CollectionsChart {...props} collections={faceCollections.collections} />
          </ElevatedPaper>
        </Grid>

      </Grid>
    </Fragment>
  )
}

export default withTheme(FaceStats)
