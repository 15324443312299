import { GetFileSize } from '../libs/Utils'

const LICENSE_DEFS = [
  {
    key: "VE_MAX_OPEN_CASES",
    name: "Maximum Number of Open Cases",
    type: "counter",
    uiType: "spinner",
    min: 0,
    max: 1000,
  },
  {
    key: "VE_MAX_FILE_SIZE",
    name: "Maximum File Size",
    type: "range",
    uiType: "list",
    options: [
      {
        id: 0,
        name: "500 MB",
        value: 500*1024*1024,
      },
      {
        id: 1,
        name: "1 GB",
        value: 1*1024*1024*1024,
      },
      {
        id: 2,
        name: "4 GB",
        value: 4*1024*1024*1024,
      },
    ],
    convertFunc: GetFileSize,
  },
  {
    key: "VE_MAX_FILE_PER_CASE",
    name: "Maximum Number of Files per Case",
    type: "counter",
    uiType: "spinner",
    min: 0,
    max: 1000,
  },
  {
    key: "VE_MAX_CASE_EXPIRATION_DAYS",
    name: "Maximum Case Expiration Period",
    type: "range",
    uiType: "spinner",
    min: 7,
    max: 365,
    suffix: "day(s)"
  },
  /*
  {
    key: "FACE_MAX_COLLECTION",
    name: "Maximum Number of Face Collections",
    type: "counter",
    uiType: "spinner",
    min: 0,
    max: 5,
  },
  {
    key: "FACE_MAX_ENROLL_FACES",
    name: "Maximum Total Number of Enrolled Faces",
    type: "counter",
    uiType: "spinner",
    min: 0,
    max: 20000000,
  },
  */
  /*
  // Example of "list" type, current unused
  {
    key: "VE_FUTURE_1",
    name: "Some future license",
    type: "selection",
    uiType: "list",
    options: [
      {
        id: 0,
        name: "Choice 1",
        value: 0,
      },
      {
        id: 1,
        name: "Choice 2",
        value: 1,
      },
      {
        id: 2,
        name: "Choice 3",
        value: 2,
      },
    ],
  },
  */
];

export default LICENSE_DEFS