import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import { encodeRFC5987ValueChars } from '../Utils'

const baseUrl = appConfig.apiUri + '/api/admin/groups'

async function FetchGroups(signal, navParams, includeRoles) {
  const { currentPage, rowsPerPage, searchString, accountFilter } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '?offset=' + offset + '&limit=' + rowsPerPage

  if (accountFilter && accountFilter.length > 0)
  {
    url += ("&account_id=" + accountFilter)
  }

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  if (includeRoles) {
    url += ('&include_roles=yes')
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function FetchGroup(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function UpdateGroup(signal, group) {
  let url = baseUrl + '/' + group.id

  return await BaseApi.DoFetch(signal, url, 'PUT', group)
}

async function CreateGroup(signal, user) {
  let url = baseUrl

  return await BaseApi.DoFetch(signal, url, 'POST', user)
}

async function DeleteGroup(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function FetchGroupUsers(signal, id, navParams) {
  const { currentPage, rowsPerPage, searchString } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '/' + id + '/users?offset=' + offset + '&limit=' + rowsPerPage

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function AssignUser(signal, groupId, userId) {
  let url = baseUrl + '/' + groupId + '/users/' + userId

  return await BaseApi.DoFetch(signal, url, 'POST')
}

async function DeassignUser(signal, groupId, userId) {
  let url = baseUrl + '/' + groupId + '/users/' + userId

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}


async function FetchGroupRoles(signal, id, navParams) {
  const { currentPage, rowsPerPage, searchString } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '/' + id + '/roles?offset=' + offset + '&limit=' + rowsPerPage

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function AssignRole(signal, groupId, roleId) {
  let url = baseUrl + '/' + groupId + '/roles/' + roleId

  return await BaseApi.DoFetch(signal, url, 'POST')
}

async function DeassignRole(signal, groupId, roleId) {
  let url = baseUrl + '/' + groupId + '/roles/' + roleId

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}


const exportsGroup = {
  FetchGroups,
  FetchGroup,
  UpdateGroup,
  CreateGroup,
  DeleteGroup,
  FetchGroupUsers,
  AssignUser,
  DeassignUser,
  FetchGroupRoles,
  AssignRole,
  DeassignRole,
};
export default exportsGroup;

