import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';

const useStyles = (theme) => ({
   pagePaper: {
      padding: theme.spacing(3, 2),
      overflow: 'auto',
   },
   borderPaper: {
      borderColor: theme.palette.secondary.main,
      borderWidth: "1px",
      borderStyle: "solid",
   },
});

function PagePaper(props) {
   const { classes, children, ...other } = props;

   return (
      <Paper className={clsx(classes.pagePaper, (props.showborder === "true") && classes.borderPaper)} {...other}>
         {children || 'oops!'}
      </Paper>
   );
}

PagePaper.propTypes = {
   showborder: PropTypes.oneOf([ 'true', 'false']),
};
 
export default withStyles(useStyles)(PagePaper)