import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = theme => ({
  progressBar: {
    width: '100%',
    height: 10,
  },
})

class UploadProgress extends Component {

  render () {
    const { classes, open, filename, fileIdx, fileCount, fileProgress, overallProgress } = this.props

    return (
      <Dialog
        fullWidth
        maxWidth='xs'
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
      >
        <DialogTitle>{'Files Upload Progress'}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" noWrap>
            {filename ? 'Uploading file ' + fileIdx + ' of ' + fileCount + ' - ' + filename
                      : 'Waiting for file upload to start...'}
          </Typography>

          <Box mt={2}>
            <Typography variant="subtitle2">{'File upload progress ' + fileProgress + '%'}</Typography>
            <LinearProgress className={classes.progressBar} variant="determinate" value={fileProgress}/>
          </Box>

          <Box mt={2} mb={2}>
            <Typography variant="subtitle2" >{'Overall upload progress ' + overallProgress + '%'}</Typography>
            <LinearProgress className={classes.progressBar} variant="determinate" value={overallProgress} color="secondary"/>
          </Box>

        </DialogContent>
      </Dialog>
    )
  }
}

UploadProgress.propTypes = {
  open: PropTypes.bool.isRequired,
  filename: PropTypes.string.isRequired,
  fileIdx: PropTypes.number.isRequired,
  fileCount: PropTypes.number.isRequired,
  fileProgress: PropTypes.number.isRequired,
  overallProgress: PropTypes.number.isRequired,
};

export default withStyles(useStyles)(UploadProgress)
