function AppConfig() {
  // Default values
  this.apiUri                =  ""
  this.idleTimeoutSec        = (30 * 60) // Set the default (prod mode) idle checker timeout to 30 minutes
  this.isSiteUnderMaintenace = false
  this.isOperationsConsole   = false
  this.enableVideoPlayer     = true
  this.downloadAsMp4         = true

  // Build related overrides
  if (process.env.NODE_ENV !== 'production') {
    this.idleTimeoutSec = (120 * 60) // In dev mode idle checker timeout to 120 minutes
  }

  // Env related overrides
  if (window._env_) {
    this.isSiteUnderMaintenace = window._env_.SITE_UNDER_MAINTENANCE === 'true'
    this.isOperationsConsole   = window._env_.NAMESPACE === 'operations'

    // Workspace related overrides
    if (window._env_.WORKSPACE) {
      if (window._env_.WORKSPACE.toLowerCase().includes('prod')) {
         // NO OVERRIDES FOR NOW...
      }
    }

  }
};

// Create app config and make object properties read only
const appConfig = Object.freeze(new AppConfig())

export default appConfig