import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import CaseApi from '../../../libs/EdgeVMSCloudApi/Cases'
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CustomCircularProgress from '../../custom/CustomCircularProgress';
import { downloadBlob } from '../../../libs/Utils'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  noWrapCell: {
    whiteSpace: 'nowrap',
  },
  descriptionCell: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tableContainer: {
    width: '100%',
    maxHeight: 500,
    overflow: 'auto',
  },
});

class CaseActivityTab extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      hasDataError: false,
      caseObject: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (action === "REPLACE") {
        await this.handleRefresh()
      }
    });

    await this.handleRefresh()
  }

  componentWillUnmount () {
    if (this.unlisten) {
      this.unlisten()
    }

    this.abortController.abort();
  }

  async handleRefresh () {
    if (this.props.match.params.caseid) {
      this.setState({isLoading: true})

      let response = await CaseApi.FetchCase(this.signal, this.props.match.params.caseid)
      if (response.error &&response.error.name === "AbortError") {
        return;
      }

      this.setState ({ caseObject: response.ok ? response.body : {} }, () => {
        this.setState({ hasDataError: response.error ? true : false, isLoading: false})
      })
    } else {
      this.setState({isLoading: false})
    }
  };

  handleDownloadAuditClick = async () => {
    const { caseObject } = this.state
    if (caseObject && caseObject.id) {
      const response = await CaseApi.DownloadAudit(this.signal, caseObject.id)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      if (response.ok) {
        downloadBlob(new Blob([response.text], {type : 'text/csv;charset=utf-8'}), response.filename)
      }
    }
  }

  handleGoHome = () => {
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/exchange')
  }

  render () {
    const { classes, newCase } = this.props
    const { isLoading, caseObject } = this.state

    if (newCase) {
      return null
    }

    let audits = []
    if (caseObject.audits) {
      audits = caseObject.audits
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
            <Toolbar>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
                <Link component="button" variant="h6" href="/" color="inherit" title='Back' onClick={this.handleGoHome}>
                  Video Xchange
                </Link>
                { caseObject.descriptor &&
                  <Link component="button" variant="h6" href="/" color="inherit" title='Back To Details'
                    onClick={() => this.props.history.push(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')), this.props.location.state)}
                  >
                    { caseObject.descriptor }
                  </Link>
                }
                { caseObject.descriptor &&
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    Activity
                  </Typography>
                }
              </Breadcrumbs>

              <Tooltip title="Back">
                <span>
                  <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                    <ArrowBackIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Download">
                <span>
                  <IconButton id="download-audit-icon" disabled={isLoading} onClick={this.handleDownloadAuditClick}>
                    <SaveAltIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader size={'small'}>
                  <TableHead>
                    <TableRow selected={true}>
                      <TableCell>Date</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Activity</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>

                  {audits.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={4}>No activity report for this case</TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {audits.map((audit, key) => {
                        return (
                          <TableRow hover key={key}>
                            <TableCell title={audit.description} className={classes.noWrapCell} component="th" scope="row">{(new Date(audit.timestamp)).toLocaleString('en-US')}</TableCell>
                            <TableCell title={audit.description} className={classes.noWrapCell}>{audit.name}</TableCell>
                            <TableCell title={audit.description} className={classes.noWrapCell}>{audit.activity}</TableCell>
                            <TableCell title={audit.description} className={classes.descriptionCell}>{audit.description}</TableCell>
                          </TableRow>
                      )})}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(CaseActivityTab)
