import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import Session from '../Session'

const baseUrl = appConfig.apiUri + '/api/auth'

async function PreAuth(username) {
  try {
    let url = baseUrl + '/prelogin'
    let data = {'username' : username, 'origin' : window.location.origin}

    const options = {
      'method' : 'POST',
      'redirect' : 'follow',
      'headers' : { 'Content-Type' : 'application/json' },
      'body' : JSON.stringify(data)
    }

    const response = await fetch(url, options)
    if (response.ok) {
      if (response.redirected) {
        return { ok: true, redirect_url:  response.url}
      } else {
        const jsonResp = await response.json()
        return { ok: true, redirect_url: jsonResp.redirect_url }
      }
    } else {
      let errorMsg = 'Got error ' + response.statusText + ' from server!'
      throw new Error(errorMsg)
    }
  } catch (error) {
    console.error(error)
    return {error: error}
  }
}

async function SignIn(username, password) {
  let url = baseUrl + '/login'
  let data = {'username' : username, 'password': password}

  const response = await BaseApi.DoFetch(null, url, 'POST', data, { noErrorDispatch: true, noSession: true })
  if (response.ok) {
    const jsonResp = response.body

    return {
      ok: true,
      passwordExpired: jsonResp.password_expired,
      message: jsonResp.message,
      session: new Session(jsonResp.access_token, jsonResp.refresh_token)
    }

  } else {
    return response
  }
}

async function RefreshSession(session) {
  let url = baseUrl + '/refresh'

  const response = await BaseApi.DoFetch(null, url, 'POST', null, { useRefreshToken: true, noErrorDispatch: true })
  if (response.ok) {
    const jsonResp = response.body

    return {
      ok: true,
      session: new Session(jsonResp.access_token, session.refreshToken.encoded)
    }
  } else {
    return response
  }
}

async function SignOut() {
  // First revoke refresh token. Don't care about return value
  await BaseApi.DoFetch(null, baseUrl + '/logout2', 'POST', null, { noErrorDispatch: true, useRefreshToken: true })

  // Proceed with access token logout.
  return await BaseApi.DoFetch(null, baseUrl + '/logout', 'POST', null, { noErrorDispatch: true })
}

const exportsAuth = {
  PreAuth,
  SignIn,
  RefreshSession,
  SignOut,
};
export default exportsAuth;
