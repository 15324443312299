import React from 'react';
import StepperDialog from "./StepperDialog"
import AccountDetails from '../../views/Admin/Accounts/AccountDetails'

function AccountDialog(props) {
  return (
    <StepperDialog
       {...props}
       StepContent={AccountDetails}
    />
  );
}

export default AccountDialog
