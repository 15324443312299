import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CaseApi from '../../../libs/EdgeVMSCloudApi/Cases'
import LicenseApi from '../../../libs/EdgeVMSCloudApi/Licenses'
import FaceApi from '../../../libs/EdgeVMSCloudApi/Face'
import ExchangeStats from './ExchangeStats'
import FaceStats from './FaceStats'
import Title from '../../custom/Title'
import PagePaper from '../../custom/PagePaper'
import CustomCircularProgress from '../../custom/CustomCircularProgress'
import { SERVICES, OPERATIONS } from '../../../constants/permissions'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  body: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  toolbar: {
    paddingLeft: theme.spacing(0),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

class Dashboard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      dataTimestamp: new Date(),
      caseStats: null,
      licenseResp: null,
      faceCollections: null,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefreshTable () {
    const { session } = this.props;
    let response = null
    this.setState({dataTimestamp: new Date()})
    this.setState({isLoading: true})

    // check user:read permission to show stats
    if (session.permissions[SERVICES.USER][OPERATIONS.READ]) {
      if (session.permissions[SERVICES.CASE][OPERATIONS.READ]) {
        response = await CaseApi.FetchCaseStats(this.signal)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({caseStats: response.ok ? response.body : null})
      }

      if (session.permissions[SERVICES.LICENSE][OPERATIONS.READ]) {
        response = await LicenseApi.FetchAccountLicenses(this.signal, null)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({licenseResp: response.ok ? response.body : null})
      }

      if (session.permissions[SERVICES.FACE][OPERATIONS.READ]) {
        response = await FaceApi.FetchCollectionList(this.signal)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({faceCollections: response.ok ? response.body : null})
      }
    }

    this.setState({isLoading: false})
  }

  render () {
    const { classes, session } = this.props
    const { isLoading, dataTimestamp, caseStats, licenseResp, faceCollections } = this.state
    let greetings = ""

    if (!session.isLoggedIn){
      return null
    }

    if ((caseStats || faceCollections ) && licenseResp) {
      greetings =  "Your services at a glance as of " + dataTimestamp.toDateString() + ", " +dataTimestamp.toLocaleTimeString('en-US')
    } else if (session.permissions[SERVICES.ANY][OPERATIONS.ANY]) {
      greetings = "Use the left menu to navigate to the available services"
    } else {
      greetings = "No services available for you. Contact your system administrator to obtain more permissions."
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <PagePaper>
            <Toolbar className={classes.toolbar}>
              <Title id="dashboardWelcome" variant="h4">
                Welcome {session.accessToken.decoded.sub.username}
              </Title>

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <Typography variant="h6" color="inherit" noWrap className={classes.body}>
                  {greetings}
                </Typography>

                <ExchangeStats {...this.props} caseStats={caseStats} licenseResp={licenseResp}/>
                {false && <FaceStats {...this.props} faceCollections={faceCollections} licenseResp={licenseResp}/>}
              </Fragment>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(Dashboard)
