import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import LicensesApi from '../../../../libs/EdgeVMSCloudApi/Licenses'
import { FetchAccountList } from '../../../../libs/EdgeVMSCloudApi/Accounts'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import SelectBelow from '../../../custom/SelectBelow';
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import LICENSE_DEFS from '../../../../constants/licenseDefs'
import { GetLicenseSetting } from '../../../../libs/Utils'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
});

class Licenses extends Component {
  constructor (props) {
    super(props)

    this.dialogVariant = !!(props.variant && props.variant === "dialog")

    this.state = {
      isLoading: true,
      licenseResponse: [],
      accountResponse: {},
    }

    this.navParams = GetNavParamsFromLocation({accountFilter: ""})
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefreshTable () {
    const { session } = this.props
    this.setState({isLoading: true})

    let response = {ok: true}

    if (!this.dialogVariant) {
      let newAccountFilter = this.navParams.accountFilter

      if (session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
          session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ]) {
        response = await FetchAccountList(this.signal)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        if (response.ok) {
          let { accounts } = response.body
          if (accounts == null) {
            accounts = []
          }

          if (this.navParams.accountFilter.length > 0 && !accounts.find(account => account.id === this.navParams.accountFilter)) {
            newAccountFilter = accounts.length > 0 ? accounts[0].id : ""
          }
        }

        this.setState({ accountResponse: response.ok ? response.body : {} })
      } else {
        newAccountFilter = ""
        this.setState({ accountResponse: {} })
      }

      if (this.navParams.accountFilter !== newAccountFilter) {
        this.navParams.accountFilter = newAccountFilter
      }
    }

    if (response.ok) {
      response = await LicensesApi.FetchAccountLicenses(this.signal, this.dialogVariant ? this.props.dialogContext.accountId : this.navParams.accountFilter)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({licenseResponse: response.ok ? response.body : []})
    } else {
      this.setState({licenseResponse: []})
    }

    this.setState({isLoading: false})
  };

  async handleChangeAccountFilter (event) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('accountFilter', event.target.value)
    window.location.search = currentParams.toString()
  };

  render () {
    const { classes, session } = this.props
    const { licenseResponse, isLoading, accountResponse } = this.state

    let { accounts } = accountResponse
    if (accounts == null) {
      accounts = []
    }

    if (!session.isLoggedIn){
      return null
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant={this.dialogVariant ? "h6" : "h4"}>
                Licenses
              </Title>

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            {!this.dialogVariant &&
              <Box display="flex" justifyContent="flex-end">
                { accounts.length > 0 &&
                  <FormControl className={classes.formControl} disabled={isLoading}>
                    <InputLabel shrink>
                      Show licenses for account
                    </InputLabel>
                    <SelectBelow
                      value={this.navParams.accountFilter}
                      onChange={this.handleChangeAccountFilter.bind(this)}
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">Your Account</MenuItem>
                      {accounts.map((account) => {
                        return <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                      })}
                    </SelectBelow>
                  </FormControl>
                }
              </Box>
            }

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <CustomTable>
                  <CustomTableHead>
                    <TableRow selected={true}>
                      <TableCell>Name</TableCell>
                      <TableCell>Limit/Selection</TableCell>
                      <TableCell>Current Usage</TableCell>
                    </TableRow>
                  </CustomTableHead>

                  {licenseResponse.length ? (
                    <TableBody>
                      {LICENSE_DEFS.map((licenseDef, key) => {
                        let license = GetLicenseSetting(this.state.licenseResponse, licenseDef.key);
                        if (license) {
                          let limitSelectionText = license.value_max;
                          let currentUsageText   = license.value_current;

                          if (licenseDef.convertFunc) {
                            limitSelectionText = licenseDef.convertFunc(limitSelectionText);
                            currentUsageText   = licenseDef.convertFunc(currentUsageText);
                          }

                          if (licenseDef.suffix) {
                            limitSelectionText += " " + licenseDef.suffix;
                            currentUsageText   += " " + licenseDef.suffix;
                          }

                          if (licenseDef.type === 'range') {
                            currentUsageText = "---"
                          }

                          return (
                            <TableRow hover key={license.key}>
                              <TableCell> {licenseDef.name} </TableCell>
                              <TableCell> {limitSelectionText} </TableCell>
                              <TableCell> {currentUsageText} </TableCell>
                            </TableRow>
                          )
                        } else {
                          return null;
                        }
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={3}>(no licenses!)</TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                </CustomTable>
              </Fragment>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(Licenses)
