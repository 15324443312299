import { SET_UPLOAD_STATE } from '../constants/actionTypes'

const uploadState = (state = false, action) => {
  switch (action.type) {
    case SET_UPLOAD_STATE:
      return action.uploadState

    default:
      return state
  }
}

export default uploadState
