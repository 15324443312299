import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import appConfig from '../../config/AppConfig.js'

const useStyles = makeStyles((theme) => ({
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bottom: {
    flexShrink: 1,
    alignSelf: 'flex-end',
    color: 'rgba(255, 255, 255, 0.7)',
    paddingRight: theme.spacing(3),
  },
}));

function CustomDivider(props) {
  const classes = useStyles();
  return (
    <Typography variant="body2" color={props.color} className={classes.divider}>
      |
    </Typography>
  )
}

function Copyright(props) {
  const classes = useStyles();
  return (
    <div className={classes.bottom}>
      <Typography variant="body2" color={props.color}>
        {'Copyright © '}
        <Link color="inherit" underline="always" href="https://www.verint.com/engagement/">
          VERINT
        </Link>
        { ' ' + new Date().getFullYear() + '. All Rights Reserved Worldwide.'}
      </Typography>
    </div>
  );
}

function Version(props) {
  const classes = useStyles();

  return (
    <div className={classes.bottom}>
      <Typography variant="body2" color={props.color}>
        { !appConfig.isSiteUnderMaintenace && "Version: " + process.env.REACT_APP_VERSION }
      </Typography>
    </div>
  );
}

function Legal(props) {
  const classes = useStyles();
  return (
    <div className={classes.bottom}>
      <Box display="flex" justifyContent="flex-end">
        <Link variant="body2" color={props.color} underline="always" href="https://www.verint.com/our-company/legal-documents/intellectual-property/">
          Intellectual Property Notice
        </Link>

        <CustomDivider color={props.color}/>

        <Link variant="body2" color={props.color} underline="always" href="https://www.verint.com/our-company/legal-documents/end-user-license-agreement/">
          End-User License Agreement
        </Link>
      </Box>
    </div>
  );
}

export default function Footer(props) {
  const { session } = props
  const color = session.isLoggedIn ? "textSecondary" : "inherit"

  return (
    <Fragment>
      <Version color={color} />
      <Copyright color={color} />
      <Legal color={color} />
    </Fragment>
  );
}
