import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import UsersApi from '../../../../libs/EdgeVMSCloudApi/Users'
import { FetchAccountList } from '../../../../libs/EdgeVMSCloudApi/Accounts'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import { Forbidden } from '../../../errors'
import { ConfirmGoBack, ConfirmAction } from '../../../dialogs'
import UserActions from './UserActions'


const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(1),
    overflow: 'auto',
  },
  tableWrapper: {
    maxHeight: 240,
    overflow: 'auto',
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
});

class UserGeneralTab extends Component {
  constructor (props) {
    super(props)
    this.dialogVariant = !!(props.variant && props.variant === "dialog")

    this.state = {
      isLoading: this.props.newUser || (this.dialogVariant && !this.props.dialogContext.accountId) ? false : true,
      hasChanged: false,
      hasDataError: false,
      user: {},
      accountResponse:{},
      showDisableProgress: false,
      confirmDisableOpen: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (action === "REPLACE") {
        await this.handleRefresh()
      }
    });

    await this.handleRefresh()
  }

  componentWillUnmount () {
    if (!this.dialogVariant) {
      window.onbeforeunload = null;
    }

    if (this.unlisten) {
      this.unlisten()
    }

    this.abortController.abort();
  }

  confirmDisable() {
    this.setState({showDisableProgress: false})
    this.setState({confirmDisableOpen: true})
  }

  async confirmDisableCallback(userAnswer, callbackContext) {
    if (userAnswer && userAnswer === 'ok') {
      this.setState({showDisableProgress: true})

      await this.doSubmit()
    }

    this.setState({confirmDisableOpen: false})
  }

  async handleRefresh () {
    const { session } = this.props
    let response = {ok: true}

    this.setState({isLoading: true})

    let userId = null
    if (this.dialogVariant) {
      if (this.state.user && this.state.user.id) {
        userId = this.state.user.id
      } else {
        userId = this.props.dialogContext.userId
      }
    } else {
      userId = this.props.match.params.user
    }

    if (!this.props.newUser && userId) {
      response = await UsersApi.FetchUser(this.signal, userId)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState ({user: response.ok ? response.body : {}})
    } else {
      this.setState ({user: {}})
    }

    if (response.ok &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ]) {
      response = await FetchAccountList(this.signal)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({accountResponse: response.ok ? response.body : {}})
    }  else {
      this.setState({accountResponse: {}})
    }

    this.setState({hasDataError: response.error ? true : false}, () => this.setState({isLoading: false}))
    if (this.props.dialogCallback) {
      this.props.dialogCallback({ok: !!response.ok, objectId: userId})
    }
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
      hasChanged: true
    });

    if (this.props.dialogCallback) {
      this.props.dialogCallback({hasChanged: true})
    }
  }

  async handleSubmit(event) {
    event.preventDefault()

    if (this.state.enabled === false) {
      this.confirmDisable()
    } else {
      await this.doSubmit()
    }
  }

  async doSubmit() {
    let values = {}

    if (this.state.user.id) {
      values.id = this.state.user.id
    }

    if (this.state.name != null) {
      values.name = this.state.name
    }

    if (this.dialogVariant) {
      values.account_id = this.props.dialogContext.accountId
    }

    if (this.props.newUser && this.state.account_id != null) {
      values.account_id = this.state.account_id
    }

    if (this.state.email != null) {
      values.email = this.state.email
    }

    if (this.state.username != null) {
      values.username = this.state.username
    }

    if (this.state.enabled != null) {
      values.disabled = !this.state.enabled
    }

    this.setState({isLoading: true})
    let response = {}
    if (this.props.newUser || (this.dialogVariant && !(this.state.user && this.state.user.id))) {
      response = await UsersApi.CreateUser(this.signal, values)
    } else {
      response = await UsersApi.UpdateUser(this.signal, values)
    }

    if (response.error && response.error.name === "AbortError") {
      return;
    }

    const user = response.ok ? response.body : {}
    if (response.ok) {
      this.setState({
        hasChanged: false,
        name: null,
        account_id: null,
        email: null,
        username: null,
      })

      if (this.props.newUser) {
        this.props.history.replace('/mainmenu/admin/users/details/' + user.id, this.props.location.state)
      } else {
        this.setState({ user: user })
      }
    }

    this.setState({isLoading: false})

    if (this.props.dialogCallback) {
      this.props.dialogCallback({ok: !!response.ok, objectId: user.id, hasChanged: response.ok ? false : true})
    }
  }

  setUsernameValidity = (event) => {
    const element = document.getElementById(event.target.id)
    if (event.target.value.includes(' ')) {
      element.setCustomValidity('Spaces are not allowed in username.');
    } else {
      element.setCustomValidity('');
    }
  }

  render () {
    const { classes, session, readOnly } = this.props
    const { isLoading, hasChanged, hasDataError, user, accountResponse } = this.state
    const newUser = this.props.newUser || (this.dialogVariant && !(user && user.id))
    const { name, email, account_id, username, enabled } = this.state
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    const hasCreatePermission = session.permissions[SERVICES.USER][OPERATIONS.CREATE]
    const hasUpdatePermission = session.permissions[SERVICES.USER][OPERATIONS.UPDATE]
    const userId = this.dialogVariant ? this.props.dialogContext.userId : this.props.match.params.user
    const isSelf = session.accessToken.decoded.sub.id === userId
    const allowChanges = !readOnly && !user.federated && ((!newUser && hasUpdatePermission) || (newUser && hasCreatePermission))
    const allowDisable = !readOnly && !user.federated && !newUser && !isSelf
    const submitButtonText = newUser ? 'Create User' : 'Submit Changes'

    if (!this.dialogVariant) {
      if (hasChanged) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    }

    let { accounts } = accountResponse
    if (accounts == null) {
      accounts = []
    }

    if (!newUser && !isLoading && user.temporary !== undefined && user.temporary !== 0) {
      return <Forbidden/>
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Toolbar>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>

              { this.dialogVariant ? (
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Users
                </Typography>
              ) : (
              <Link component="button" variant="h6" href="/" color="inherit" title='Back To Users'
                onClick={() => originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/admin/users')}
              >
                Users
              </Link>
              )}

              { (newUser || user.name) &&
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  { newUser ? 'New user' : user.name }
                </Typography>
              }
            </Breadcrumbs>
            {!newUser && !this.dialogVariant &&
              <UserActions
                dropDownVariant
                disabled={isLoading}
                userId={this.props.match.params.user}
                session={session}
                federated={user.federated}
                onConfirmDelete={() => originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/admin/users')}
              />
            }

            {!this.dialogVariant &&
            <Tooltip title="Back">
              <span>
                <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                  <ArrowBackIcon />
                </IconButton>
              </span>
            </Tooltip>
            }
            { allowChanges &&
              <Tooltip title={submitButtonText}>
                <span>
                  <IconButton id="submit-icon" disabled={isLoading || !hasChanged} onClick={() => document.getElementById('submit-button').click()}>
                    <PublishIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }
            <Tooltip title="Refresh">
              <span>
                <IconButton id="refresh-icon" disabled={isLoading || newUser} onClick={this.handleRefresh.bind(this)}>
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
          { isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Fragment>
              <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                <fieldset className={classes.fieldset} disabled={hasDataError || !allowChanges}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      { newUser &&
                        <Box display="flex" justifyContent="left" alignItems="center">
                          <InfoIcon className={classes.infoIcon}/>
                          New users will receive an email with a temporary password and are required to change the password before they can access the system.
                        </Box>
                      }

                      { user.created_at &&
                        <Typography variant="body1" color="inherit">
                          Created on : {user.created_at ? (new Date(user.created_at)).toLocaleString('en-US') : ''}
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        margin="normal"
                        required
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        onChange={this.handleChange.bind(this)}
                        fullWidth
                        value = {name != null ? name : user.name ? user.name : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        margin="normal"
                        required
                        name="email"
                        label="Email"
                        type="email"
                        id="email"
                        autoComplete="email"
                        onChange={this.handleChange.bind(this)}
                        fullWidth
                        value = {email != null ? email : user.email ? user.email : ''}
                      />
                    </Grid>
                    { newUser && !this.dialogVariant &&
                      accounts.length > 0 &&
                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          disabled={this.dialogVariant}
                          select
                          required
                          margin="normal"
                          id="account_id"
                          label="Account"
                          name="account_id"
                          autoComplete="account_id"
                          onChange={this.handleChange.bind(this)}
                          fullWidth
                          value = {account_id != null ? account_id : user.account_id ? user.account_id : ''}
                          SelectProps={{ native: true }}
                        >
                          <option value=""/>
                          {accounts.map((account) => {
                            return <option key={account.id} value={account.id}>{account.name}</option>
                          })}
                        </TextField>
                      </Grid>
                    }
                    <Grid item xs={12} md={8} lg={4}>
                      <TextField
                        margin="normal"
                        required
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        onChange={this.handleChange.bind(this)}
                        onInput={this.setUsernameValidity}
                        fullWidth
                        value = {username != null ? username : user.username ? user.username : ''}
                      />
                    </Grid>
                    {allowDisable &&
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              name="enabled"
                              id="enabled"
                              checked={enabled != null ? enabled : !user.disabled}
                              onChange={this.handleChange.bind(this)}
                              disabled={!allowChanges}
                            />
                          }
                          label="User account enabled"
                        />
                      </Grid>
                    }
                  </Grid>
                  { allowChanges &&
                    <div className={classes.form}>
                      <Button
                        id="submit-button"
                        startIcon={<PublishIcon/>}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!hasChanged}
                      >
                        {submitButtonText}
                      </Button>
                    </div>
                  }
                </fieldset>
              </form>
            </Fragment>
          )}
        </Container>

        { !this.dialogVariant &&
          <ConfirmGoBack
            open={hasChanged}
          />
        }

        <ConfirmAction
          title="Disable User ?"
          open={this.state.confirmDisableOpen}
          showProgress={this.state.showDisableProgress}
          progressMsg="Processing request to disable user..."
          actionCallback={this.confirmDisableCallback.bind(this)}
        >
          Are you sure you want to disable this user account?<br/>
          This will prevent the user from accessing the site.
        </ConfirmAction>

      </div>
    )
  }
}

export default withStyles(useStyles)(UserGeneralTab)
