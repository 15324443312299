import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import { encodeRFC5987ValueChars } from '../Utils'

const baseUrl = appConfig.apiUri + '/api/admin/roles'

async function FetchRoles(signal, navParams, includeGroups) {
  const { currentPage, rowsPerPage, searchString, accountFilter } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '?offset=' + offset + '&limit=' + rowsPerPage

  if (accountFilter && accountFilter.length > 0)
  {
    url += ("&account_id=" + accountFilter)
  }

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  if (includeGroups) {
    url += ('&include_groups=yes')
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function FetchRole(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function UpdateRole(signal, role) {
  let url = baseUrl + '/' + role.id

  return await BaseApi.DoFetch(signal, url, 'PUT', role)
}

async function CreateRole(signal, role) {
  return await BaseApi.DoFetch(signal, baseUrl, 'POST', role)
}

async function DeleteRole(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function FetchRoleGroups(signal, id, navParams) {
  const { currentPage, rowsPerPage, searchString } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '/' + id + '/groups?offset=' + offset + '&limit=' + rowsPerPage

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function FetchRolePermissions(signal, id, navParams) {
  const { currentPage, rowsPerPage, searchString } = navParams
  const offset = (currentPage * rowsPerPage)
  let url = baseUrl + '/' + id + '/permissions?offset=' + offset + '&limit=' + rowsPerPage

  if (searchString && searchString.length > 0) {
    url += ('&search_value=' + encodeRFC5987ValueChars(searchString))
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function AssignPermission(signal, roleId, permission) {
  let url = baseUrl + '/' + roleId + '/permissions'

  return await BaseApi.DoFetch(signal, url, 'POST', permission)
}

async function DeassignPermission(signal, roleId, permissionId) {
  let url = baseUrl + '/' + roleId + '/permissions/' + permissionId

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

const exportsRole = {
  FetchRoles,
  FetchRole,
  UpdateRole,
  CreateRole,
  DeleteRole,
  FetchRoleGroups,
  FetchRolePermissions,
  AssignPermission,
  DeassignPermission,
};
export default exportsRole;

