import React from 'react';
import PagePaper from './PagePaper';

function ElevatedPaper(props) {
   const { children, ...other } = props;

   return (
      <PagePaper elevation={3} {...other}>
         {children || 'oops!'}
      </PagePaper>
   );
}

export default ElevatedPaper;