import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Forbidden } from '../../../errors'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import { ConfirmAction, ConfirmGoBack, InfoDialog } from '../../../dialogs'
import { GetDefaultCookiesOptions } from '../../../../constants/cookieDefs'
import StepperDialogs from '../../../dialogs/stepper'
import { FetchAccount, DeleteAccount } from '../../../../libs/EdgeVMSCloudApi/Accounts'
import { FetchUser, DeleteUser } from '../../../../libs/EdgeVMSCloudApi/Users'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  revertButton: {
    marginRight: theme.spacing(1),
    minWidth: '100px',
  },
  instructions: {
    borderColor: theme.palette.secondary.main,
    borderWidth: "1px",
    borderStyle: "solid",
 },
});

const ACCOUNT_CREATOR_CONTEXT_COOKIE = 'account_creator_context'

async function validateAccount(signal, context) {
  let response = {ok: true}

  if (context && context.accountId) {
    response = await FetchAccount(signal, context.accountId, {noErrorDispatch: true})
  }

  return response
}

async function validateUser(signal, context) {
  let response = {ok: true}

  if (context && context.userId) {
    response = await FetchUser(signal, context.userId, false, {noErrorDispatch: true})
  }

  return response
}

async function revertAccount(signal, context) {
  let response = {ok: true}

  if (context && context.accountId) {
    response = await DeleteAccount(signal, context.accountId)
  }

  return response
}

async function revertUser(signal, context) {
  let response = {ok: true}

  if (context && context.userId) {
    response = await DeleteUser(signal, context.userId)
  }

  return response
}

const steps = [
  {
    label: 'Create account',
    instructions: 'This step allows you to create and adjust the new account configuration.',
    dialog: StepperDialogs.AccountDialog,
    objectId: 'accountId',
    validator: validateAccount,
    revert: revertAccount,
  },
  {
    label: 'Validate account license',
    instructions: 'Use this step to validate the new account license and make any adjustments if required.',
    dialog: StepperDialogs.LicenseEditorDialog,
  },
  {
    label: 'Create account admin user',
    instructions: 'This step allows you to create the admin user for the new account. IMPORTANT: This user will be automatically added to "Account Admin" group (only after creation).',
    dialog: StepperDialogs.UserDialog,
    objectId: 'userId',
    validator: validateUser,
    revert: revertUser,
  },
  {
    label: 'Review and Complete',
    instructions: 'Use this step to review and complete this process.',
    dialog: StepperDialogs.ReviewAndCompleteDialog,
  },
];

class AccountCreator extends Component {
  constructor (props) {
    super(props)

    this.state = {
      context: {
        activeStep: 0,
        previousStep: 0,
      },
      openStepDialog: false,
      openConfirmResume: false,
      openConfirmRevert: false,
      openFinalDialog: false,
      showRevertProgress: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    const contextCookie = this.props.cookies.get(ACCOUNT_CREATOR_CONTEXT_COOKIE)

    if (contextCookie && (contextCookie.activeStep > 0 || contextCookie.previousStep > 0)) {
      let isContextValid = true

      for (let idx = 0; idx < steps.length; idx++) {
        if (steps[idx].validator) {
          const response = await steps[idx].validator(this.signal, contextCookie)
          if (response.error && response.error.name === "AbortError") {
            return;
          }

          if (response.error) {
            isContextValid = false
            break
          }
        }
      }

      if (isContextValid) {
        this.setState({openConfirmResume: true})
      } else {
        this.resetContext()
      }
    }
  }

  componentWillUnmount () {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  updateContext = (update) => {
    this.setState((state) => {
      let previousStep = state.context.previousStep
      if (update.activeStep != null) {
        previousStep = state.context.activeStep
      }

      return ({context: {...state.context, previousStep, ...update}})
    }, () => {
      this.props.cookies.set(ACCOUNT_CREATOR_CONTEXT_COOKIE, this.state.context, GetDefaultCookiesOptions())
    })
  }

  setActiveStep = (nextStep) => {
    if (nextStep >= 0 && nextStep < steps.length) {
      this.updateContext({activeStep: nextStep})
    }
  }

  handlePreviousStep = (event) => {
    const {context: {activeStep}} = this.state
    this.setActiveStep(activeStep - 1)
  }

  handleNextStep = (event) => {
    this.setState({ openStepDialog: true })
  }

  handleStepComplete = (response) => {
    const {context: {activeStep}} = this.state
    const step = steps[activeStep]

    this.setState({ openStepDialog: false })

    if (response.ok && ((step.objectId == null) || (step.objectId && response.objectId))) {
      if (step.objectId && response.objectId) {
        this.updateContext({[step.objectId]: response.objectId})
      }

      if (activeStep < steps.length - 1) {
        this.setActiveStep(activeStep + 1)
      } else {
        this.setState({openFinalDialog: true})
      }
    }
  }

  resetContext = () => {
    this.setState({context: {activeStep: 0, previousStep: 0,}}, () => this.updateContext({}))
  }

  handleRevert = () => {
    this.setState({showRevertProgress: false}, () => {
      this.setState({openConfirmRevert: true})
    })
  }

  confirmRevertCallback = async (userAnswer, callbackContext) => {
    if (userAnswer && userAnswer === 'ok') {
      this.setState({showRevertProgress: true}, async () => {
        for (let idx = steps.length - 1; idx >= 0; idx--) {
          if (steps[idx].revert) {
            const response = await steps[idx].revert(this.signal, this.state.context)
            if (response.error && response.error.name === "AbortError") {
              return;
            }
          }
        }

        this.resetContext()
        this.setState({openConfirmRevert: false})
      })
    } else {
      this.setState({openConfirmRevert: false})
    }
  }

  confirmResumeCallback = (userAnswer, callbackContext) => {
    let currentContext = this.state.context

    if (userAnswer && userAnswer === 'ok') {
      const contextCookie = this.props.cookies.get(ACCOUNT_CREATOR_CONTEXT_COOKIE)

      if (contextCookie) {
        currentContext = contextCookie
      }
    }

    this.updateContext(currentContext)
    this.setState({openConfirmResume: false})
  }

  render () {
    const { classes, session } = this.props
    const { context: { activeStep, previousStep } } = this.state
    const hasChanged = activeStep > 0 || previousStep > 0
    let step = null

    if (hasChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    if (!session.isLoggedIn){
      return <Forbidden />
    }

    if (activeStep < steps.length) {
      step = steps[activeStep]
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Account Creator
              </Title>
            </Toolbar>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper alternativeLabel activeStep={activeStep}>
                  { steps.map((step) => (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                  ))}
                </Stepper>
              </Grid>

              { step && step.instructions &&
                <Grid item xs={12} className={classes.instructions}>
                  <Typography>
                    {"Instructions: "}
                    { step.instructions }
                  </Typography>
                </Grid>
              }

              <Grid item xs={12}>
                <Typography>
                  {`Press ${activeStep === 0 ? previousStep === 0 ? "START" : "CONTINUE" : activeStep === steps.length - 1 ? "COMPLETE" :"NEXT"} button to proceed with step ${activeStep+1} ...`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  id="revert-button"
                  className={classes.revertButton}
                  disabled={activeStep === 0 && previousStep === 0}
                  variant="contained"
                  color="primary"
                  onClick={this.handleRevert}
                >
                  Revert
                </Button>

                <Button
                  id="back-button"
                  className={classes.revertButton}
                  disabled={activeStep === 0}
                  variant="contained"
                  color="primary"
                  onClick={this.handlePreviousStep}
                >
                  Back
                </Button>

                <Button
                  id="forward-button"
                  className={classes.revertButton}
                  disabled={activeStep >= steps.length}
                  variant="contained"
                  color="primary"
                  onClick={this.handleNextStep}
                >
                  {
                    activeStep === 0 ? previousStep === 0 ? "Start" : "Continue" :
                    activeStep === steps.length - 1 ? "Complete" : "Next"
                  }
                </Button>

              </Grid>
            </Grid>

          </PagePaper>
        </Container>

        { step && step.dialog &&
          <step.dialog
            {...this.props}
            classes={null}
            title={step.label}
            open={this.state.openStepDialog}
            dialogContext={this.state.context}
            handleClose={this.handleStepComplete}
          />
        }

        <ConfirmAction
          yesno
          title="Resume Account Creation ?"
          open={this.state.openConfirmResume}
          actionCallback={this.confirmResumeCallback}
        >
          It looks like there is an unfinished account creation process...
          <br/><br/>
          Click YES if you want to resume where you left off.
          <br/>
          Click NO to start a new account creation process.
        </ConfirmAction>

        <ConfirmAction
          yesno
          title="Revert Account Creation ?"
          open={this.state.openConfirmRevert}
          showProgress={this.state.showRevertProgress}
          progressMsg="Reverting account creation..."
          actionCallback={this.confirmRevertCallback}
        >
          Are you sure you want to revert account creation process?
          <br/>
          All the created resources will be deleted!
        </ConfirmAction>

        <InfoDialog
          open={this.state.openFinalDialog}
          title={"Finished"}
          actionCallback={() => this.setState({openFinalDialog: false}, () => this.resetContext())}
        >
          Account creation process completed successfully.
        </InfoDialog>

        <ConfirmGoBack
          open={hasChanged}
          message={"You are about to leave the Account Creator page before you have complete the entire process!\n\nNote that you will be able to resume the process if you come back to this tool."}
        />

      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(AccountCreator))
