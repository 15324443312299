import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/admin/permissions'

async function FetchPermissionActions(signal) {
  let url = baseUrl + '/actions'

  return await BaseApi.DoFetch(signal, url, 'GET')
}

const exportsPermission = {
  FetchPermissionActions,
};
export default exportsPermission;

