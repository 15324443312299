import React from 'react';
import { withTheme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Bar, HorizontalBar} from 'react-chartjs-2';
import { HexToRgb } from '../../libs/Utils'

// # of steps to use based on the leading digit of the scale maximum.
const NUM_SCALE_STEPS = [5, 5, 4, 6, 4, 5, 6, 7, 4, 3, 5];

function BarChart (props) {
  const { classes, chartData, barColor, barLabel, chartTitle, barDirection } = props
  const myTheme = useTheme()
  const paletteTypeFontColor = (myTheme.palette.type === "dark" ? "white" : "black")

  let data = {
    labels: [],
    datasets: [
      {
        label: barLabel,
        backgroundColor: HexToRgb(barColor, 1),
        borderColor: HexToRgb(barColor, 1),
        borderWidth: 1,
        hoverBackgroundColor: HexToRgb(barColor, 0.4),
        hoverBorderColor: HexToRgb(barColor, 1),
        data: []
      }
    ],
  }

  let largestValue = 0;
  chartData.datasets.forEach( (item) => {
    data.labels.push(item.label)
    data.datasets[0].data.push(item.data)
    // For dynamic scale max, we need to know the largest value in the dataset.
    if (item.data > largestValue) {
      largestValue = item.data;
    }
  })

  let scaleMax = 10;
  let numSteps = 5;
  if (props.scaleMax) {
    // Caller has specified the scale maximum to use.
    scaleMax = props.scaleMax;
    let leadingDigit = scaleMax;
    while (leadingDigit > 10) {
      leadingDigit /= 10;
    }
    numSteps = NUM_SCALE_STEPS[leadingDigit];
  } else {
    // Dynamically calculate the scale maximum to use.

    // Calculate the power of 10 of the largest value.
    let exponent = 0;
    scaleMax = largestValue
    while (scaleMax > 10) {
      ++exponent;
      scaleMax /= 10;
    }
    // Start the scaleMax at the next highest power of 10.
    scaleMax = 10 ** (exponent + 1);
    let leadingDigit = 10;
    // Do not scale lower than a max of 10.
    if (scaleMax > 10) {
      // Lower the scaleMax by nice round numbers until it is no larger than 50% above
      // the largest value, making sure we don't drop below (2 * 10^n).
      while ((scaleMax > (1.5 * largestValue)) && (leadingDigit > 2)) {
        --leadingDigit;
        scaleMax = leadingDigit * (10 ** exponent);
      }
    }
    numSteps = NUM_SCALE_STEPS[leadingDigit];
  }

  let dynamicOptions = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          fontColor: paletteTypeFontColor,
        },
        ticks: {
          fontColor: paletteTypeFontColor,
          beginAtZero: true,
          min: 0,
          max: scaleMax,
          stepSize: (scaleMax / numSteps),
        }
      }],
      yAxes: [{
        scaleLabel: {
          fontColor: paletteTypeFontColor,
        },
        ticks: {
          fontColor: paletteTypeFontColor,
          beginAtZero: true,
          min: 0,
          max: scaleMax,
          stepSize: (scaleMax / numSteps),
        }
      }]
    }
  }

  return (
    <div className={classes.text}>
      <Typography variant="h6" color="inherit" noWrap align="center">
        {chartTitle}
      </Typography>

      {barDirection === "horizontal" ?
        <HorizontalBar data={data} options={dynamicOptions}/> :
        <Bar data={data} options={dynamicOptions}/>}
    </div>
  )
}

export default withTheme(BarChart)