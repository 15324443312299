import { SET_PALETTE_TYPE } from '../constants/actionTypes'
import { COOKIES } from '../constants/cookieDefs'

const paletteType = (state = COOKIES.APP_PALETTE.default, action) => {
  switch (action.type) {
    case SET_PALETTE_TYPE:
      return (action.paletteType)

    default:
      return state
  }
}

export default paletteType
