import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DialogTitle, DialogContent, DialogActions } from '../../../custom/CustomDialogs'
import { downloadBlob } from '../../../../libs/Utils'
import copy from 'clipboard-copy'


export default function ShowAccessKeyDialog(props) {
  const theme = useTheme();
  const [showSecret, setShowSecret] = useState(false);
  const [showSecretToolTip, setShowSecretToolTip] = useState(false);
  const { open, handleClose, newAccessKey } = props

  const onDownloadClick = () => {
    const filename = 'access_key_' + newAccessKey.key_id + '.csv'
    const description = newAccessKey.description ? newAccessKey.description : ''

    const csv  = 'Access key ID,Access key secret,Access key description\n' +
                newAccessKey.key_id + ',' + newAccessKey.secret + ',' + description + '\n'

    downloadBlob(new Blob([csv], {type : 'text/csv;charset=utf-8'}), filename)
  }

  if (!newAccessKey || !newAccessKey.key_id || !newAccessKey.secret) {
    return null
  }

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose}>
          New Access Key
        </DialogTitle>
        <DialogContent dividers>

          <Box border={2} color="green" borderColor="green" p={1} display="flex" alignItems="center">
            <CheckCircleIcon style={{marginRight: theme.spacing(2)}}/>
            <Typography variant="h6" gutterBottom>Your new access key is available !</Typography>
          </Box>

          <br/>

          <Typography component="div">
            <Box color="orange" fontStyle="italic" fontWeight={theme.typography.fontWeightBold} display="flex" justifyContent="center" alignItems="center">
              <WarningIcon style={{marginRight: theme.spacing(2)}}/>
              Save or download your access key now.
              <WarningIcon style={{marginLeft: theme.spacing(2)}}/>
            </Box>
          </Typography>

          <br/>

          <Typography>
            This is the ONLY time the access key secret can be viewed or downloaded.
            You cannot recover it later. However you can create other access keys later.
            <br />
            <br />
            For your protection, you should never share your access key secret with anyone.
          </Typography>

          <br/>

          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <Typography component="div">
                <Box fontWeight={theme.typography.fontWeightBold}>Key ID:</Box>
              </Typography>
              <Typography component="div">
                <Box fontWeight={theme.typography.fontWeightBold}>Secret:</Box>
              </Typography>
            </Box>

            <Box p={1}>
              <Box display="flex" flexDirection="row">
                <Typography>{newAccessKey.key_id}</Typography>
                <Tooltip title="Copy to Clipboard">
                  <IconButton
                    id="copy-key-id-to-clipboard-icon"
                    size={'small'}
                    onClick={async () => await copy(newAccessKey.key_id)}
                  >
                    <FileCopyIcon fontSize={'small'}/>
                  </IconButton>
                </Tooltip>
              </Box>

              <Box display="flex" flexDirection="row">
                <Typography>{showSecret ? newAccessKey.secret : "**********"}</Typography>

                <Tooltip title={ showSecret ? "Hide Secret" : "Show Secret" } open={showSecretToolTip}>
                  <span>
                    <IconButton
                      id="secret-visibility-icon"
                      size={'small'}
                      onClick={() => {
                        setShowSecret(!showSecret)
                        setShowSecretToolTip(false)
                      }}
                      onMouseEnter={() => setShowSecretToolTip(true)}
                      onMouseLeave={() => setShowSecretToolTip(false)}
                    >
                      {showSecret ? <VisibilityOffIcon fontSize={'small'}/> : <VisibilityIcon fontSize={'small'}/>}
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Copy to Clipboard">
                  <span>
                    <IconButton
                      id="copy-secret-to-clipboard-icon"
                      size={'small'}
                      onClick={async () => await copy(newAccessKey.secret)}
                    >
                      <FileCopyIcon fontSize={'small'}/>
                    </IconButton>
                  </span>
                </Tooltip>

              </Box>

            </Box>
          </Box>

          </DialogContent>
        <DialogActions>
          <Button
            id="download-access-key-button"
            variant="outlined"
            color="primary"
            onClick={onDownloadClick}
            startIcon={<SaveAltIcon />}
          >
            Download Access Key
          </Button>
          <Button
            id="close-access-key-dialog-button"
            variant="outlined"
            color="primary"
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


