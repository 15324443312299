import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import PwdResetApi from '../../../libs/EdgeVMSCloudApi/PasswordReset'

import { InfoDialog } from '../../dialogs'

const useStyles = theme => ({
  container: {
    marginTop: '15vh',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      isLoading: false,
      error: "",
      alertSuccess: false,
    };

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  componentWillUnmount () {
    this.abortController.abort();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({isLoading: true})
    const response = await PwdResetApi.CreateResetRequest(this.signal, this.state.email)

    if (response.error) {
      if (response.error.name === "AbortError") {
        return;
      } else if (response.error.message) {
        this.setState({error: response.error.message})
      } else {
        this.setState({error: 'Something went wrong.'})
      }
    }

    this.setState({
      isLoading: false,
      alertSuccess: true
    })
  }

  successAck() {
    this.setState({
      email: "",
      error: "",
      alertSuccess: false,
    })

    this.props.history.push("/");
  }

  render () {
    const { classes } = this.props
    const { isLoading, error } = this.state

    if (this.props.session && this.props.session.isLoggedIn) {
      return <Redirect to="/mainmenu" />
    }

    return (
      <Container maxWidth="xs" className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.wrapper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            {isLoading && <CircularProgress size={68} className={classes.fabProgress} color="primary"/>}
          </div>
          <Typography component="h1" variant="h5">
            Request Password Reset
          </Typography>
          { (error && (error.length > 0)) &&
            <Box border={2} color="error.main" borderColor="error.main" fontFamily="h6.fontFamily" p={1} mt={1} mb={1} width={1}>
              {error}
            </Box>
          }
          <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
            <Typography>
              Enter your email address below and we'll send you a link to reset your password.
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="email"
              autoFocus
              onChange={this.handleChange.bind(this)}
              disabled={isLoading}
            />
            <Button
              id="submit-button"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              Submit
            </Button>
          </form>
        </Paper>

      <InfoDialog
        open={this.state.alertSuccess}
        actionCallback={this.successAck.bind(this)}
      >
        Email sent to {this.state.email} with further instructions.
      </InfoDialog>
      </Container>
    );
  }
}

export default withRouter(withStyles(useStyles)(ForgotPassword))
