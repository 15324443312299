import { SET_SHOW_NOTIFICATIONS, SET_NOTIFICATION_COUNT } from '../constants/actionTypes'

export const showNotifications = (state = true, action) => {
  switch (action.type) {
    case SET_SHOW_NOTIFICATIONS:
      return action.showNotifications

    default:
      return state
  }
}

export const notificationCount = (state = 0, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_COUNT:
      return action.notificationCount

    default:
      return state
  }
}

