import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';

const useStyles = (theme) => ({
   head: {
      backgroundColor: theme.palette.secondary.main,
   },
});

function CustomTableHead(props) {
   const { classes, children, ...other } = props;

   return (
      <TableHead className={classes.head} {...other}>
         {children}
      </TableHead>
   );
}

export default withStyles(useStyles)(CustomTableHead)