import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import CustomCircularProgress from './CustomCircularProgress';
import PasswordRequirements from './PasswordRequirements'
import UsersApi from '../../libs/EdgeVMSCloudApi/Users'
import { SetPasswordCustomValidity } from '../../libs/Utils'
import { Forbidden } from '../errors'
import { InfoDialog } from '../dialogs';
import { setPasswordExpired } from '../../actions/passwordExpired'

const useStyles = theme => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  button: {
    minWidth: '125px',
    margin: theme.spacing(1),
  },
  buttonBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0, 2),
  },
});

class ChangePasswordContents extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      alertSuccess: false,
      user: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  componentWillUnmount () {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onCancelClick() {
    this.props.onClose()
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { userid } = this.props
    let values = {}

    if (userid) {
      values.id = userid
    }

    if (this.state.newPassword != null) {
      values.new_password = this.state.newPassword
    }

    if (this.state.oldPassword != null) {
      values.old_password = this.state.oldPassword
    }

    this.setState({isLoading: true})
    let response = await UsersApi.UpdateUser(this.signal, values)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    const user = response.ok ? response.body : {}
    if (response.ok) {
      this.setState({
        newPassword: null,
        oldPassword: null,
        user: user
      }, () => this.setState({ alertSuccess: true }))
    }

    this.setState({isLoading: false})
  }

  successAck() {
    this.setState({alertSuccess: false}, () => {
      this.props.setPasswordExpired(this.state.user.password_expired === true)
    })

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  setPasswordConfirmValidity() {
    SetPasswordCustomValidity('newPassword', 'confirm-password');
  }

  render () {
    const { classes, session, onClose } = this.props
    const { isLoading } = this.state
    const passwordExpiredVariant = this.props.variant === "passwordExpired"
    const user = session.accessToken.decoded.sub
    const tempUser = ((user.temporary !== undefined) && (user.temporary !== 0))

    if (!session.isLoggedIn || tempUser || user.federated) {
      return <Forbidden />
    }

    return (
      <Fragment>
        { isLoading ? (
          <CustomCircularProgress />
        ) : (
          <Fragment>
            <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <fieldset className={classes.fieldset}>
                <Grid container spacing={1} justify="center" alignItems="center">
                  {passwordExpiredVariant && 
                    <Fragment>
                      <Grid item xs={false} sm={1} md={2}>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Typography component="h2" variant="body1">
                          Your password has expired and must be changed before proceeding.
                        </Typography>
                        </Grid>
                      <Grid item xs={false} sm={1} md={2}>
                      </Grid>
                    </Fragment>
                  }

                  <Grid item xs={false} sm={1} md={2}>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <PasswordRequirements />
                  </Grid>
                  <Grid item xs={false} sm={1} md={2}>
                  </Grid>

                  <Grid item xs={false} sm={1} md={3}>
                  </Grid>
                  <Grid item xs={12} sm={10} md={6}>
                    <TextField
                      margin="normal"
                      required={true}
                      name="oldPassword"
                      label="Old Password"
                      type="password"
                      id="oldPassword"
                      autoComplete="oldPassword"
                      onChange={this.handleChange.bind(this)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={false} sm={1} md={3}>
                  </Grid>

                  <Grid item xs={false} sm={1} md={3}>
                  </Grid>
                  <Grid item xs={12} sm={10} md={6}>
                    <TextField
                      margin="normal"
                      required={true}
                      name="newPassword"
                      label="New Password"
                      type="password"
                      id="newPassword"
                      autoComplete="newPassword"
                      onChange={this.handleChange.bind(this)}
                      fullWidth
                      onInput={this.setPasswordConfirmValidity.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={false} sm={1} md={3}>
                  </Grid>

                  <Grid item xs={false} sm={1} md={3}>
                  </Grid>
                  <Grid item xs={12} sm={10} md={6}>
                    <TextField
                      margin="normal"
                      required={true}
                      name="confirm-password"
                      label="Confirm New Password"
                      type="password"
                      id="confirm-password"
                      autoComplete="confirm-password"
                      onChange={this.handleChange.bind(this)}
                      fullWidth
                      onInput={this.setPasswordConfirmValidity.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={false} sm={1} md={3}>
                  </Grid>
                </Grid>

                <div className={classes.buttonBox}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    id="submit-button"
                    startIcon={<PublishIcon/>}
                    type="submit"
                  >
                    Submit Changes
                  </Button>

                  {onClose &&
                    <Button
                      id="cancel-button"
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      onClick={this.onCancelClick.bind(this)}
                      startIcon={<ClearIcon />}
                    >
                      Cancel
                    </Button>
                  }
                </div>
              </fieldset>
            </form>
          </Fragment>
        )}

        <InfoDialog
          open={this.state.alertSuccess}
          actionCallback={this.successAck.bind(this)}
        >
          Successfully changed password!
        </InfoDialog>
      </Fragment>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setPasswordExpired: passwordExpired => dispatch(setPasswordExpired(passwordExpired))
  }
}

export default connect(null, mapDispatchToProps)(withStyles(useStyles)(ChangePasswordContents))
