import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CustomTabBar from '../../../custom/CustomTabBar';
import CustomTab from '../../../custom/CustomTab';
import TabPanel from '../../../custom/TabPanel';
import PagePaper from '../../../custom/PagePaper';
import UserGeneralTab from './UserGeneralTab';
import UserGroupTab from './UserGroupsTab'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

class UserDetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tabIndex: this.getTabIndexFromPathname(this.props.location.pathname),
    }
  }

  componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (location.pathname.includes('/mainmenu/admin/users/details/')) {
        const tabIndex = this.getTabIndexFromPathname(location.pathname)
        this.setState({ tabIndex })
      }
    })
  }

  componentWillUnmount () {
    if (this.unlisten) {
      this.unlisten()
    }
  }

  getTabIndexFromPathname(pathname) {
    return pathname.includes('/groups') ? 1 : 0
  }

  handleTabChange (event, newValue) {
    const { tabIndex } = this.state
    const pathname = tabIndex === 0 ? this.props.location.pathname : this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/'))

    if (newValue === tabIndex) {
      return
    }

  if (newValue === 1) {
      this.props.history.push(pathname + '/groups', this.props.location.state)
    } else {
      this.props.history.push(pathname, this.props.location.state)
    }
  }

  render () {
    const { classes, newUser } = this.props
    const { tabIndex } = this.state

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <CustomTabBar
              value={tabIndex}
              onChange={this.handleTabChange.bind(this)}
            >
              <CustomTab id="general" label="General"/>
              <CustomTab id="groups" label="Groups" disabled={newUser}/>
            </CustomTabBar>

            <TabPanel value={tabIndex} index={0}>
              <UserGeneralTab {...this.props} classes={{}}/>
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <UserGroupTab {...this.props} classes={{}}/>
            </TabPanel>

          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(UserDetails)
