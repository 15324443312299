
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, withTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ExchangeActions from './ExchangeActions'
import folderImageBlue from '../../../assets/images/Folder_1x_Blue.png'
import folderImageWhite from '../../../assets/images/Folder_1x_White.png'

const useStyles = theme => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  card: {
    display: 'flex',
    alignItems: 'center',
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flex: '1 1 auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    //overflowWrap: 'break-word',
  },
  image: {
    width: 58,
    height: 39,
    objectFit: 'cover',
    margin: theme.spacing(1),
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});


class CardView extends Component {
  render () {
    const { classes, cases, session, onConfirmDelete, onDetailsActionClick, theme } = this.props

    return (
      <Grid container spacing={1} className={classes.root}>
        { cases.length === 0 &&
          <Grid item xs={12}>
            No Cases
          </Grid>
        }

        {cases.map((fields, key) => {

          let occurred_on = 'N/A'
          if (fields.incident_date != null) {
            occurred_on = (new Date(fields.incident_date)).toLocaleString('en-US')
          }

          return(
            <Grid item key={key} xs={12} md={6} lg={4} xl={3}>
              <Card className={classes.card}>

                <Grid container item spacing={1} xs={12}>
                  <Grid item xs={12} title={"Click for more details"}>

                    <CardActionArea className={classes.rowFlex} onClick={() => onDetailsActionClick(fields.id)}>
                      <CardMedia
                        className={classes.image}
                        component="img"
                        image={theme.palette.type === 'dark' ? folderImageWhite  : folderImageBlue}
                      />

                      <CardContent className={classes.content}>
                        <Typography variant="h6" noWrap>
                          Case: {fields.descriptor}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" noWrap>
                          Name: {fields.incident_name}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" noWrap>
                          Status: {fields.status}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          <br/>
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" noWrap>
                          Occurred on {occurred_on}
                        </Typography>

                        <TextField
                          multiline
                          rows="4"
                          margin="normal"
                          name="description"
                          label="Description"
                          id="description"
                          fullWidth
                          variant="outlined"
                          value={fields.description ? fields.description : ''}
                          inputProps={{
                            readOnly: true,
                            style: {
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              cursor: "pointer",
                            }
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </CardContent>
                    </CardActionArea>
                  </Grid>
                  <Grid item xs={12}>
                    <CardActions className={classes.controls}>
                      <ExchangeActions
                        dense
                        caseId={fields.id}
                        session={session}
                        onConfirmDelete={onConfirmDelete}
                      />
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
        )})}
      </Grid>
    )
  }
}

CardView.propTypes = {
  cases: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
};

export default withTheme(withStyles(useStyles)(CardView))
