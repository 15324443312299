import { SET_SHOW_NOTIFICATIONS, SET_NOTIFICATION_COUNT } from '../constants/actionTypes'

export const setShowNotifications = (showNotifications) => ({
  type: SET_SHOW_NOTIFICATIONS,
  showNotifications,
})

export const setNotificationCount = (notificationCount) => ({
  type: SET_NOTIFICATION_COUNT,
  notificationCount,
})
