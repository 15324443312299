import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
import { withStyles } from '@material-ui/core/styles';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from '@material-ui/core/Checkbox';
import GroupsApi from '../../libs/EdgeVMSCloudApi/Groups'
import UserApi from '../../libs/EdgeVMSCloudApi/Users'
import CustomTable from '../custom/CustomTable';
import CustomTableHead from '../custom/CustomTableHead';
import CustomCircularProgress from '../custom/CustomCircularProgress';
import SearchBox from '../custom/SearchBox'
import { NavTableFooter } from '../table'
import ROWS_PER_PAGE_OPTIONS from '../../constants/navParams'
import { COOKIES } from '../../constants/cookieDefs'
import ConfirmAction from './confirmAction'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  button: {
    minWidth: '125px',
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export const PARENT_TYPES = {
  ROLE: 'role',
  USER: 'user',
}

class SelectGroupDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      groupResponse: {},
      userResponse: {},
      currentPage: '0',
      rowsPerPage: props.cookies.get(COOKIES.ROWS_PER_PAGE.name),
      searchString: '',
      showActionProgress: false,
      confirmDeleteActionOpen: false,
    }

    this.groupInAction = null
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefresh () {
    const { currentPage, rowsPerPage, searchString } = this.state
    const { parent, parentType } = this.props
    const accountFilter = parent && parent.account_id ? parent.account_id : undefined

    this.setState({isLoading: true})

    let response = await GroupsApi.FetchGroups(this.signal, { currentPage, rowsPerPage, searchString, accountFilter }, parentType === PARENT_TYPES.ROLE)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({ groupResponse: response.ok ? response.body : {} })

    if (parentType === PARENT_TYPES.USER && response.ok && parent && parent.id) {
      response = await UserApi.FetchUser(this.signal, parent.id, true)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState ({ userResponse: response.ok ? response.body : {}})
    } else {
      this.setState ({ userResponse: {}})
    }

    this.setState({ isLoading: false })
  };

  handleRequestSearch (event, value) {
    this.setState({ currentPage: '0', searchString: value.length ? value : "" }, () => this.handleRefresh())
    this.handleRefresh()
  };

  onPageChange(newValue) {
    this.setState({currentPage: newValue.toString()}, () => this.handleRefresh())
  };

  onRowsPerPageChange(newValue) {
    this.setState({rowsPerPage: newValue.toString()}, () => this.handleRefresh())
  };

  async onRowClick(isChecked, group) {
    let response = {}

    if (!isChecked) {
      if (this.props.assignGroup) {
        this.setState({isLoading: true})
        response = await this.props.assignGroup(this.signal, group.id)

        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({isLoading: false})
        await this.handleRefresh()
      }
    } else {
      if (this.props.deassignGroup) {
        this.onDeleteActionClick(group)
      }
    }
  }

  onDeleteActionClick (groupInAction) {
    if (groupInAction)
    {
      this.groupInAction = groupInAction
      this.setState({showActionProgress: false})
      this.setState({confirmDeleteActionOpen: true})
    }
  }

  async DeleteActionCallback (groupAnswer, toDelete) {
    if (groupAnswer && groupAnswer === 'ok' && toDelete && toDelete.id) {
      this.setState({showActionProgress: true})

      let response = await this.props.deassignGroup(this.signal, toDelete.id)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      await this.handleRefresh()
    }
    this.setState({confirmDeleteActionOpen: false})
  };


  render () {
    const { classes, session, open, parent, parentType } = this.props
    const { groupResponse, userResponse, isLoading } = this.state
    const { currentPage, rowsPerPage, searchString } = this.state
    const accountFilter = parent && parent.account_id ? parent.account_id : undefined

    let { total, offset, groups } = groupResponse
    if (groups == null) {
      groups = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    if (!session.isLoggedIn){
      return null
    }

    return (
      <div>
        <Dialog
          fullWidth
          maxWidth='md'
          disableScrollLock
          open={open}
          onEnter={() => this.handleRefresh()}
          onClose={this.props.doneCallback}
        >
          <DialogTitle>Add Group</DialogTitle>

          <DialogContent>
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CustomCircularProgress/>
            </Backdrop>

            <Box display="flex" justifyContent="flex-end">
              <SearchBox
                idKey={"group-dialog"}
                margin={"dense"}
                className={classes.formControl}
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>

            <CustomTable>
              <CustomTableHead>
                <TableRow selected={true}>
                  <TableCell padding="checkbox">Select</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </CustomTableHead>
              {groups.length ? (
                <TableBody>
                  {groups.map((group) => {
                    let isChecked = false

                    if (parentType === PARENT_TYPES.ROLE && parent && parent.id && group.roles ) {
                      isChecked = group.roles.findIndex(roleId => roleId === parent.id) > -1
                    } else if (parentType === PARENT_TYPES.USER && parent && parent.id && userResponse.groups) {
                      isChecked = userResponse.groups.findIndex(groupId => groupId === group.id) > -1
                    } else {
                      return null
                    }

                    if (accountFilter && accountFilter.length) {
                      if (group.account_id.toString() !== accountFilter) {
                        return null
                      }
                    }

                    return (
                      <TableRow
                        hover
                        key={group.id}
                        role="checkbox"
                        tabIndex={-1}
                        onClick={this.onRowClick.bind(this, isChecked, group)}
                        selected={isChecked}
                      >
                        <TableCell padding="checkbox" align="center">
                          <Checkbox checked={isChecked} color='primary'/>
                        </TableCell>
                        <TableCell>{group.name}</TableCell>
                        <TableCell>{group.description}</TableCell>
                      </TableRow>
                  )})}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={3}>No Groups</TableCell>
                  </TableRow>
                </TableBody>
              )}

              <NavTableFooter
                noURLUpdate
                labelRowsPerPage='Groups per page'
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={total}
                rowsPerPage={parseInt(rowsPerPage, 10)}
                currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                onRowsPerPageChange={this.onRowsPerPageChange.bind(this)}
                onPageChange={this.onPageChange.bind(this)}
              />
            </CustomTable>
          </DialogContent>
          <DialogActions>
            <Button
              id="done-button"
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={this.props.doneCallback}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmAction
          title="Remove Group Assignment ?"
          open={this.state.confirmDeleteActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to remove group assignment..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.groupInAction}
        >
          {`Are you sure you want to remove ${parentType} ${parent ? parent.name ? "\"" + parent.name + "\"" : parent.key_id ? parent.key_id : "" : ""}${parent.description ? " \"" + parent.description + "\"" : ""} from ${this.groupInAction ? "\"" + this.groupInAction.name + "\"" : "this"} group ?`}
        </ConfirmAction>

      </div>
    )
  }
}

SelectGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
  parentType: PropTypes.string.isRequired,
  assignGroup: PropTypes.func,
  deassignGroup: PropTypes.func,
  doneCallback: PropTypes.func,
  showActionProgress: PropTypes.bool,
};

export default withCookies(withStyles(useStyles)(SelectGroupDialog))
