import AccountDialog from './AccountDialog'
import LicenseEditorDialog from './LicenseEditorDialog'
import UserDialog from './UserDialog'
import RolesDialog from './RolesDialog'
import ReviewAndCompleteDialog from './ReviewAndCompleteDialog'

const exportsDialog = {
  AccountDialog,
  LicenseEditorDialog,
  UserDialog,
  RolesDialog,
  ReviewAndCompleteDialog,
};
export default exportsDialog;