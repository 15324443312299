import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import CaseApi from '../../../libs/EdgeVMSCloudApi/Cases'
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CustomCircularProgress from '../../custom/CustomCircularProgress';
import { SERVICES, OPERATIONS } from '../../../constants/permissions'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  tableContainer: {
    width: '100%',
    maxHeight: 500,
    overflow: 'auto',
  },
});

class CaseUsersTab extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      hasDataError: false,
      caseObject: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (action === "REPLACE") {
        await this.handleRefresh()
      }
    });

    await this.handleRefresh()
  }

  componentWillUnmount () {
    if (this.unlisten) {
      this.unlisten()
    }

    this.abortController.abort();
  }

  async handleRefresh () {
    if (this.props.match.params.caseid) {
      this.setState({isLoading: true})

      let response = await CaseApi.FetchCase(this.signal, this.props.match.params.caseid)
      if (response.error &&response.error.name === "AbortError") {
        return;
      }

      this.setState ({ caseObject: response.ok ? response.body : {} }, () => {
        this.setState({ hasDataError: response.error ? true : false, isLoading: false})
      })
    } else {
      this.setState({isLoading: false})
    }
  };

  handleGoHome = () => {
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/exchange')
  }

  render () {
    const { classes, newCase } = this.props
    const { isLoading, caseObject } = this.state

    if (newCase) {
      return null
    }

    let users = []
    if (caseObject.users) {
      users = caseObject.users
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
            <Toolbar>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
                <Link component="button" variant="h6" href="/" color="inherit" title='Back' onClick={this.handleGoHome}>
                  Video Xchange
                </Link>
                { caseObject.descriptor &&
                  <Link component="button" variant="h6" href="/" color="inherit" title='Back To Details'
                    onClick={() => this.props.history.push(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')), this.props.location.state)}
                  >
                    { caseObject.descriptor }
                  </Link>
                }
                { caseObject.descriptor &&
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    Users
                  </Typography>
                }
              </Breadcrumbs>

              <Tooltip title="Back">
                <span>
                  <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                    <ArrowBackIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <TableContainer className={classes.tableContainer}>
              <Table stickyHeader size={'small'}>
                <TableHead>
                  <TableRow selected={true}>
                  <TableCell rowSpan={2}>Name</TableCell>
                   <TableCell rowSpan={2}>Email</TableCell>

                    <TableCell colSpan={4} align="center">Permissions</TableCell>
                  </TableRow>
                  <TableRow selected={true}>
                    <TableCell align='center'>Create</TableCell>
                    <TableCell align='center'>Read</TableCell>
                    <TableCell align='center'>Update</TableCell>
                    <TableCell align='center'>Delete</TableCell>
                  </TableRow>
                </TableHead>

                {users.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6}>No users assigned to this case</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {users.map((user) => {
                      return (
                        <TableRow hover key={user.name}>
                          <TableCell component="th" scope="row">{user.name}</TableCell>
                          <TableCell>{user.temporary && user.temporary === 1 ? 'N/A' : user.email}</TableCell>
                          <TableCell align='center'>
                            { user.permissions && user.permissions.includes(SERVICES.CASE + ":" + OPERATIONS.CREATE) ?
                              <CheckIcon style={{ color: green[500] }} fontSize='small'/> :
                              <ClearIcon style={{ color: red[500] }} fontSize='small'/>
                            }
                          </TableCell>
                          <TableCell align='center'>
                            { user.permissions && user.permissions.includes(SERVICES.CASE + ":" + OPERATIONS.READ) ?
                              <CheckIcon style={{ color: green[500] }} fontSize='small'/> :
                              <ClearIcon style={{ color: red[500] }} fontSize='small'/>
                            }
                          </TableCell>
                          <TableCell align='center'>
                            { user.permissions && user.permissions.includes(SERVICES.CASE + ":" + OPERATIONS.UPDATE) ?
                              <CheckIcon style={{ color: green[500] }} fontSize='small'/> :
                              <ClearIcon style={{ color: red[500] }} fontSize='small'/>
                            }
                          </TableCell>

                          <TableCell align='center'>
                            { user.permissions && user.permissions.includes(SERVICES.CASE + ":" + OPERATIONS.DELETE) ?
                              <CheckIcon style={{ color: green[500] }} fontSize='small'/> :
                              <ClearIcon style={{ color: red[500] }} fontSize='small'/>
                            }
                          </TableCell>
                        </TableRow>
                    )})}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(CaseUsersTab)
