import { CLEAR_ERROR, SET_ERROR } from '../constants/actionTypes'

export const clearErrorMsg = () => ({
  type: CLEAR_ERROR
})

export const setErrorMsg = (errorMessage) => ({
  type: SET_ERROR,
  errorMessage
})
