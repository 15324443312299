import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { NotFound } from '../../errors'
import VideoExchange from './VideoExchange'
import CaseDetails from './CaseDetails'
import { SERVICES, OPERATIONS } from '../../../constants/permissions'

class ExchangeRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>

        { (session.permissions[SERVICES.CASE][OPERATIONS.READ] ||
           session.permissions[SERVICES.CASE][OPERATIONS.UPDATE]) &&
          <Route
            path='/mainmenu/exchange/details/:caseid'
            render={(props) => <CaseDetails {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.CASE][OPERATIONS.CREATE] &&
          <Route
            exact path='/mainmenu/exchange/new'
            render={(props) => <CaseDetails {...props} session={session} newCase/>}
          />
        }

        { session.permissions[SERVICES.CASE][OPERATIONS.READ] &&
          <Route
            exact path="/mainmenu/exchange"
            render={(props) => <VideoExchange {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default ExchangeRouter
