import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/passwordreset'

async function CreateResetRequest(signal, email, useAccessToken=false) {
  let body = {
    'email': email,
    'send_email': true
  }

  if (useAccessToken) {
    return await BaseApi.DoFetch(signal, baseUrl, 'POST', body)
  } else {
    return await BaseApi.DoFetch(signal, baseUrl, 'POST', body, { noSession: true })
  }
}

async function FetchPasswordReset(signal, token) {
  let url = baseUrl + '/' + token

  return await BaseApi.DoFetch(signal, url, 'GET', null, { noErrorDispatch: true, noSession: true })
}

async function CreateNewPassword(signal, token, newPassword) {
  let url = baseUrl + '/' + token
  let body = {
    'new_password': newPassword,
  }

  return await BaseApi.DoFetch(signal, url, 'POST', body, { noErrorDispatch: true, noSession: true })
}


const exportsPasswordReset = {
  CreateResetRequest,
  FetchPasswordReset,
  CreateNewPassword
};
export default exportsPasswordReset;

