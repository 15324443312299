import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PublishIcon from '@material-ui/icons/Publish';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import PagePaper from '../../../custom/PagePaper';
import AccountsApi from '../../../../libs/EdgeVMSCloudApi/Accounts'
import { CASE_SETTINGS, ACCOUNT_SETTINGS } from '../../../../libs/EdgeVMSCloudApi/Constants'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import { ConfirmGoBack, ConfirmAction } from '../../../dialogs'
import AccountActions from './AccountActions'
import { GetLicenseSetting } from '../../../../libs/Utils'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  accordeonGrid: {
    width:'100%',
    marginTop: theme.spacing(1),
  },
  accordeonHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryAccordeonHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class AccountDetails extends Component {
  constructor (props) {
    super(props)
    this.dialogVariant = !!(props.variant && props.variant === "dialog")

    this.state = {
      isLoading: this.props.newAccount || (this.dialogVariant && !this.props.dialogContext.accountId) ? false : true,
      hasChanged: false,
      hasDataError: false,
      account: {},
      caseSettings: {},
      accountSettings: {},
      showDisableProgress: false,
      confirmDisableOpen: false,
      expandCaseSettings: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (action === "REPLACE") {
        await this.handleRefreshAccount()
      }
    });

    await this.handleRefreshAccount()
  }

  componentWillUnmount () {
    if (!this.dialogVariant) {
      window.onbeforeunload = null;
    }

    if (this.unlisten) {
      this.unlisten()
    }

    this.abortController.abort();
  }

  confirmDisable() {
    this.setState({showDisableProgress: false})
    this.setState({confirmDisableOpen: true})
  }

  async confirmDisableCallback(userAnswer, callbackContext) {
    if (userAnswer && userAnswer === 'ok') {
      this.setState({showDisableProgress: true})

      await this.doSubmit()
    }

    this.setState({confirmDisableOpen: false})
  }

  async handleRefreshAccount () {
    let accountId = null
    if (this.dialogVariant) {
      if (this.state.account && this.state.account.id) {
        accountId = this.state.account.id
      } else {
        accountId = this.props.dialogContext.accountId
      }
    } else {
      accountId = this.props.match.params.account
    }

    if (!this.props.newAccount && accountId) {
      this.setState({isLoading: true})

      let response = await AccountsApi.FetchAccount(this.signal, accountId)
      if (response.error && response.error.name === "AbortError") {
        return;
      }
      this.setState ({ account: response.ok ? response.body : {} })

      if (response.ok) {
        response = await AccountsApi.FetchCaseSettings(this.signal, accountId)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState ({
          caseSettings: response.ok && response.body ? response.body : {} ,
        })
      }

      if (response.ok) {
        response = await AccountsApi.FetchAccountSettings(this.signal, accountId)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState ({ accountSettings: response.ok ? response.body : {} })
      }

      this.setState({hasDataError: response.error ? true : false, isLoading: false})
      if (this.props.dialogCallback) {
        this.props.dialogCallback({ok: !!response.ok, objectId: accountId})
      }
    }
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
      hasChanged: true
    });

    if (this.props.dialogCallback) {
      this.props.dialogCallback({hasChanged: true})
    }
  }

  async handleSubmit(event) {
    event.preventDefault()

    if (this.state.enabled === false) {
      this.confirmDisable()
    } else {
      await this.doSubmit()
    }
  }

  async doSubmit() {
    let values = {}

    if (this.state.account.id) {
      values.id = this.state.account.id
    }

    if (this.state.name != null) {
      values.name = this.state.name
    }

    if (this.state.contact != null) {
      values.contact = this.state.contact
    }

    if (this.state.email != null) {
      values.email = this.state.email
    }

    if (this.state.enabled != null) {
      values.disabled = !this.state.enabled
    }

    this.setState({isLoading: true})

    let response = {}
    let account  = this.state.account

    if (this.props.newAccount || (this.dialogVariant && !(this.state.account && this.state.account.id))) {
      response = await AccountsApi.CreateAccount(this.signal, values)
    } else {
      response = await AccountsApi.UpdateAccount(this.signal, values)
    }

    if (response.error && response.error.name === "AbortError") {
      return;
    }

    if (response.ok && response.body) {
      account = response.body

      // Update account settings if needed
      let caseSettings = {}
      let caseSettingsChanged = false
      Object.values(CASE_SETTINGS).forEach(setting => {
        if (this.state[setting] != null) {
          caseSettingsChanged   = true
          caseSettings[setting] = this.state[setting]
        } else {
          caseSettings[setting] = this.state.caseSettings[setting]
        }
      })

      if (caseSettingsChanged) {
        response = await AccountsApi.UpdateCaseSettings(this.signal, caseSettings, account.id)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        if (response.ok && response.body) {
          this.setState({ caseSettings: response.body }, () => {
            Object.values(CASE_SETTINGS).forEach(setting => {
              this.setState({[setting]: null})
            })
          })
        }
      }

      // Update account settings if needed
      let accountSettings = {...this.state.accountSettings}
      let accountSettingsChanged = false
      Object.values(ACCOUNT_SETTINGS).forEach(setting => {
        if (this.state[setting] != null) {
          accountSettingsChanged = true
          if ((setting === ACCOUNT_SETTINGS.PWD_EXPIRATION_DAYS) ||
              (setting === ACCOUNT_SETTINGS.ACTIVITY_LOGS_RETENTION_TIME)) {
            accountSettings[setting] =  parseInt(this.state[setting], 10)
          } else {
            accountSettings[setting] = this.state[setting]
          }
        }
      })

      if (accountSettingsChanged) {
        response = await AccountsApi.UpdateAccountSettings(this.signal, accountSettings, account.id)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        if (response.ok && response.body) {
          this.setState({ accountSettings: response.body }, () => {
            Object.values(ACCOUNT_SETTINGS).forEach(setting => {
              this.setState({[setting]: null})
            })
          })
        }
      }

      this.setState({
        hasChanged: response.ok ? false : true,
        name: null,
        contact: null,
        email: null,
      })

      if (this.props.newAccount && !this.dialogVariant) {
        this.props.history.replace('/mainmenu/admin/accounts/details/' + account.id)
      } else if (this.dialogVariant && !(this.state.account && this.state.account.id)) {
        this.setState({account: account}, async () => {
          await this.handleRefreshAccount()
        })
      } else {
        this.setState({account: account})
      }
    }

    if (this.props.dialogCallback) {
      this.props.dialogCallback({ok: !!response.ok, objectId: account.id, hasChanged: response.ok ? false : true})
    }

    this.setState({isLoading: false})
  }

  onConfirmDelete() {
    // Consider going to a known good path instead of just back?
    this.props.history.goBack();
  }

  render () {
    const { classes, session, readOnly } = this.props
    const { isLoading, hasChanged, hasDataError, account, caseSettings, accountSettings } = this.state
    const newAccount = this.props.newAccount || (this.dialogVariant && !(account && account.id))
    const { name, contact, email, enabled, expandCaseSettings } = this.state
    const { password_expiration_days, activity_logs_retention_time } = this.state
    const { share_encryption, default_expiration_days, enforce_default_expiration_days, restrict_temporary_user } = this.state
    const hasCreatePermission = !readOnly && session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE]
    const hasUpdatePermission = !readOnly && session.permissions[SERVICES.ACCOUNT][OPERATIONS.UPDATE]
    const allowChanges = (!newAccount && hasUpdatePermission) || (newAccount && hasCreatePermission)
    const allowDisable = !newAccount && hasCreatePermission
    const submitButtonText = newAccount ? 'Create Account' : 'Submit Changes'

    const expirationDaysLicense = GetLicenseSetting(caseSettings.licenses, "VE_MAX_CASE_EXPIRATION_DAYS")
    let expirationDaysMin = null
    let expirationDaysMax = null

    if (expirationDaysLicense) {
      expirationDaysMin = expirationDaysLicense.value_min
      expirationDaysMax = expirationDaysLicense.value_max
    }

    let expirationDaysValue = null
    if (default_expiration_days != null) {
      expirationDaysValue = default_expiration_days
    } else if ((caseSettings[CASE_SETTINGS.DEFAULT_EXPIRATION_DAYS] != null) && (caseSettings[CASE_SETTINGS.DEFAULT_EXPIRATION_DAYS] > 0)) {
      expirationDaysValue = caseSettings[CASE_SETTINGS.DEFAULT_EXPIRATION_DAYS]
    } else {
      expirationDaysValue = expirationDaysMax
    }

    // Make sure that we do not allow to set a value that is not within license range
    if (expirationDaysValue > expirationDaysMax) {
      expirationDaysValue = expirationDaysMax
    } else {
      if (expirationDaysValue < expirationDaysMin) {
        expirationDaysValue = expirationDaysMin
      }
    }

    if (!this.dialogVariant) {
      if (hasChanged) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
                { this.dialogVariant ? (
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    Account Settings
                  </Typography>
                ) : (
                  <Link component="button" variant="h6" href="/" color="inherit" title='Back' onClick={this.props.history.goBack.bind(this)}>
                    Account Settings
                  </Link>
                )}

                { (newAccount || account.name) &&
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    { newAccount ? 'New account' : account.name }
                  </Typography>
                }
              </Breadcrumbs>

              { !newAccount && !this.dialogVariant &&
                <AccountActions
                  dropDownVariant
                  disabled={isLoading}
                  accountId={this.props.match.params.account}
                  session={session}
                  onConfirmDelete={this.onConfirmDelete.bind(this)}
                />
              }

              { !this.dialogVariant &&
                <Tooltip title="Back">
                  <span>
                    <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                      <ArrowBackIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              { allowChanges &&
                <Tooltip title={submitButtonText}>
                  <span>
                    <IconButton id="submit-icon" disabled={isLoading || !hasChanged} onClick={() => document.getElementById('submit-button').click()}>
                      <PublishIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading || newAccount} onClick={this.handleRefreshAccount.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                  <fieldset className={classes.fieldset} disabled={hasDataError || !allowChanges}>
                    <Grid container spacing={1}>
                      { allowDisable &&
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="enabled"
                                id="enabled"
                                checked={enabled != null ? enabled : !account.disabled}
                                onChange={this.handleChange.bind(this)}
                                disabled={newAccount}
                              />
                            }
                            label="Account enabled"
                          />
                        </Grid>
                      }
                      { account.created_at &&
                        <Grid item xs={12}>
                          <Typography variant="body1" color="inherit">
                            Created on : {account.created_at ? (new Date(account.created_at)).toLocaleString('en-US') : ''}
                          </Typography>
                        </Grid>
                      }
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          required
                          id="name"
                          label="Account Name"
                          name="name"
                          autoComplete="name"
                          onChange={this.handleChange.bind(this)}
                          fullWidth
                          value = {name != null ? name : account.name ? account.name : ''}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          required
                          id="contact"
                          label="Contact Name"
                          name="contact"
                          autoComplete="contact"
                          onChange={this.handleChange.bind(this)}
                          fullWidth
                          value = {contact != null ? contact : account.contact ? account.contact : ''}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          margin="normal"
                          required
                          name="email"
                          label="Contact Email"
                          type="email"
                          id="email"
                          autoComplete="current-password"
                          onChange={this.handleChange.bind(this)}
                          fullWidth
                          value = {email != null ? email : account.email ? account.email : ''}
                        />
                      </Grid>

                      { !newAccount &&
                        <Fragment>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              margin="normal"
                              required
                              name={ACCOUNT_SETTINGS.PWD_EXPIRATION_DAYS}
                              label="Password Expiration Period"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 30,
                                  max: 180
                                },
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                              }}
                              id="pwd-expiration-days"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              disabled={newAccount}
                              value = {password_expiration_days != null ? password_expiration_days : accountSettings[ACCOUNT_SETTINGS.PWD_EXPIRATION_DAYS] || ""}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              margin="normal"
                              required
                              name={ACCOUNT_SETTINGS.ACTIVITY_LOGS_RETENTION_TIME}
                              label="Activity Logs Retention Time"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 30,
                                  max: 732
                                },
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                              }}
                              id="activity-log-retention-time"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              disabled={newAccount}
                              value = {activity_logs_retention_time != null ? activity_logs_retention_time : accountSettings[ACCOUNT_SETTINGS.ACTIVITY_LOGS_RETENTION_TIME] || ""}
                            />
                          </Grid>

                          <Grid container spacing={1} item xs={12} className={classes.accordeonGrid}>
                            <Accordion  expanded={expandCaseSettings} onChange={() => this.setState({expandCaseSettings: !expandCaseSettings})}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.accordeonHeading}>
                                  Video Xchange
                                </Typography>
                                <Typography className={classes.secondaryAccordeonHeading}>
                                  Video Xchange settings that are global to this account
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={1}>
                                  <Grid container item xs={12}>
                                    <Grid item xs={12} md={6} lg={4}>
                                      <TextField
                                        InputProps = {{
                                          inputProps: {
                                            min: expirationDaysMin,
                                            max: expirationDaysMax
                                          },
                                          endAdornment: <InputAdornment position="end">days</InputAdornment>,
                                        }}
                                        margin="normal"
                                        name={CASE_SETTINGS.DEFAULT_EXPIRATION_DAYS}
                                        label="Default case retention time"
                                        type="number"
                                        id={CASE_SETTINGS.DEFAULT_EXPIRATION_DAYS}
                                        onChange={this.handleChange.bind(this)}
                                        fullWidth
                                        value = {expirationDaysValue != null ? expirationDaysValue : ''}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name={CASE_SETTINGS.ENFORCE_DEFAULT_EXPIRATION_DAYS}
                                          checked={enforce_default_expiration_days != null ? enforce_default_expiration_days : caseSettings[CASE_SETTINGS.ENFORCE_DEFAULT_EXPIRATION_DAYS] === true}
                                          onChange={this.handleChange.bind(this)}
                                          disabled={newAccount}
                                        />
                                      }
                                      label="Enforce default case retention time as maximum value"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name={CASE_SETTINGS.SHARE_ENCRYPTION}
                                          checked={share_encryption != null ? share_encryption : caseSettings[CASE_SETTINGS.SHARE_ENCRYPTION] === true}
                                          onChange={this.handleChange.bind(this)}
                                          disabled={newAccount}
                                        />
                                      }
                                      label="Use customer provided encryption key for file sharing"
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name={CASE_SETTINGS.RESTRICT_TEMPORARY_USER}
                                          checked={restrict_temporary_user != null ? restrict_temporary_user : caseSettings[CASE_SETTINGS.RESTRICT_TEMPORARY_USER] === true}
                                          onChange={this.handleChange.bind(this)}
                                          disabled={newAccount}
                                        />
                                      }
                                      label="Restrict temporary users view in case details page"
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </Fragment>
                      }
                    </Grid>

                    { allowChanges &&
                      <div className={classes.form}>
                        <Button
                          id="submit-button"
                          startIcon={<PublishIcon/>}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={!hasChanged}
                        >
                          {submitButtonText}
                        </Button>
                      </div>
                    }
                  </fieldset>
                </form>
              </Fragment>
            )}
          </PagePaper>
        </Container>

        { !this.dialogVariant &&
          <ConfirmGoBack
            open={hasChanged}
          />
        }

        <ConfirmAction
          title="Disable Account ?"
          open={this.state.confirmDisableOpen}
          showProgress={this.state.showDisableProgress}
          progressMsg="Processing request to disable account..."
          actionCallback={this.confirmDisableCallback.bind(this)}
        >
          Are you sure you want to disable this account?<br/>
          This will prevent all account users from accessing the site.
        </ConfirmAction>

      </div>
    )
  }
}

export default withStyles(useStyles)(AccountDetails)
