import { SERVICES, OPERATIONS } from '../constants/permissions'
import jwtDecode from 'jwt-decode'

function parseRoles(roles) {
  const localRoles = roles && Array.isArray(roles) ? roles : []
  let retVal = {}

  Object.values(SERVICES).forEach(service => {
    retVal[service] = {}
    Object.values(OPERATIONS).forEach(operation => {
      if (localRoles.find(role => role === service + ":" + operation)){
        retVal[SERVICES.ANY][OPERATIONS.ANY] = true
        retVal[SERVICES.ANY][operation] = true
        retVal[service][OPERATIONS.ANY] = true
        retVal[service][operation] = true
      }
    })
  })

  return retVal
}

export default function Session(accessToken, refreshToken) {
  this.isLoggedIn = false
  this.permissions = parseRoles()

  this.accessToken = {
    encoded: undefined,
    decoded: undefined
  }

  this.refreshToken = {
    encoded: undefined,
    decoded: undefined
  }

  if (accessToken != null && refreshToken != null) {
    try {
      this.accessToken = {
        encoded: accessToken,
        decoded: jwtDecode(accessToken)
      }

      this.refreshToken = {
        encoded: refreshToken,
        decoded: jwtDecode(refreshToken)
      }

      if (this.accessToken.decoded && this.accessToken.decoded.roles) {
        this.permissions = parseRoles(this.accessToken.decoded.roles)
      }

      this.isLoggedIn = true
    } catch (error) {
      console.error(error)
    }
  }
};

