import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { DialogTitle, DialogContent, DialogActions } from '../custom/CustomDialogs'
import { setPaletteType } from '../../actions/paletteType'
import { COOKIES, GetDefaultCookiesOptions } from '../../constants/cookieDefs'
import ROWS_PER_PAGE_OPTIONS from '../../constants/navParams'


function PreferencesDialog(props) {
  const { open, handleClose } = props
  const [state, setState] = useState({
    appPaletteType: (props.cookies.get(COOKIES.APP_PALETTE.name) === 'dark'),
    useListView: (props.cookies.get(COOKIES.USE_LIST_VIEW.name) === 'true'),
    rowsPerPage: props.cookies.get(COOKIES.ROWS_PER_PAGE.name),
    hasChanged: false,
  });

  const handleChange = (event) => {
    if (event.target.name === COOKIES.APP_PALETTE.name) {
      let newType = event.target.checked ? "dark" : "light";
      props.cookies.set(COOKIES.APP_PALETTE.name, newType, GetDefaultCookiesOptions());
      props.setPaletteType(newType);
    } else if (event.target.name === COOKIES.USE_LIST_VIEW.name) {
      props.cookies.set(COOKIES.USE_LIST_VIEW.name, event.target.checked ? "true" : "false", GetDefaultCookiesOptions());
    } else if (event.target.name === COOKIES.ROWS_PER_PAGE.name) {
      props.cookies.set(COOKIES.ROWS_PER_PAGE.name, event.target.value, GetDefaultCookiesOptions());
    }

    setState({ ...state, [event.target.name]: event.target.checked, hasChanged: true });
  };

  useEffect(() => {
    if (open) {
      setState({
        appPaletteType: (props.cookies.get(COOKIES.APP_PALETTE.name) === 'dark'),
        useListView: (props.cookies.get(COOKIES.USE_LIST_VIEW.name) === 'true'),
        rowsPerPage: props.cookies.get(COOKIES.ROWS_PER_PAGE.name),
        hasChanged: false,
      })
    }
  }, [open, props.cookies])

  useEffect(() => {
    if (!open && state.hasChanged) {
      window.location.reload()
    }
  }, [open, state.hasChanged])

  return (
    <div>
      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose}>
          Preferences
        </DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={state[COOKIES.APP_PALETTE.name]} onChange={handleChange} name={COOKIES.APP_PALETTE.name} />}
                label="Use dark theme"
              />

              <FormControlLabel
                control={<Switch checked={state[COOKIES.USE_LIST_VIEW.name]} onChange={handleChange} name={COOKIES.USE_LIST_VIEW.name} />}
                label="Use list view when available"
              />

              <TextField
                select
                margin="normal"
                id={COOKIES.ROWS_PER_PAGE.name}
                label="Preferred rows per page"
                name={COOKIES.ROWS_PER_PAGE.name}
                onChange={handleChange}
                value = {state[COOKIES.ROWS_PER_PAGE.name]}
                SelectProps={{ native: true }}
              >
                {ROWS_PER_PAGE_OPTIONS.map((option, key) => {
                  return <option key={key} value={option}>{option}</option>
                })}
              </TextField>
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapDispatchToProps (dispatch) {
  return {
    setPaletteType: paletteType => dispatch(setPaletteType(paletteType)),
  }
}

export default connect(null, mapDispatchToProps)(withCookies(PreferencesDialog))

