import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/admin/licenses'
async function FetchAccountLicenses(signal, account) {
  let url = baseUrl

  if (account) {
    url += '?account_id=' + account
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function UpdateAccountLicenses(signal, account, licensesArray) {
  let url = baseUrl
  let body = {
    'account_id': account,
    'licenses': licensesArray,
  }

  return await BaseApi.DoFetch(signal, url, 'PUT', body)
}


const exportsLicense = {
  FetchAccountLicenses,
  UpdateAccountLicenses,
};
export default exportsLicense;
