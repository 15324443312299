import React, { useEffect, useState } from 'react';
import StepperDialog from "./StepperDialog"
import UserGeneralTab from '../../views/Admin/Users/UserGeneralTab'
import GroupsApi from '../../../libs/EdgeVMSCloudApi/Groups'

function UserDialog(props) {
  const { open } = props
  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    if (open) {
      if (!abortController) {
        setAbortController(new AbortController())
      }
    }

    return function cleanup() {
      if (abortController) {
        abortController.abort()
      }
    }

  }, [open, abortController])

  const setAdminPermission = async (accountId, userId) => {
    let response = await GroupsApi.FetchGroups(abortController.signal, { currentPage: 0, rowsPerPage: 1000, accountFilter: accountId}, false)

    if (response.ok) {
      const groups = response.body && response.body.groups ? response.body.groups : []
      const accountAdminGroup = groups.find(group => group.name === "Account Admin")

      if (accountAdminGroup) {
        response = await GroupsApi.AssignUser(abortController.signal, accountAdminGroup.id, userId)
      }
    }

    return response.error ? response.error.name ? response.error.name : "error" : "ok"
  }

  const handleClose = async (response) => {
    let retVal = null

    if (props.dialogContext.userId == null && props.dialogContext.accountId && response.objectId) {
      retVal = await setAdminPermission(props.dialogContext.accountId, response.objectId)
    }

    if (props.handleClose && retVal !== "AbortError") {
      props.handleClose(response)
    }
  }

  return (
    <StepperDialog
       {...props}
       handleClose={async (response) => await handleClose(response)}
       StepContent={UserGeneralTab}
    />
  );
}

export default UserDialog
