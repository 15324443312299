import { SET_PASSWORD_EXPIRED } from '../constants/actionTypes'

const passwordExpired = (state = false, action) => {
  switch (action.type) {
    case SET_PASSWORD_EXPIRED:
      return action.passwordExpired

    default:
      return state
  }
}

export default passwordExpired
