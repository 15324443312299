import React, {Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AccountDetails from '../../views/Admin/Accounts/AccountDetails'
import Licenses from '../../views/Admin/Licenses/Licenses'
import UserGeneralTab from '../../views/Admin/Users/UserGeneralTab'
import UserGroupsTab from '../../views/Admin/Users/UserGroupsTab'
import { DialogTitle, DialogContent, DialogActions } from '../../custom/CustomDialogs'


function ReviewAndCompleteDialog(props) {
  const { open, session } = props

  const handleClose = (response) => {
    if (props.handleClose) {
      props.handleClose(response)
    }
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth='md'
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          Review and Complete
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
            <Paper>
              <AccountDetails
                {...props}
                readOnly
                session={session}
                variant="dialog"
              />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <Licenses
                  {...props}
                  readOnly
                  session={session}
                  variant="dialog"
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <UserGeneralTab
                  {...props}
                  readOnly
                  session={session}
                  variant="dialog"
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <UserGroupsTab
                  {...props}
                  readOnly
                  session={session}
                  variant="dialog"
                />
              </Paper>
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            id="goback-button"
            variant="outlined"
            color="primary"
            startIcon={<CloseIcon />}
            onClick={() => handleClose({ok: false})}
          >
            { "Go Back" }
          </Button>

          <Button
            id="complete-button"
            variant="outlined"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => handleClose({ok: true})}
          >
            { "Complete" }
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}


export default ReviewAndCompleteDialog

