import { SET_ERROR, CLEAR_ERROR } from '../constants/actionTypes'

const initialState = {
  show: false,
  errorMessage: "",
}

const showError = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return ({
        show: true,
        errorMessage: action.errorMessage,
      })

    case CLEAR_ERROR:
      return (initialState)

    default:
      return state
  }
}

export default showError
