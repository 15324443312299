import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

const useStyles = () => ({
  tabFont: {
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
});

function CustomTab(props) {
  const { classes, label, id, ...other } = props;

  return (
    <Tab id={`${id}-button`} label={label} className={classes.tabFont} {...other} />
  );
}

CustomTab.propTypes = {
  label: PropTypes.node.isRequired,
};

export default withStyles(useStyles)(CustomTab)