import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { NotFound } from '../../../errors'
import Accounts from './Accounts'
import AccountDetails from './AccountDetails'
import SSODetails  from './SSODetails'
import LicenseEditor from './LicenseEditor'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

class AccountRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>

        { (session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ] ||
           session.permissions[SERVICES.ACCOUNT][OPERATIONS.UPDATE]) &&
          <Route
            path='/mainmenu/admin/accounts/details/:account'
            render={(props) => <AccountDetails {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ] &&
          <Route
            path='/mainmenu/admin/accounts/sso/:account'
            render={(props) => <SSODetails {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.LICENSE][OPERATIONS.READ] &&
          <Route
            path='/mainmenu/admin/accounts/license/:account'
            render={(props) => <LicenseEditor {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
          <Route
            path='/mainmenu/admin/accounts/new'
            render={(props) => <AccountDetails {...props} session={session} newAccount/>}
          />
        }

        { session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/accounts'
            render={(props) => <Accounts {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default AccountRouter
