import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Redirect, Switch, withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium'
import NotificationsIcon from '@material-ui/icons/Notifications';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Zoom from '@material-ui/core/Zoom';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import { GetMenuItems, GetMenuRoutes } from './MenuItems'
import Footer from './Footer'
import { Landing, SignIn, SignOut, ForgotPassword, PasswordReset } from '../views'
import logo from '../../assets/images/Verint_Logo_White_RGB_High-Res.png'
import bgImage from '../../assets/images/splash.jpg'
import { setPaletteType } from '../../actions/paletteType'
import { setErrorMsg, clearErrorMsg } from '../../actions/showError'
import { setShowNotifications } from '../../actions/notifications'
import { Snackbar, Slide } from '@material-ui/core';
import { COOKIES, GetDefaultCookiesOptions } from '../../constants/cookieDefs'
import { PreferencesDialog, ChangePasswordDialog, NotificationDialog } from '../dialogs'
import UserChangePassword from '../views/Admin/Users/UserChangePassword'
import green from '@material-ui/core/colors/green'
import { SERVICES, OPERATIONS } from '../../constants/permissions'
import appConfig from '../../config/AppConfig.js'

const drawerWidth = 240;
const minUserMenuWidth = 100;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  banner: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    minHeight: 40,
    maxHeight: 40,
  },
  userMenuItem: {
    minWidth : minUserMenuWidth
  },
  userButton: {
    textTransform: 'none',
    minWidth: minUserMenuWidth,
    marginLeft: theme.spacing(1),
  },
  dashboard: {
    flexGrow: 1,
  },
});

class MainMenu extends Component  {
  constructor (props) {
    super(props)

    this.state = {
      drawerOpen: true,
      userMenuAnchor: null,
      showPreferences: false,
      showChangePasswordDialog: false,
      showEmptyNotifications: false,
    }

    const { cookies } = this.props;
    let savedPaletteType = cookies.get(COOKIES.APP_PALETTE.name);
    if (savedPaletteType && (savedPaletteType !== this.props.paletteType)) {
      this.props.setPaletteType(savedPaletteType);
    }
  }

  handleDrawerOpen = () => {
    this.setState({drawerOpen: true});
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen: false});
  };

  onHelpClicked = () => {
    const currentUrl = new URL(window.location.href)
    const pathLevels = currentUrl.pathname.split('/')

    let cshId = null
    if ((currentUrl.pathname === "/") || (pathLevels.length <= 1)) {
      cshId = "TASK_SIGNINGIN";
    } else {
      switch (pathLevels[1]) {
        case "mainmenu":
          switch (pathLevels[2]) {
            case "dashboard":
              cshId = this.props.session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] ? "HLP_DASHBOARDOVERVIEWADMIN" : "HLP_DASHBOARDOVERVIEWUSER";
              break;

            case "exchange":
              cshId = "HLP_CASES";
              break;

            case "admin":
              switch (pathLevels[3]) {
                case "accounts":
                  cshId = "HLP_ACCOUNTS";
                  break;

                case "users":
                  cshId = "HLP_USERS";
                  break;

                case "roles":
                  cshId = "HLP_ROLES";
                  break;

                case "groups":
                  cshId = "HLP_GROUPS";
                  break;

                case "accesskeys":
                  cshId = "HLP_ACCESSKEYS";
                  break;

                case "licenses":
                  cshId = "HLP_LICENSES";
                  break;

                case "activities":
                  cshId = "HLP_ACTIVITYLOGS";
                  break;

                default:
                  break;
              }
              break;

            default:
              break;
          }
          break;

        case "signin":
          cshId = "TASK_SIGNINGIN";
          break;

        default:
          break;
      }
    }

    // Make popout window relative to screen size but no larger than 800 x 650
    let popoutWidth = Math.min(parseInt(window.screen.width) * 0.5, 800)
    let popoutHeight = Math.min(parseInt(window.screen.height) * 0.75, 650)
    let features = "width=" + popoutWidth.toFixed(0);
    features += ",height=" + popoutHeight.toFixed(0);
    features += ",top=0,left=0,location=0,menubar=0,scrollbars=1"
    const helpUrl = cshId ? "/VVX-Help/Default.htm#cshid=" + cshId : "/VVX-Help/Content/404.htm"
    if (null === window.open(helpUrl, '', features)) {
      this.props.setErrorMsg("Failed to open help window")
    }
  }

  onThemeClicked = () => {
    const { cookies } = this.props;
    let newType = this.props.paletteType === "light" ? "dark" : "light";
    cookies.set(COOKIES.APP_PALETTE.name, newType, GetDefaultCookiesOptions());
    this.props.setPaletteType(newType);
  }

  handleSnackbarClose = (event, reason) => {
    this.props.clearErrorMsg();
  }

  onSignOutClick = () => {
    this.setState({userMenuAnchor: null})
    this.props.history.push('/signout', { originLocation: this.props.location.pathname})
  }

  render () {
    const { classes, session, location: { pathname } } = this.props;
    const { drawerOpen, userMenuAnchor } = this.state;
    const bPasswordExpired = session.isLoggedIn && this.props.passwordExpired
    const showMenu = session.isLoggedIn && !bPasswordExpired
    const bIsForgotPassword = this.props.location.pathname === '/forgotpassword';
    const bIsPasswordReset = this.props.location.pathname.startsWith("/passwordreset/");
    const bIsSignout = session.isLoggedIn && this.props.location.pathname === '/signout'
    const bIsSignIn = !session.isLoggedIn && (this.props.location.pathname === '/signin' || (!(bIsForgotPassword || bIsPasswordReset || bPasswordExpired) && this.props.location.pathname !== '/' && this.props.location.pathname !== '/signout'))
    const bIsFederatedUser = session.isLoggedIn && session.accessToken.decoded.sub.federated
    const bIsTempUser = session.isLoggedIn && (session.accessToken.decoded.sub.temporary !== undefined) && (session.accessToken.decoded.sub.temporary !== 0)
    const bIsPasswordChangeAllowed = !bIsFederatedUser && !bIsTempUser && !bPasswordExpired

    return (
      <div className={classes.root}>
        <AppBar position="absolute" className={clsx(classes.appBar, (drawerOpen && showMenu) && classes.appBarShift)}>
          <Toolbar>
            { showMenu &&
              <IconButton
                id="main-menu-open-icon"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                className={clsx(classes.menuButton, (drawerOpen && showMenu) && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
            }
            <img src={logo} alt="Verint logo" className={classes.logo} />

            <Typography variant="h4" color="inherit" noWrap className={classes.banner}>
              EdgeVMS Cloud
            </Typography>

            { appConfig.isOperationsConsole &&
              <Typography variant="h4" style={{ color: green[500], fontWeight: 700 }} noWrap className={classes.banner}>
                Verint Operations
              </Typography>
            }

            { appConfig.isSiteUnderMaintenace ? (
              <div/>
            ) : session.isLoggedIn ? (
              <div>

                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Tooltip title="Help">
                      <IconButton id="open-help" onClick={this.onHelpClicked}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Tooltip title={this.props.paletteType === "light" ? "Switch to Dark Theme" : "Switch to Light Theme"}>
                      <IconButton id="change-theme" onClick={this.onThemeClicked}>
                        <BrightnessMediumIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Tooltip title={"Show Notifications"}>
                      <IconButton id="show-notifications" onClick={() => {
                        this.setState({ showEmptyNotifications: true }, () => this.props.setShowNotifications(true))}
                      }>
                        <Badge badgeContent={this.props.notificationCount} color='error'>
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Button
                      id="user-menu-button"
                      className={classes.userButton}
                      variant='outlined'
                      color="inherit"
                      startIcon={<AccountCircleIcon/>}
                      endIcon={Boolean(userMenuAnchor) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                      onClick={(event) => this.setState({userMenuAnchor: event.currentTarget})}
                    >
                      {session.accessToken.decoded.sub.username}
                    </Button>
                  </Grid>
                </Grid>
                <Menu
                  className={classes.userMenu}
                  id="user-menu"
                  anchorEl={userMenuAnchor}
                  keepMounted
                  TransitionComponent={Zoom}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(userMenuAnchor)}
                  onClose={() => this.setState({userMenuAnchor: null})}
                >
                  <MenuItem id="user-preferences" className={classes.userMenuItem} onClick={() => this.setState({ showPreferences: true, userMenuAnchor: null })}>
                    <ListItemIcon>
                      <SettingsIcon/>
                    </ListItemIcon>
                    Preferences
                  </MenuItem>
                  { !bPasswordExpired && bIsPasswordChangeAllowed &&
                    <MenuItem id="user-change-password" className={classes.userMenuItem} onClick={() => this.setState({ showChangePasswordDialog: true, userMenuAnchor: null })}>
                      <ListItemIcon>
                        <LockIcon/>
                      </ListItemIcon>
                      Change Password
                    </MenuItem>
                  }
                  <MenuItem id="user-signout" className={classes.userMenuItem} onClick={this.onSignOutClick}>
                    <ListItemIcon>
                      <ExitToAppIcon/>
                    </ListItemIcon>
                    Sign Out
                  </MenuItem>
                </Menu>

                <PreferencesDialog
                  open={this.state.showPreferences}
                  handleClose={() => this.setState({showPreferences: false})}
                />

                {bIsPasswordChangeAllowed &&
                  <ChangePasswordDialog
                    open={this.state.showChangePasswordDialog}
                    handleClose={() => this.setState({showChangePasswordDialog: false})}
                    session={session}
                  />
                }

                <NotificationDialog
                  open={this.props.showNotifications}
                  showEmpty={this.state.showEmptyNotifications}
                  handleClose={() => {
                    this.props.setShowNotifications(false)
                    this.setState({showEmptyNotifications: false})
                  }}
                />
              </div>
            ) : (
              <Fragment>
                <Tooltip title="Help">
                  <IconButton id="open-help" onClick={this.onHelpClicked}>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={this.props.paletteType === "light" ? "Switch to Dark Theme" : "Switch to Light Theme"}>
                  <IconButton id="change-theme" onClick={this.onThemeClicked}>
                    <BrightnessMediumIcon />
                  </IconButton>
                </Tooltip>

                { !bIsSignIn &&
                  <Button id="buttonSignin" variant='outlined' color="inherit" onClick={() => this.props.history.push('/signin')}>Sign In</Button>
                }
              </Fragment>
            )}

          </Toolbar>
        </AppBar>
        { showMenu &&
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !(drawerOpen && showMenu) && classes.drawerPaperClose),
            }}
            open={(drawerOpen && showMenu)}
          >
            <div className={classes.toolbarIcon}>
              <IconButton id="main-menu-close-icon" onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <GetMenuItems pathname={pathname} session={session} />
          </Drawer>
        }

        <main
          className={classes.content}
          style={!session.isLoggedIn || bPasswordExpired ? {
            backgroundImage: "url(" + bgImage + ")",
            backgroundSize: 'cover',
            overflow: 'hidden'
          } : null}
        >
          <div className={classes.appBarSpacer} />

          { !session.isLoggedIn || bPasswordExpired || bIsSignout ? (
            <Fragment>
              <div className={classes.dashboard}>
                { bIsSignIn ?
                  <SignIn /> :
                    bIsSignout ?
                    <SignOut session={session} doSignOut={async () => await this.props.doSignOut()} /> :
                      bIsForgotPassword ?
                      <ForgotPassword /> :
                        bIsPasswordReset ?
                        <PasswordReset /> :
                          bPasswordExpired ?
                          <UserChangePassword session={session} variant="passwordExpired"/> :
                            <Landing/>
                }
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.dashboard}>
                <Switch>
                  <Redirect exact from="/mainmenu" to="/mainmenu/dashboard" />
                  <Redirect exact from="/signin" to="/mainmenu" />
                  <Redirect exact from="/" to="/mainmenu" />
                </Switch>

                <GetMenuRoutes {...this.props}/>
              </div>
            </Fragment>
          )}

          <Snackbar
            id="errorSnackbar"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            TransitionComponent={Slide}
            message={this.props.showError.errorMessage}
            open={this.props.showError.show}
            onClose={this.handleSnackbarClose}
            action={[
              <IconButton
                id="error-msg-close-icon"
                color="inherit"
                fontSize="large"
                key="close"
                onClick={this.handleSnackbarClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
           />

          <Footer session={session}/>
        </main>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    paletteType: state.paletteType,
    showError: state.showError,
    passwordExpired: state.passwordExpired,
    showNotifications: state.showNotifications,
    notificationCount: state.notificationCount
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setShowNotifications: showNotifications => dispatch(setShowNotifications(showNotifications)),
    setPaletteType: paletteType => dispatch(setPaletteType(paletteType)),
    setErrorMsg: (errorMessage) => dispatch(setErrorMsg(errorMessage)),
    clearErrorMsg: () => dispatch(clearErrorMsg()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(withStyles(styles)(MainMenu))))
