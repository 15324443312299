import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import { DialogTitle, DialogContent, DialogActions } from '../custom/CustomDialogs'

const PROMPT_TYPE = {
  INFORMATIVE: 0,
  QUERY_ACCEPT: 1,
}

function PromptDialog (props) {
  const { open, prompt } = props

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleAcceptClick = () => {
    if (props.onAccept) {
      props.onAccept()
    }
  }

  if (!prompt) {
    return null
  }

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>Notice</DialogTitle>
      <DialogContent dividers>
        <Typography style={{whiteSpace: 'pre-line'}}>
          {prompt.message ? prompt.message : ""}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAcceptClick}
          startIcon={<CheckIcon />}
        >
          { prompt.prompt_type === PROMPT_TYPE.QUERY_ACCEPT ? "Accept" : "Ok" }
        </Button>

        { prompt.prompt_type === PROMPT_TYPE.QUERY_ACCEPT &&
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelClick}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default PromptDialog

