import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';


const useStyles = theme => ({
  button: {
    minWidth: '125px',
  },
})

class ConfirmAction extends Component {
  constructor (props) {
    super(props)

    this.state = {
      disableOk: Boolean(this.props.textConfirm)
    }
  }

  handleDialogEnter () {
    this.setState({
      disableOk: Boolean(this.props.textConfirm)
    })
  }

  async onCloseClick () {
    await this.props.actionCallback('close', this.props.callbackContext)
  }

  async onOkClick () {
    await this.props.actionCallback('ok', this.props.callbackContext)
  }

  async onCancelClick () {
    await this.props.actionCallback('cancel', this.props.callbackContext)
  }

  async onProgressCancelClick () {
    await this.props.progressCancelCallback()
  }

  onTextConfirmChange = (event) => {
    const { textConfirm } = this.props

    if (Boolean(textConfirm)) {
      this.setState({ disableOk: textConfirm !== event.target.value })
    }
  };

  onKeyPressHandler = async (event) => {
    event.persist()
    if (event.key === 'Enter' && this.props.textConfirm === event.target.value) {
      await this.onOkClick()
    }
  };

  render () {
    const { classes, open, title, showProgress, progressMsg, children, textConfirm, yesno, allowProgressCancel} = this.props
    const { disableOk } = this.state

    return (
      <Dialog
        disableScrollLock
        open={open}
        disableBackdropClick={!!yesno}
        disableEscapeKeyDown={!!yesno}
        onEnter={this.handleDialogEnter.bind(this)}
        onClose={this.onCloseClick.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        { showProgress ? (
          <Fragment>
            <DialogTitle id="alert-dialog-title">Please Wait...</DialogTitle>
            <DialogContent style={{height: "100%", overflow: "hidden",}}>
              <DialogContentText id="alert-dialog-description">
                {progressMsg}
              </DialogContentText>
              <Box p={1} width={1} display="flex" alignItems="center" justifyContent="center">
                <CircularProgress color="inherit"/>
              </Box>
            </DialogContent>
            {allowProgressCancel &&
              <DialogActions>
                <Button
                  id="cancel-button"
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={this.onProgressCancelClick.bind(this)}
                  startIcon={<ClearIcon />}
                >
                  Cancel
                </Button>
              </DialogActions>
            }
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {children}
              </DialogContentText>

              { textConfirm &&
                <TextField
                  error={disableOk}
                  required
                  autoFocus
                  margin="dense"
                  id="text_confirm"
                  helperText={"Type \"" + textConfirm + "\" to confirm"}
                  placeholder={textConfirm}
                  onChange={this.onTextConfirmChange}
                  onKeyPress={this.onKeyPressHandler}
                />
              }

            </DialogContent>
            <DialogActions>
              <Button
                id="ok-button"
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={this.onOkClick.bind(this)}
                startIcon={<CheckIcon />}
                disabled = {disableOk}
              >
                {yesno ? "Yes" : "Ok"}
              </Button>
              <Button
                id="cancel-button"
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={this.onCancelClick.bind(this)}
                startIcon={<ClearIcon />}
                autoFocus={!Boolean(textConfirm)}
              >
                {yesno ? "No" : "Cancel"}
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    )
  }
}

export default withStyles(useStyles)(ConfirmAction)
