import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import { DialogTitle, DialogContent, DialogActions } from '../../custom/CustomDialogs'
import { GetFileSize } from '../../../libs/Utils'


export default function RejectedFilesDialog(props) {
  const { open, message, rejectedFiles, handleClose } = props

  return (
    <div>
      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose}>
          Rejected Files
        </DialogTitle>
        <DialogContent dividers>
          <Typography> {message} </Typography>
          <br/>
          <List dense={true}>
            {rejectedFiles.map( (file, key) => {
              return (
                <ListItem key={key}>
                  <ListItemIcon>
                    <FolderIcon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    secondary={GetFileSize(file.size)}
                  />
                </ListItem>
            )})}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


