import React, { Fragment, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { DialogTitle, DialogContent } from '../../custom/CustomDialogs'


const useStyles = makeStyles(theme => ({
  actionButton: {
    minWidth: '125px',
    margin: theme.spacing(1),
  },
  warning: {
    marginRight: theme.spacing(2)
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const UPLOAD_VARIANT = "upload"
const KEY_MAX_LENGHT = 32

export default function ShowAccessKeyDialog(props) {
  const theme = useTheme();
  const classes = useStyles()
  const { open, variant, handleClose, handleCloseContext } = props
  const [state, setState] = useState({
    showEncryptionKey: false,
    showEncryptionKeyConfirm: false,
  });

  const filename = handleCloseContext && handleCloseContext.file_name ? handleCloseContext.file_name : "this file"

  const handleClickShowEncryptionKey = () => {
    setState({ ...state, showEncryptionKey: !state.showEncryptionKey });
  };

  const handleClickShowEncryptionKeyConfirm = () => {
    setState({ ...state, showEncryptionKeyConfirm: !state.showEncryptionKeyConfirm });
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose("ok", variant, handleCloseContext, state["encryption_key"])
  };

  const setEncryptionKeyConfirmValidity = () => {
    const encryption_key = document.getElementById("encryption_key");
    const encryption_key_confirm = document.getElementById("confirm-encryption_key");

    if (!encryption_key.value) {
      encryption_key.setCustomValidity('Please fill in this field.');
    }
    else if (!encryption_key_confirm.value) {
      encryption_key_confirm.setCustomValidity('Please fill in this field.');
    }
    else if (encryption_key.value !== encryption_key_confirm.value) {
      encryption_key.setCustomValidity('Encryption key must match confirmation.');
      encryption_key_confirm.setCustomValidity('Encryption key must match confirmation.');
    } else {
      encryption_key.setCustomValidity('');
      encryption_key_confirm.setCustomValidity('');
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='sm'
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={() => handleClose("cancel")}
      >
        <DialogTitle onClose={() => handleClose("cancel")}>
          Encryption Key Required
        </DialogTitle>
        <DialogContent dividers>

          <Typography>
            { variant === UPLOAD_VARIANT ?
              "Your account settings require that you provide an encryption key for the files you share." :
              "Please provide the encryption key to download " + filename + "."
            }
          </Typography>

          { variant === UPLOAD_VARIANT &&
            <Fragment>
              <br/>
              <Typography component="div" >
                <Box color="orange" fontStyle="italic" fontWeight={theme.typography.fontWeightBold} display="flex" alignItems="center">
                  <WarningIcon className={classes.warning}/>
                  Make sure you save this encryption key.
                  <br/>
                  You cannot recover it later and you will not be able to download the file without it !
                </Box>
              </Typography>
            </Fragment>
          }

          <form id="encryption_key_form" className={classes.form} onSubmit={handleSubmit}>
            <FormControl component="fieldset" >
              <FormGroup>
                <TextField
                  autoFocus
                  defaultValue=""
                  type={state.showEncryptionKey ? "text" : "password"}
                  margin="normal"
                  required
                  name="encryption_key"
                  label="Encryption Key"
                  id="encryption_key"
                  helperText={"Limited to " + KEY_MAX_LENGHT + " characters"}
                  onChange={event => setState({ ...state, [event.target.name]: event.target.value })}
                  onInput={variant === UPLOAD_VARIANT ? setEncryptionKeyConfirmValidity : null}
                  inputProps={{
                    maxLength: KEY_MAX_LENGHT,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={state.showEncryptionKey ? "Hide" : "Show"}>
                          <span>
                            <IconButton
                              id="key-visibility-icon"
                              onClick={handleClickShowEncryptionKey}
                              onMouseDown={handleMouseDown}
                            >
                              {state.showEncryptionKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />

                { variant === UPLOAD_VARIANT &&
                  <TextField
                    defaultValue=""
                    margin="normal"
                    type={state.showEncryptionKeyConfirm ? "text" : "password"}
                    required
                    name="confirm-encryption_key"
                    label="Confirm Encryption Key"
                    id="confirm-encryption_key"
                    onInput={setEncryptionKeyConfirmValidity}
                    inputProps={{
                      maxLength: KEY_MAX_LENGHT,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={state.showEncryptionKeyConfirm ? "Hide" : "Show"}>
                            <span>
                              <IconButton
                                id="key-confirm-visibility-icon"
                                onClick={handleClickShowEncryptionKeyConfirm}
                                onMouseDown={handleMouseDown}
                              >
                                {state.showEncryptionKeyConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </span>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              </FormGroup>
            </FormControl>

            <Box pt={1} width={1} display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button
                id="ok-button"
                type="submit"
                form="encryption_key_form"
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                startIcon={<CheckIcon />}
              >
                Ok
              </Button>
              <Button
                id="cancel-button"
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={() => handleClose("cancel")}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}


