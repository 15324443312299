import React, {Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { DialogTitle, DialogContent, DialogActions } from '../../custom/CustomDialogs'

function StepperDialog(props) {
  const { open, session, title, StepContent } = props
  const [lastResponse, setLastResponse] = useState({});
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (open) {
      setLastResponse({})
    }
  }, [open])

  useEffect(() => {
   if (lastResponse.ok && lastResponse.objectId && !lastResponse.hasChanged) {
    setStatus("complete")
   } else if (lastResponse.objectId) {
    setStatus("warning")
   } else {
    setStatus("")
   }
  }, [lastResponse])

  const dialogCallback = (response) => {
    setLastResponse({...lastResponse, ...response})
  }

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose(lastResponse)
    }
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth='xl'
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <StepContent
            {...props}
            session={session}
            variant="dialog"
            dialogCallback={dialogCallback}
          />
        </DialogContent>

        <DialogActions>
          <Button
            id="stepper-dialog-button"
            variant="outlined"
            color="primary"
            onClick={handleClose}
            disabled={status === "warning"}
            startIcon={status === "complete" ? <CheckIcon /> : status === "warning" ? <WarningIcon /> : <CloseIcon />}
          >
            { status ? "Complete Step" : "Cancel" }
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}


export default StepperDialog

