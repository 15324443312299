import React, { Component } from 'react'
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import warningImage from '../../../assets/images/warning_amber_54x54.png'
import appConfig from '../../../config/AppConfig.js'

const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    top: '30vh',
    right: theme.spacing(3),
    paddingRight: theme.spacing(1),
    color: 'white'
  },
  middle: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(16),
    textAlign: 'center',
  },
  logo: {
    margin: `${theme.spacing(1) * 3}px 0 ${theme.spacing(1) * 4}px`,
    maxHeight: 200
  }
});


class Landing extends Component {
  render () {
    const { classes } = this.props

    if (!appConfig.isSiteUnderMaintenace) {
      return (
        <div className={classes.root}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.text}>
              <Typography
                variant="h3"
                align="right"
                color="inherit"
                gutterBottom
              >
                Welcome to<br />VERINT EdgeVMS Cloud
              </Typography>
              <Typography
                variant="h4"
                align="center"
                color="inherit"
                gutterBottom
              >
                {'Video Exchange Made Easy.'}
              </Typography>
              <div className={classes.middle}>
              <Typography variant="h5" color="inherit" gutterBottom>
                {'Please sign in to get started...'}
              </Typography>
              </div>
            </div>
          </Container>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.text}>
              <Typography
                variant="h3"
                align="right"
                color="inherit"
                gutterBottom
              >
                Welcome to<br />VERINT EdgeVMS Cloud
              </Typography>
              <Typography
                variant="h4"
                align="center"
                color="inherit"
                gutterBottom
              >
                {'Video Exchange Made Easy.'}
              </Typography>
              <div className={classes.middle}>
              <img src={warningImage} alt="Warning logo"/>
              <Typography variant="h4" color="inherit" gutterBottom>
                {'Site Under Maintenance'}
              </Typography>
              <Typography variant="h5" color="inherit" gutterBottom>
                {'We sincerely apologize for the inconvenience.'}
              </Typography>
              <Typography variant="h5" color="inherit" gutterBottom>
                {'Our site is currently undergoing scheduled'}
              </Typography>
              <Typography variant="h5" color="inherit" gutterBottom>
                {'maintenance and upgrades, but will return shortly.'}
              </Typography>
              <Typography variant="h5" color="inherit" gutterBottom>
                {'Thank you for your patience.'}
              </Typography>
              </div>
            </div>
          </Container>
        </div>
      )
    }
  }
}

export default withTheme(withStyles(useStyles)(Landing))
