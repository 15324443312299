import React, { Component, Fragment, createRef, useState } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDateTimePicker, DatePicker } from "@material-ui/pickers";
import RootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MapIcon from '@material-ui/icons/Map';
import PublishIcon from '@material-ui/icons/Publish';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CaseApi from '../../../libs/EdgeVMSCloudApi/Cases'
import ShareApi from '../../../libs/EdgeVMSCloudApi/Shares'
import { CASE_SETTINGS } from '../../../libs/EdgeVMSCloudApi/Constants'
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EventIcon from '@material-ui/icons/Event';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomCircularProgress from '../../custom/CustomCircularProgress';
import { setErrorMsg } from '../../../actions/showError'
import { setUploadState } from '../../../actions/uploadState'
import { SERVICES, OPERATIONS } from '../../../constants/permissions'
import { ConfirmGoBack, ConfirmAction, UploadProgress, VideoPlayerDialog, PromptDialog, InfoDialog } from '../../dialogs'
import clsx from 'clsx';
import { GetFileSize, isSharePlayable, hasAssociatedFileType, GetLicenseSetting, downloadBlob } from '../../../libs/Utils'
import ExchangeActions from './ExchangeActions'
import EncryptionKeyDialog from './EncryptionKeyDialog'
import RejectedFilesDialog from './RejectedFilesDialog'
import Bowser from "bowser"
import appConfig from '../../../config/AppConfig.js'
import OverflowTooltip from '../../custom/OverflowTooltip'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  subtitle: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  drop: {
    paddingTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(1),
    overflow: 'auto',
  },
  borderPaper: {
    borderColor: theme.palette.secondary.main,
    borderWidth: "2px",
    borderStyle: "solid",
  },
  tableContainer: {
    width: '100%',
    maxHeight: 400,
    overflow: 'auto',
  },
  filesTable: {
    width: "100%",
    tableLayout: "fixed",
    minWidth: '425px',
  },
  filesTableFileCell: {
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  filesTableActionCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: '110px',
  },
  filesTableCommonCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: '100px',
  },
  filesTableEncryptionCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: '30px',
  },
  dldAllButton: {
    marginBottom: theme.spacing(2),
  },
});

const EDGE_MAX_UPLOAD_FILE_SIZE = 2*1024*1024*1024 // 2 GB

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
}

const caseStatuses = [
  "Created",
  "Active",
  "Accepted",
  "Inactive",
  "Dismissed",
  "Closed"
]

const DEFAULT_STATUS_INDEX = 0
const UPDATE_INITIAL_DELAY = 2000;
const ENCRYPTION_TYPE_NONE = 'NONE'

function DownloadMenu(props) {
  const { disabled, share } = props
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = async (event) => {
    if (appConfig.downloadAsMp4 && hasAssociatedFileType(share, '.mp4')) {
      setAnchorEl(event.currentTarget)
    } else {
      await props.onMenuItemClick(share)
    }
  };

  const handleMenuItemClick = async (downloadAsMp4) => {
    setAnchorEl(null)
    await props.onMenuItemClick(share, downloadAsMp4)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  };

  return (
    <Fragment>
      <Tooltip title={"Download"}>
        <span>
          <IconButton
            size={'small'}
            disabled={disabled}
            onClick={handleButtonClick}
          >
            <SaveAltIcon fontSize='small'/>
          </IconButton>
        </span>
      </Tooltip>

      { appConfig.downloadAsMp4 && hasAssociatedFileType(share, '.mp4') &&
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={async () => await handleMenuItemClick()}>Download...</MenuItem>
          <MenuItem onClick={async () => await handleMenuItemClick(true)}>Download as mp4...</MenuItem>
        </Menu>
      }
    </Fragment>
  );
}

const DarkerDisabledTextField = withStyles(theme => ({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.text.primary
    }
  }
}))(TextField);

class CaseGeneralTab extends Component {
  constructor (props) {
    super(props)

    const browser = Bowser.getParser(window.navigator.userAgent)

    this.state = {
      isLoading: this.props.newCase?false:true,
      hasChanged: false,
      isFormDataValid: false,
      hasDataError: false,
      caseObject: {},
      shares: [],
      newFiles: [],
      rejectedFiles: [],
      confirmDownloadActionOpen: false,
      confirmDeleteShareOpen: false,
      showActionProgress: false,
      showEncryptionKeyDialog: false,
      showDownloadKeyDialog: false,
      showUploadProgress: false,
      filename: '',
      fileIdx: 0,
      fileCount: 0,
      fileProgress: 0,
      overallSize: 0,
      overallSent: 0,
      currentSent: 0,
      openVideoPlayer: false,
      shareToPlay : null,
      showPromptDialog: false,
      promptIndex: -1,
      openExpirationCalendar: false,
      maxExpirationDate: null,
      settingsResponse: {},
      expirationDateMsg: null,
      showExpirationDateMsg: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
    this.shareUpdateTimerId = null;
    this.updateCallbackPtr = this.UpdateShares.bind(this)
    this.updateDelay = 0;
    this.filesToDownload = []
    this.isBrowserEdge = browser.getBrowserName() === "Microsoft Edge"
    this.props.setUploadState(false)
  }

  async componentDidMount () {
    this.unlisten = this.props.history.listen(async (location, action) => {
      if (action === "REPLACE") {
        await this.handleRefreshCase()
      }
    });

    await this.handleRefreshCase()
  }

  componentWillUnmount () {
    window.onbeforeunload = null;

    if (this.unlisten) {
      this.unlisten()
    }

    if (this.shareUpdateTimerId) {
      clearTimeout(this.shareUpdateTimerId);
      this.shareUpdateTimerId = null
    }

    this.abortController.abort();
    this.props.setUploadState(false)
  }

  async UpdateShares(bFromRefresh) {
    const caseId = this.props.match.params.caseid
    let continueUpdating = true;
    const response = await ShareApi.FetchShares(this.signal, caseId)
    if (response.error && response.error.name === "AbortError") {
      return response;
    }

    if (response.ok) {
      this.setState({ shares: response.body})

      if (!this.NeedsShareUpdate(response.body)) {
        this.updateDelay = 0;
        continueUpdating = false;
      } else {
        if (bFromRefresh === true) {
          if (this.shareUpdateTimerId) {
            clearTimeout(this.shareUpdateTimerId);
          }
          this.updateDelay = 0;
          this.shareUpdateTimerId = setTimeout(this.updateCallbackPtr, UPDATE_INITIAL_DELAY)
        }
      }
    }

    if (continueUpdating) {
      this.shareUpdateTimerId = setTimeout(this.updateCallbackPtr, this.GetUpdateDelay())
    } else {
      clearTimeout(this.shareUpdateTimerId)
      this.shareUpdateTimerId = null
    }

    return response
  }

  async handleRefreshCase () {
    const caseId = this.props.match.params.caseid

    if (!this.props.newCase && caseId) {
      this.setState({isLoading: true})

      let response = await CaseApi.FetchCase(this.signal, caseId)
      if (response.error &&response.error.name === "AbortError") {
        return;
      }

      this.setState ({ caseObject: response.ok ? response.body : {} })

      if (response.ok) {
        response = await this.UpdateShares(true)
        if (response.error &&response.error.name === "AbortError") {
          return;
        }
      }

      if (response.ok) {
        response = await this.refreshCaseSettings(true)
        if (response.error &&response.error.name === "AbortError") {
          return;
        }
      }

      this.setState({ hasDataError: response.error ? true : false, isLoading: false})
    }
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      hasChanged: true
    }, this.updateFormValid);
  }

  transferStart = (oEvent, file) => {
    this.setState((state) => ({
      isLoading: true,
      fileProgress: 0,
      currentSent: 0,
      filename: file.name,
      fileIdx: state.fileIdx + 1
    }))
  }

  updateProgress = (oEvent, file) => {
    if (oEvent.lengthComputable) {
      let percentComplete = Math.floor(oEvent.loaded / oEvent.total * 100)
      this.setState({
        isLoading: true,
        fileProgress: percentComplete,
        currentSent: oEvent.loaded
      })
    }
  }

  transferComplete  = async (oEvent, caseId, file, encryptionKey) => {
    this.setState((state) => ({
      currentSent: 0,
      overallSent: state.overallSent + file.size
    }))

    await this.uploadNext(caseId, encryptionKey)
  }

  transferFailed  = async (oEvent, caseId, file, status) => {
    this.setState({
      showUploadProgress: false,
      hasChanged: this.newFiles.length > 0 ? true : false,
      newFiles: this.newFiles,
    })

    if (oEvent.type === 'error') {
      // Could be one of (look at console logs!):
      //  ERR_NAME_NOT_RESOLVED
      //  ERR_CONNECTION_REFUSED
      //  ERR_BLOCKED_BY_CLIENT
      //  ERR_TUNNEL_CONNECTION_FAILED
      this.props.setErrorMsg("Error: Failed to contact upload site!")
    } else {
      this.props.setErrorMsg("Error [" + status + "] occurred while transferring file " + file.name)
    }

    this.props.setUploadState(false)

    await this.handleRefreshCase()
  }

  async startFileUpload (caseId, encryptionKey) {
    if (this.state.newFiles.length > 0) {
      this.newFiles = this.state.newFiles.slice(0)

      let overallSize = 0
      for (let idx = 0; idx < this.newFiles.length; idx++) {
        overallSize += this.newFiles[idx].size
      }

      this.setState({
        hasChanged: true,
        filename: '',
        fileIdx: 0,
        fileCount: this.newFiles.length,
        fileProgress: 0,
        overallSize: overallSize,
        overallSent: 0,
        currentSent: 0,
        isLoading: true }, () => this.setState({ showUploadProgress: true, }))

      this.props.setUploadState(true)

      await this.uploadNext(caseId, encryptionKey)
    }
  }

  async uploadNext(caseId, encryptionKey) {
    let response = {}
    const remaining = this.newFiles.length
    if (remaining > 0) {
      const file = this.newFiles.pop()
      response = await ShareApi.ShareCreateAndUpload(this.signal, caseId, file, encryptionKey,
                      this.transferStart, this.updateProgress, this.transferComplete, this.transferFailed)
      if (response.error) {
        if (response.error.name === "AbortError") {
          return;
        }

        this.newFiles.push(file)
      }
    }

    if (remaining === 0 || response.error) {
      this.setState({
        showUploadProgress: false,
        hasChanged: response.error && this.newFiles.length > 0 ? true : false,
        newFiles: this.newFiles,
      })

      this.props.setUploadState(false)

      await this.handleRefreshCase()
    }
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({ isLoading: true })

    if (this.state.newFiles.length > 0) {
      const response = await CaseApi.FetchSettings(this.signal)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      if (response.ok && response.body && response.body) {
        const settings = response.body

        if (settings[CASE_SETTINGS.SHARE_ENCRYPTION] === true) {
          this.setState({ showEncryptionKeyDialog: true })
        } else {
          await this.pushValues()
        }
      } else {
        this.setState({ isLoading: false })
      }
    } else {
      await this.pushValues()
    }
  }

  async pushValues(encryptionKey) {
    let values = {}
    let updateCase = false

    if (this.state.caseObject.id) {
      values.id = this.state.caseObject.id
    }

    if (this.state.description != null) {
      values.description = this.state.description
      updateCase = true
    }

    if (this.state.status != null) {
      values.status = this.state.status
      updateCase = true
    } else {
      if (this.props.newCase) {
        values.status = caseStatuses[DEFAULT_STATUS_INDEX]
      }
    }

    if (this.state.descriptor) {
      values.descriptor = this.state.descriptor
      updateCase = true
    }

    if (this.state.incident_name != null) {
      values.incident_name = this.state.incident_name
      updateCase = true
    }

    if (this.state.incident_location != null) {
      values.incident_location = this.state.incident_location
      updateCase = true
    }

    if (this.state.incident_date != null) {
      const isValidDate = this.state.incident_date instanceof Date && !isNaN(this.state.incident_date.valueOf())
      if (isValidDate) {
        values.incident_date = this.state.incident_date.toUTCString()
        updateCase = true
      }
    }

    if (this.state.recipient != null) {
      values.recipient = this.state.recipient
      updateCase = true
    }

    if (this.state.expires_at != null) {
      const isValidDate = this.state.expires_at instanceof Date && !isNaN(this.state.expires_at.valueOf())
      if (isValidDate) {
        values.expires_at = this.state.expires_at.toUTCString()
        updateCase = true
      }
    }

    this.setState({isLoading: true})
    let response = {ok: true}
    let caseObject = {...this.state.caseObject}

    if (updateCase) {
      if (this.props.newCase) {
        response = await CaseApi.CreateCase(this.signal, values)
      } else {
        response = await CaseApi.UpdateCase(this.signal, values)
      }

      if (response.error && response.error.name === "AbortError") {
        return;
      }

      caseObject = response.ok ? response.body : {}
      if (response.ok) {
        this.setState({
          hasChanged: false,
          description : null,
          descriptor : null,
          status : null,
          incident_name : null,
          incident_location : null,
          incident_date : null,
          recipient : null,
          expires_at : null,
        })

        if (this.props.newCase) {
          await this.props.history.replace('/mainmenu/exchange/details/' + caseObject.id, this.props.location.state)
        } else {
          this.setState({ caseObject: caseObject })
        }
      }
    }

    this.setState({isLoading: false})

    if (response.ok) {
      if (this.state.newFiles.length > 0 && caseObject.id != null) {
        await this.startFileUpload(caseObject.id, encryptionKey)
      }
    }
  }

  onDrop(accpetedFiles) {
    let validFiles = []
    let rejectedFiles = []

    if (this.isBrowserEdge) {
      validFiles = accpetedFiles.filter( file => {
        if (file.size > EDGE_MAX_UPLOAD_FILE_SIZE) {
          rejectedFiles.push(file)
          return false
        }
        return true
      })
    } else {
      validFiles = accpetedFiles
    }

    this.setState((state) => {
      return {
        rejectedFiles,
        newFiles: state.newFiles.concat(validFiles),
        hasChanged: this.state.hasChanged || validFiles.length > 0
      }
    })
  }

  async onDownloadAllClick () {
    if (this.state.shares.length > 0) {
      const { shares } = this.state
      this.filesToDownload = []
      for (let idx = 0; idx < shares.length; idx++) {
        if (shares[idx].status === 'complete') {
          this.filesToDownload.push({share: shares[idx]})
        }
      }

      if (this.filesToDownload.length > 0) {
        const toDownload = this.filesToDownload.pop()
        await this.downloadFile(toDownload.share, toDownload.associated)
      }
    }
  }

  handleDownloadMenuItemClick = async (share, downloadAsMp4) => {
    this.filesToDownload = []
    if (downloadAsMp4) {
      for (let idx=0; idx < share.files.length; idx++) {
        if (share.files[idx].status === 'READY' && share.files[idx].file_name.includes('.mp4')) {
          this.filesToDownload.push({ share: share, associated: share.files[idx] })
        }
      }

      if (this.filesToDownload.length > 0) {
        const toDownload = this.filesToDownload.pop()
        await this.downloadFile(toDownload.share, toDownload.associated)
      }
    } else {
      await this.downloadFile(share)
    }
  }

  async downloadFile (share, associated) {
    if (share.encryption_type === ENCRYPTION_TYPE_NONE) {
      await this.doDownload(share, associated)
    } else {
      this.setState({ toDownload : {share: share, associated: associated} }, () => {
        this.setState({ showDownloadKeyDialog: true })
      })
    }
  };

  async doDownload (share, associated, encryptionKey) {
    const filename = associated && associated.file_name ? associated.file_name : share.file_name

    this.setState({ confirmDownloadActionOpen: true})

    const response = await ShareApi.GetDownloadLink(this.signal, share.id, associated ? associated.id : null, encryptionKey)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    if (response.ok) {
      if (response.blob) {
        downloadBlob(new Blob([response.blob]), filename)
      } else {
        if (response.body && response.body.download_url) {
          window.location.href = response.body.download_url
        }
      }
    }

    if (this.filesToDownload.length > 0) {
      setTimeout(async () => {
        const toDownload = this.filesToDownload.pop()
        await this.downloadFile(toDownload.share, toDownload.associated)
      }, 500)
    } else {
      this.setState({confirmDownloadActionOpen: false})
    }
  };

  handleDateChange = (date, element) => {
    const isValidDate  = date instanceof Date && !isNaN(date.valueOf())
    const incidentDate = document.getElementById(element);

    if (isValidDate) {
      incidentDate.setCustomValidity('');
    } else {
      incidentDate.setCustomValidity('Please provide a valid date');
    }

    this.setState({
      [element]: date,
      hasChanged: true
    }, this.updateFormValid);
  };

  onDeleteShareClick (idInAction) {
    if (idInAction)
    {
      this.idInAction = idInAction
      this.setState({showActionProgress: false})
      this.setState({confirmDeleteShareOpen: true})
    }
  }

  onDeleteNewClick (toDelete) {
    this.setState((state) => {
      let newFiles = [...state.newFiles]
      newFiles.splice(toDelete, 1)
      return {
        newFiles: newFiles
      }
    })
  }

  async DeleteShareCallback (userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete) {
      this.setState({showActionProgress: true})
      const response = await ShareApi.DeleteShare(this.signal, toDelete)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      await this.handleRefreshCase()
    }

    this.setState({confirmDeleteShareOpen: false})
  };

  NeedsShareUpdate(shares) {
    return (shares && Array.isArray(shares) && shares.length > 0 && shares.find(share => share.status === 'uploading') != null)
  }

  GetUpdateDelay() {
    this.updateDelay = Math.min(10000, Math.max(UPDATE_INITIAL_DELAY, this.updateDelay * 1.5));
    return (this.updateDelay);
  }

  handleEncryptionKeyDialogClose = async (userAnswer, variant, context, encryptionKey) => {
    this.setState({ showEncryptionKeyDialog: false, showDownloadKeyDialog: false }, async () => {
      if (userAnswer === 'ok' && encryptionKey){
        if (variant === 'upload') {
          await this.pushValues(encryptionKey)
        } else {
          await this.doDownload(context.share, context.associated, encryptionKey)
        }
      } else {
        if (this.filesToDownload.length > 0) {
          const toDownload = this.filesToDownload.pop()
          await this.downloadFile(toDownload.share, toDownload.associated)
          return
        }
        this.setState({ isLoading: false, confirmDownloadActionOpen: false })
      }
    })
  }

  updateFormValid() {
    let valid = true
    if (this.props.newCase) {
      let requiredFields = [
        "incident_name",
        //"status",  //always has a value pushed
        "incident_date",
        "description"
      ]

      requiredFields.forEach(field => {
        if (!this.state[field]) {
          valid = false
        }
      })
    }
    this.setState({isFormDataValid: valid})
  }

  handlePlayClick = (share) => {
    this.setState({shareToPlay: share} , () => {
      if (share && share.prompts && share.prompts.length) {
        this.setState({promptIndex: 0, showPromptDialog: true})
      } else {
        this.setState({openVideoPlayer: true})
      }
    })
  }

  handlePromptAccept = () => {
    const { shareToPlay, promptIndex } = this.state
    this.setState({showPromptDialog: false}, () => {
      if (shareToPlay && shareToPlay.prompts && (shareToPlay.prompts.length < promptIndex + 1)) {
        this.setState({promptIndex: promptIndex + 1}, () => {
          this.setState({showPromptDialog: true})
        })
      } else {
        this.setState({promptIndex: -1, openVideoPlayer: true})
      }
    })
  }

  handlePromptCancel = () => {
    this.setState({showPromptDialog: false}, () => {
      this.setState({promptIndex: -1, shareToPlay: null})
    })
  }

  toExpirationDate(date, expirationDays) {
    let retVal = new Date(date.setUTCDate(date.getUTCDate() + expirationDays + 1))
    retVal.setUTCHours(0)
    retVal.setUTCMinutes(0)
    retVal.setUTCSeconds(0)
    retVal.setUTCMilliseconds(0)

    return retVal
  }

  refreshCaseSettings = async (setMaxExpirationDate) => {
    const response = await CaseApi.FetchSettings(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return response
    }

    this.setState({settingsResponse: response.ok && response.body ? response.body : {}}, () => {
      if (setMaxExpirationDate) {
        this.setState({maxExpirationDate: this.getMaxExpirationDate()})
      }
    })

    return response
  }

  getMaxExpirationDate = (strict) => {
    const { caseObject, settingsResponse } = this.state
    let maxExpirationDate = null

    if (Object.keys(caseObject).length >= 0 && Object.keys(settingsResponse).length) {
      const expirationDaysLicense = GetLicenseSetting(settingsResponse.licenses, "VE_MAX_CASE_EXPIRATION_DAYS")
      const expirationDays = !!strict && settingsResponse.enforce_default_expiration_days ? settingsResponse.default_expiration_days : expirationDaysLicense.value_max
      let created_at = new Date(caseObject.created_at)
      let expires_at = new Date(caseObject.expires_at)
      maxExpirationDate = this.toExpirationDate(created_at, expirationDays)

      if (!strict && (expires_at > maxExpirationDate)) {
        maxExpirationDate = expires_at
      }
    }

    return maxExpirationDate
  }

  handleOpenExpirationCalendar = async () => {
    const response = await this.refreshCaseSettings()
    if (response.error && response.error.name === "AbortError") {
      return
    }

    if (response.ok) {
      const { caseObject, settingsResponse } = this.state
      const minExpirationDate = this.toExpirationDate(new Date(Date.now()), 0)
      const maxExpirationDate = this.getMaxExpirationDate(true)
      const expirationDaysLicense = GetLicenseSetting(settingsResponse.licenses, "VE_MAX_CASE_EXPIRATION_DAYS")

      if (maxExpirationDate <= minExpirationDate) {
        const created_at = new Date(caseObject.created_at)
        let expirationDateMsg = "You can't change the expiration date of this case because it has reached the maximum retention time"

        const elapsed = minExpirationDate.getTime() - this.toExpirationDate(created_at, 0).getTime()
        if (elapsed >= (expirationDaysLicense.value_max*24*60*60*1000) ) {
          expirationDateMsg += " allowed by the account license"
        } else {
          if (settingsResponse.enforce_default_expiration_days && settingsResponse.default_expiration_days > 0 &&
              (elapsed >= settingsResponse.default_expiration_days*24*60*60*1000)) {
            expirationDateMsg += " enforced by the settings of this account"
          }
        }

        expirationDateMsg += "."

        this.setState({openExpirationCalendar: false, expirationDateMsg}, this.setState({showExpirationDateMsg: true}))
      } else {
        this.setState({openExpirationCalendar: true}, this.setState({maxExpirationDate}))
      }
    }
  }

  handleGoHome = () => {
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/exchange')
  }

  render () {
    const { classes, session, newCase } = this.props
    const { isLoading, hasChanged, isFormDataValid, hasDataError, caseObject, shares } = this.state
    const { newFiles, descriptor, status, incident_name, incident_date, incident_location, recipient, description} = this.state
    const { showUploadProgress, filename, fileIdx, fileCount, fileProgress, overallSize, overallSent, currentSent } = this.state
    const { showEncryptionKeyDialog, showDownloadKeyDialog, toDownload, showPromptDialog, shareToPlay, promptIndex } = this.state
    const { expires_at, openExpirationCalendar, maxExpirationDate } = this.state
    const hasCreatePermission = session.permissions[SERVICES.CASE][OPERATIONS.CREATE]
    const hasUpdatePermission = session.permissions[SERVICES.CASE][OPERATIONS.UPDATE]
    const allowChanges = (!newCase && hasUpdatePermission) || (newCase && hasCreatePermission)
    const formDisable = hasDataError || !allowChanges
    const submitButtonText = newCase ? 'Create Case' : 'Submit Changes'

    if (hasChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    let overallProgress = 0
    if (overallSize > 0) {
      overallProgress = Math.floor( (overallSent + currentSent) / overallSize * 100)
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <Toolbar>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
              <Link component="button" variant="h6" href="/" color="inherit" title='Back' onClick={this.handleGoHome}>
                Video Xchange
              </Link>
              { (newCase || caseObject.descriptor) &&
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  { newCase ? 'New Case' : caseObject.descriptor }
                </Typography>
              }
            </Breadcrumbs>

            {!newCase &&
              <ExchangeActions
                dropDownVariant
                disabled={isLoading}
                caseId={this.props.match.params.caseid}
                session={session}
                onConfirmDelete={this.handleGoHome}
              />
            }

            <Tooltip title="Back">
              <span>
                <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                  <ArrowBackIcon />
                </IconButton>
              </span>
            </Tooltip>

            { allowChanges &&
              <Tooltip title={submitButtonText}>
                <span>
                  <IconButton id="submit-icon" disabled={isLoading || !hasChanged} onClick={() => document.getElementById('submit-button').click()}>
                    <PublishIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }

            <Tooltip title="Refresh">
              <span>
                <IconButton id="refresh-icon" disabled={isLoading || newCase} onClick={this.handleRefreshCase.bind(this)}>
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>

          { isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Fragment>
              <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <fieldset className={classes.fieldset} disabled={formDisable}>
                      <Paper className={classes.paper}>
                        <Toolbar>
                          <Typography variant="h5" color="inherit" className={classes.subtitle}>
                            Details
                          </Typography>
                        </Toolbar>

                        <Grid container spacing={1}>
                          { !newCase && caseObject.created_at && caseObject.expires_at &&
                            <Fragment>
                              <Grid item xs={12} md={6}>
                                <DarkerDisabledTextField
                                  disabled
                                  fullWidth
                                  margin="dense"
                                  name="created_at"
                                  label="Created On"
                                  id="created_at"
                                  variant="outlined"
                                  value={(new Date(caseObject.created_at)).toLocaleString('en-US')}
                                />
                              </Grid>
                                <Grid item xs={12} md={6}>
                                  <DatePicker
                                    open={openExpirationCalendar}
                                    readOnly={formDisable}
                                    autoOk
                                    disablePast={openExpirationCalendar}
                                    margin="dense"
                                    variant="inline"
                                    inputVariant="outlined"
                                    name="expires_at"
                                    label="Expires On"
                                    id="expires_at"
                                    autoComplete="expires_at"
                                    fullWidth
                                    value={expires_at != null ? expires_at : caseObject.expires_at ? new Date(caseObject.expires_at) : null}
                                    onChange={(date) => this.handleDateChange(date, "expires_at")}
                                    onOpen={async () => await this.handleOpenExpirationCalendar()}
                                    onClose={() => this.setState({openExpirationCalendar: false})}
                                    format="MM/dd/yyyy, hh:mm a"
                                    minDate={caseObject.created_at != null ? new Date(caseObject.created_at) : new Date(Date.now())}
                                    maxDate={maxExpirationDate}
                                    InputProps={{
                                      endAdornment:
                                        <InputAdornment position={'end'}>
                                          <Tooltip title="Change Expiration Date">
                                            <span>
                                              <IconButton id="change-expiration-date" disabled={formDisable}>
                                                <EventIcon/>
                                              </IconButton>
                                            </span>
                                          </Tooltip>
                                        </InputAdornment>,
                                    }}
                                  />
                                </Grid>
                            </Fragment>
                          }

                          <Grid item xs={12} md={4}>
                            <TextField
                              required={!newCase}
                              margin="normal"
                              name="descriptor"
                              label="Identifier"
                              id="descriptor"
                              autoComplete="descriptor"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              value = {descriptor != null ? descriptor : caseObject.descriptor ? caseObject.descriptor : ''}
                            />
                          </Grid>

                          <Grid item xs={12} md={8}>
                            <TextField
                              required
                              margin="normal"
                              name="incident_name"
                              label="Name"
                              id="incident_name"
                              autoComplete="incident_name"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              value = {incident_name != null ? incident_name : caseObject.incident_name ? caseObject.incident_name : ''}
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <TextField
                              select
                              required
                              margin="normal"
                              id="status"
                              label="Status"
                              name="status"
                              autoComplete="status"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              value = {status != null ? status : caseObject.status ? caseObject.status : caseStatuses[DEFAULT_STATUS_INDEX]}
                              SelectProps={{ native: true }}
                            >
                              { caseObject.status && !caseStatuses.includes(caseObject.status) &&
                                <option key={caseObject.status} value={caseObject.status}>{caseObject.status}</option>
                              }

                              {caseStatuses.map((status) => {
                                return <option key={status} value={status}>{status}</option>
                              })}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} md={8}>
                            <KeyboardDateTimePicker
                              required
                              readOnly={formDisable}
                              autoOk
                              disableFuture
                              margin="normal"
                              variant="inline"
                              name="incident_date"
                              label="Occured On"
                              id="incident_date"
                              autoComplete="incident_date"
                              fullWidth
                              value={incident_date != null ? incident_date : caseObject.incident_date ? new Date(caseObject.incident_date) : null}
                              onChange={(date) => this.handleDateChange(date, "incident_date")}
                              format="MM/dd/yyyy, hh:mm a"
                              mask="__/__/____, __:__ _M"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              margin="normal"
                              name="incident_location"
                              label="Location"
                              id="incident_location"
                              autoComplete="incident_location"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              value = {incident_location != null ? incident_location : caseObject.incident_location ? caseObject.incident_location : ''}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MapIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              margin="normal"
                              name="recipient"
                              label="Recipient Email (optional)"
                              type="email"
                              id="recipient"
                              autoComplete="recipient"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              value = {recipient != null ? recipient : caseObject.recipient ? caseObject.recipient : ''}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              required
                              multiline
                              margin="normal"
                              name="description"
                              label="Description"
                              id="description"
                              autoComplete="description"
                              onChange={this.handleChange.bind(this)}
                              fullWidth
                              variant="outlined"
                              rows="4"
                              value = {description != null ? description : caseObject.description ? caseObject.description : ''}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </fieldset>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Dropzone ref={dropzoneRef} onDrop={this.onDrop.bind(this)} disabled={formDisable} noClick noKeyboard>
                    {({getRootProps, getInputProps, isDragActive}) => {
                      const {ref, ...rootProps} = getRootProps()

                      return (
                        <RootRef rootRef={ref}>
                          <Paper className={clsx(classes.paper, isDragActive && classes.borderPaper)} {...rootProps}>
                            <input {...getInputProps()} />
                            <Toolbar>
                              <Typography variant="h5" color="inherit" className={classes.subtitle}>
                                Files
                              </Typography>

                              { hasUpdatePermission &&
                                <Tooltip title="Add Files">
                                  <span>
                                    <IconButton id="add-files-icon" disabled={formDisable} onClick={openDialog}>
                                      <Add/>
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              }
                            </Toolbar>

                            <Button
                              disabled={shares.find(element => element.status === 'complete') == null}
                              disableFocusRipple={true}
                              variant="contained"
                              color="primary"
                              onClick={this.onDownloadAllClick.bind(this)}
                              startIcon={<SaveAltIcon />}
                              className={classes.dldAllButton}
                            >
                              Download All Available Files
                            </Button>

                            <TableContainer className={classes.tableContainer}>
                              <Table className={classes.filesTable} stickyHeader size='small'>
                                <TableHead>
                                  <TableRow selected={true}>
                                    <TableCell className={classes.filesTableEncryptionCell} padding='none'></TableCell>
                                    <TableCell>File</TableCell>
                                    <TableCell className={classes.filesTableCommonCell}>Size</TableCell>
                                    <TableCell className={classes.filesTableCommonCell}>Status</TableCell>
                                    <TableCell className={classes.filesTableActionCell}>Actions</TableCell>
                                  </TableRow>
                                </TableHead>

                                {newFiles.length === 0 && shares.length === 0 ? (
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align='center' colSpan={5}>No files attached to this case</TableCell>
                                    </TableRow>
                                  </TableBody>
                                ) : (
                                  <TableBody>
                                    { newFiles.map((file, key) => {
                                      return (
                                        <TableRow hover key={key}>
                                          <TableCell className={classes.filesTableEncryptionCell} padding='none'/>
                                          <TableCell className={classes.filesTableFileCell}>
                                            <OverflowTooltip>{file.name}</OverflowTooltip>
                                          </TableCell>
                                          <TableCell className={classes.filesTableCommonCell}>{GetFileSize(file.size)}</TableCell>
                                          <TableCell className={classes.filesTableCommonCell}>new</TableCell>
                                          <TableCell className={classes.filesTableActionCell}>
                                            <Tooltip title="Remove New File">
                                              <span>
                                                <IconButton
                                                  size={'small'}
                                                  onClick={this.onDeleteNewClick.bind(this, key)}
                                                >
                                                  <DeleteForeverIcon fontSize='small' />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                    )})}

                                    { shares.map((share, key) => {
                                      const bIsComplete = (share.status === 'complete')

                                      return (
                                        <TableRow hover key={newFiles.length + key}>
                                          <TableCell className={classes.filesTableEncryptionCell} padding='none'>
                                            <Tooltip title={`${share.encryption_type === ENCRYPTION_TYPE_NONE ? "No " : ""}Encryption Key Required`}>
                                              { share.encryption_type === ENCRYPTION_TYPE_NONE ?
                                                <LockOpenIcon fontSize='small' /> :
                                                <LockIcon fontSize='small'/>
                                              }
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell className={classes.filesTableFileCell}>
                                            <OverflowTooltip>{share.file_name}</OverflowTooltip>
                                          </TableCell>
                                          <TableCell className={classes.filesTableCommonCell}>{GetFileSize(share.size)}</TableCell>
                                          <TableCell className={classes.filesTableCommonCell}>
                                            { share.status === 'complete' ? 'available' :
                                              share.status === 'uploading' ? <CircularProgress color="inherit" size={20} /> :
                                              share.status
                                            }
                                          </TableCell>
                                          <TableCell className={classes.filesTableActionCell}>
                                            <DownloadMenu
                                              disabled={!bIsComplete}
                                              share={share}
                                              onMenuItemClick={this.handleDownloadMenuItemClick}
                                            />
                                            { appConfig.enableVideoPlayer && isSharePlayable(share) &&
                                              <Tooltip title={"Play"}>
                                                <span>
                                                  <IconButton
                                                    size={'small'}
                                                    disabled={!bIsComplete}
                                                    onClick={() => this.handlePlayClick(share)}
                                                  >
                                                    <PlayArrowIcon fontSize='small'/>
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            }

                                            { hasUpdatePermission &&
                                              <Tooltip title="Delete Forever">
                                                <span>
                                                  <IconButton
                                                    size={'small'}
                                                    onClick={this.onDeleteShareClick.bind(this, share.id)}
                                                  >
                                                    <DeleteForeverIcon fontSize='small' />
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            }

                                          </TableCell>
                                        </TableRow>
                                    )})}

                                  </TableBody>
                                )}
                              </Table>
                            </TableContainer>

                            { hasUpdatePermission &&
                              <div className={classes.form}>
                                <Typography variant="subtitle2" color="inherit" className={classes.drop}>
                                  Drop files here or click add button to select files to upload.
                                </Typography>
                              </div>
                            }

                          </Paper>
                        </RootRef>
                    )}}
                    </Dropzone>
                  </Grid>

                  { allowChanges &&
                    <div className={classes.form}>
                      <Button
                        id="submit-button"
                        startIcon={<PublishIcon/>}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={newCase ? !isFormDataValid : !hasChanged}
                      >
                        {submitButtonText}
                      </Button>
                    </div>
                  }
                </Grid>
              </form>
            </Fragment>
          )}
        </Container>

        <ConfirmGoBack
          open={hasChanged}
        />

        <EncryptionKeyDialog
          open={showEncryptionKeyDialog || showDownloadKeyDialog}
          variant={showEncryptionKeyDialog ? "upload" : null}
          handleClose={this.handleEncryptionKeyDialogClose}
          handleCloseContext={toDownload}
        />

        <ConfirmAction
          open={this.state.confirmDownloadActionOpen}
          showProgress={true}
          progressMsg="Processing request to download case file(s)."
        />

        <ConfirmAction
          title="Delete File ?"
          open={this.state.confirmDeleteShareOpen}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to delete file..."
          actionCallback={this.DeleteShareCallback.bind(this)}
          callbackContext={this.idInAction}
        >
          Are you sure you want to permanently delete this file?<br/>
          This operation is IRREVERSIBLE!
        </ConfirmAction>

        <UploadProgress
          open={showUploadProgress}
          filename={filename}
          fileIdx={fileIdx}
          fileCount={fileCount}
          fileProgress={fileProgress}
          overallProgress={overallProgress}
        />

        <RejectedFilesDialog
          open={this.state.rejectedFiles.length > 0}
          message={`The following files have been rejected because they exceed this browser's maximum file size upload limit of ${GetFileSize(EDGE_MAX_UPLOAD_FILE_SIZE)} per file.`}
          rejectedFiles={this.state.rejectedFiles}
          handleClose={() => this.setState({rejectedFiles: []})}
        />

        <PromptDialog
          open={showPromptDialog === true}
          prompt={shareToPlay && shareToPlay.prompts && promptIndex > -1 && promptIndex < shareToPlay.prompts.length ? shareToPlay.prompts[promptIndex] : null}
          onAccept={this.handlePromptAccept}
          onCancel={this.handlePromptCancel}
        />

        { appConfig.enableVideoPlayer &&
          <VideoPlayerDialog
            open={this.state.openVideoPlayer === true}
            share={this.state.shareToPlay}
            handleClose={() => this.setState({openVideoPlayer: false}, () => {this.setState({shareToPlay: null})})}
          />
        }

        <InfoDialog
          open={this.state.showExpirationDateMsg === true}
          title={"Can't Change Expiration Date!"}
          actionCallback={() => this.setState({showExpirationDateMsg: false})}
        >
          {this.state.expirationDateMsg}
        </InfoDialog>
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setErrorMsg: (errorMessage) => dispatch(setErrorMsg(errorMessage)),
    setUploadState: (uploadState) => dispatch(setUploadState(uploadState))
  }
}

export default connect(null, mapDispatchToProps)(withStyles(useStyles)(CaseGeneralTab))
