import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Groups from './Groups'
import GroupDetails from './GroupDetails'
import { NotFound } from '../../../errors'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

class GroupRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>

        { (session.permissions[SERVICES.USER][OPERATIONS.READ] ||
           session.permissions[SERVICES.USER][OPERATIONS.UPDATE]) &&
          <Route
            path='/mainmenu/admin/groups/details/:group'
            render={(props) => <GroupDetails {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.USER][OPERATIONS.CREATE] &&
          <Route
            path='/mainmenu/admin/groups/new'
            render={(props) => <GroupDetails {...props} session={session} newGroup/>}
          />
        }

        { session.permissions[SERVICES.USER][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/groups'
            render={(props) => <Groups {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default GroupRouter
