import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SelectBelow from '../../../custom/SelectBelow';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Add from '@material-ui/icons/Add';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Forbidden } from '../../../errors'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import GroupsApi from '../../../../libs/EdgeVMSCloudApi/Groups'
import { FetchAccountList } from '../../../../libs/EdgeVMSCloudApi/Accounts'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import SearchBox from '../../../custom/SearchBox'
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { NavTableFooter } from '../../../table'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import ROWS_PER_PAGE_OPTIONS from '../../../../constants/navParams'
import { COOKIES } from '../../../../constants/cookieDefs'
import GroupActions from './GroupActions'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
});


class Groups extends Component {
  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      currentPage: '0',
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      accountFilter: "",
      searchString: "",
    }

    this.state = {
      isLoading: true,
      groupResponse: {},
      accountResponse: {},
    }

    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefresh () {
    const { session } = this.props
    this.setState({isLoading: true})

    let response = await GroupsApi.FetchGroups(this.signal, this.navParams)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({groupResponse: response.ok ? response.body : {}})

    if (response.ok &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ]) {
      response = await FetchAccountList(this.signal)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({accountResponse: response.ok ? response.body : {}})
    } else {
      this.setState({accountResponse: {}})
    }

    this.setState({isLoading: false})
  };

  handleRequestSearch (event, value) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')

    if (value.length) {
      currentParams.set('searchString', value)
    } else {
      currentParams.delete('searchString')
    }

    window.location.search = currentParams.toString()
  };

  onEditClick (group) {
    this.props.history.push(this.props.location.pathname + '/details/' + group, { originLocation: window.location.toString()})
  }

  handleChangeAccountFilter (event) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')
    currentParams.set('accountFilter', event.target.value)
    window.location.search = currentParams.toString()
  };

  render () {
    const { classes, session } = this.props
    const { groupResponse, isLoading, accountResponse} = this.state
    const { currentPage, rowsPerPage, searchString } = this.navParams

    let { total, offset, groups } = groupResponse
    if (groups == null) {
      groups = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    let { accounts } = accountResponse
    if (accounts == null) {
      accounts = []
    }

    if (!session.isLoggedIn){
      return <Forbidden />
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Groups
              </Title>

              { session.permissions[SERVICES.USER][OPERATIONS.CREATE] &&
                <Tooltip title="Add New Group">
                  <span>
                    <IconButton
                      id="add-group-icon"
                      disabled={isLoading}
                      onClick={ () => {
                        this.props.history.push(this.props.location.pathname + '/new', { originLocation: window.location.toString() })
                      }}
                    >
                      <Add/>
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            <Box display="flex" justifyContent="flex-end">
              { accounts.length > 0 &&
                <FormControl className={classes.formControl} disabled={isLoading}>
                  <InputLabel shrink>
                    Account Filter
                  </InputLabel>

                  <SelectBelow
                    value={ accounts.length > 0 ? this.navParams.accountFilter : "" }
                    onChange={this.handleChangeAccountFilter.bind(this)}
                    displayEmpty={accounts.length > 0}
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="">Your Account</MenuItem>
                    {accounts.map((account) => {
                      return <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                    })}
                  </SelectBelow>
                </FormControl>
              }

              <SearchBox
                className={classes.formControl}
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
            </Box>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <CustomTable>
                  <CustomTableHead>
                    <TableRow selected={true}>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </CustomTableHead>

                  {groups.length ? (
                    <TableBody>
                      {groups.map((group, key) => {
                        return (
                          <TableRow hover key={group.id}>
                            <TableCell onClick={this.onEditClick.bind(this, group.id)} title={"Click for details"}>
                              {group.name}
                            </TableCell>
                            <TableCell onClick={this.onEditClick.bind(this, group.id)} title={"Click for details"}>
                              {group.description}
                            </TableCell>

                            <TableCell className={classes.actionsCell}>
                              <GroupActions
                                dense
                                groupId={group.id}
                                session={session}
                                onConfirmedDelete={async () => await this.handleRefresh()}
                              />
                            </TableCell>
                          </TableRow>
                      )})}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={3}>No Groups</TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  <NavTableFooter
                    labelRowsPerPage='Groups per page'
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    count={total}
                    rowsPerPage={parseInt(rowsPerPage, 10)}
                    currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                  />

                </CustomTable>
              </Fragment>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(Groups))
