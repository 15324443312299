import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/admin/maintenance'

async function FetchMaintenanceNotification(signal) {
  return await BaseApi.DoFetch(signal, baseUrl, 'GET')
}

async function UpdateMaintenanceNotification(signal, maintenanceNotification) {
  return await BaseApi.DoFetch(signal, baseUrl, 'PUT', maintenanceNotification)
}

const exportsMaintenance = {
  FetchMaintenanceNotification,
  UpdateMaintenanceNotification,
};
export default exportsMaintenance;
