import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DetailsIcon from '@material-ui/icons/Details';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import { ConfirmAction } from '../../../dialogs'
import AccountApi from '../../../../libs/EdgeVMSCloudApi/Accounts'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

const useStyles = theme => ({
  actionsDropDown: {
    marginRight: theme.spacing(6),
  },
  actionsMenuIcon: {
    minWidth: theme.spacing(4),
  },
});

function DetailsAction(props) {
  const { accountId, session, disabled, dense, dropDownVariant } = props
  const hasReadPermission = session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ]
  const hasUpdatePermission = session.permissions[SERVICES.ACCOUNT][OPERATIONS.UPDATE]

  if (dropDownVariant || !(hasReadPermission || hasUpdatePermission)) {
    return null
  }

  return (
    <Fragment>
      <Tooltip title={hasUpdatePermission ? "Edit" : "Details"}>
        <IconButton
          disabled={disabled}
          size={dense ? 'small' : 'medium'}
          onClick={() => props.history.push('/mainmenu/admin/accounts/details/' + accountId)}
        >
          { hasUpdatePermission ?
            <EditIcon fontSize={dense ? 'small' : 'default'}/> :
            <DetailsIcon fontSize={dense ? 'small' : 'default'}/>
          }
        </IconButton>
      </Tooltip>
    </Fragment>
  )
}

class LicensesAction extends Component {
  onClick = () => {
    if (this.props.closeMenu){
      this.props.closeMenu()
    }

    if (this.props.accountId) {
      this.props.history.push('/mainmenu/admin/accounts/license/' + this.props.accountId)
    }
  }

  render () {
    const { session, classes, disabled, dense, dropDownVariant } = this.props;

    if (!session.permissions[SERVICES.LICENSE][OPERATIONS.CREATE]
     && !session.permissions[SERVICES.LICENSE][OPERATIONS.UPDATE]
     && !session.permissions[SERVICES.LICENSE][OPERATIONS.DELETE])
    {
      return null
    }

    return (
      <Fragment>
        { dropDownVariant ? (
          <MenuItem onClick={this.onClick}>
            <ListItemIcon className={classes.actionsMenuIcon}>
              <VpnKeyIcon fontSize='small' />
            </ListItemIcon>
            Licenses
          </MenuItem>
        ) : (
          <Tooltip title="Licenses">
            <IconButton
              disabled={disabled}
              size={dense ? 'small' : 'medium'}
              onClick={this.onClick}
            >
              <VpnKeyIcon fontSize={dense ? 'small' : 'default'} />
            </IconButton>
          </Tooltip>
        )}
      </Fragment>
    )
  }
}

class SSOAction extends Component {
  onClick = () => {
    if (this.props.closeMenu){
      this.props.closeMenu()
    }

    if (this.props.accountId) {
      this.props.history.push('/mainmenu/admin/accounts/sso/' + this.props.accountId)
    }
  }

  render () {
    const { classes, disabled, dense, dropDownVariant } = this.props;

    return (
      <Fragment>
        { dropDownVariant ? (
          <MenuItem onClick={this.onClick}>
            <ListItemIcon className={classes.actionsMenuIcon}>
              <SupervisorAccountIcon fontSize='small' />
            </ListItemIcon>
            SSO Configuration
          </MenuItem>
        ) : (
          <Tooltip title="SSO Configuration">
            <IconButton
              disabled={disabled}
              size={dense ? 'small' : 'medium'}
              onClick={this.onClick}
            >
              <SupervisorAccountIcon fontSize={dense ? 'small' : 'default'} />
            </IconButton>
          </Tooltip>
        )}
      </Fragment>
    )
  }
}

class DeleteAction extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showActionProgress: false,
      confirmDeleteActionOpen: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  onDeleteActionClick() {
    if (this.props.closeMenu){
      this.props.closeMenu()
    }

    this.setState({showActionProgress: false})
    this.setState({confirmDeleteActionOpen: true})
  }

  async DeleteActionCallback(userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete) {
      this.setState({showActionProgress: true})
      const response = await AccountApi.DeleteAccount(this.signal, toDelete)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({confirmDeleteActionOpen: false})

      // Call this last because it will probably result in the component being unmounted.
      if (this.props.onConfirmDelete) {
        await this.props.onConfirmDelete();
      }
    } else {
      this.setState({confirmDeleteActionOpen: false})
    }
  }

  render() {
    const { session, classes, disabled, dense, dropDownVariant } = this.props;

    if (!session.permissions[SERVICES.ACCOUNT][OPERATIONS.DELETE])
    {
      return null
    }

    return (
      <Fragment>
        { dropDownVariant ? (
          <MenuItem onClick={this.onDeleteActionClick.bind(this)}>
            <ListItemIcon className={classes.actionsMenuIcon}>
              <DeleteForeverIcon fontSize='small' />
            </ListItemIcon>
            Delete Forever
          </MenuItem>
        ) : (
          <Tooltip title="Delete Forever">
            <IconButton
              disabled={disabled}
              size={dense ? 'small' : 'medium'}
              onClick={this.onDeleteActionClick.bind(this)}
            >
              <DeleteForeverIcon fontSize={dense ? 'small' : 'default'} />
            </IconButton>
          </Tooltip>
        )}

        <ConfirmAction
          title="Delete Account ?"
          open={this.state.confirmDeleteActionOpen}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to delete account..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.props.accountId}
        >
          Are you sure you want to permanently delete this account?<br/>
          This operation is IRREVERSIBLE!
        </ConfirmAction>
      </Fragment>
    )
  }
}

class AccountActions extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userMenuAnchor: null,
    }
  }

  closeMenu = () => {
    this.setState({userMenuAnchor: null})
  }

  render () {
    const { session, classes, disabled,  dropDownVariant } = this.props
    const { userMenuAnchor } = this.state

    if (!session.permissions[SERVICES.ACCOUNT][OPERATIONS.ANY]) {
      if (dropDownVariant) {
        return null
      } else {
        return "None"
      }
    }

    if (dropDownVariant) {
      return (
        <Fragment>
          <Button
            disabled={disabled}
            className={classes.actionsDropDown}
            variant='outlined'
            color="inherit"
            endIcon={Boolean(userMenuAnchor) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            onClick={(event) => this.setState({userMenuAnchor: event.currentTarget})}
          >
            ACTIONS
          </Button>
          <Menu
            id="actions-menu"
            anchorEl={userMenuAnchor}
            keepMounted
            TransitionComponent={Fade}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(userMenuAnchor)}
            onClose={this.closeMenu}
          >
            <LicensesAction {...this.props} closeMenu={this.closeMenu}/>
            <SSOAction {...this.props} closeMenu={this.closeMenu}/>
            <DeleteAction {...this.props} closeMenu={this.closeMenu}/>
          </Menu>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <DetailsAction {...this.props}/>
          <LicensesAction {...this.props}/>
          <SSOAction {...this.props}/>
          <DeleteAction {...this.props}/>
        </Fragment>
      )
    }
  }
}

export default withRouter(withStyles(useStyles)(AccountActions))
