import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import m2mApi from '../../../../libs/EdgeVMSCloudApi/m2m'
import { FetchAccountList } from '../../../../libs/EdgeVMSCloudApi/Accounts'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import SearchBox from '../../../custom/SearchBox'
import { NavTableFooter } from '../../../table'
import SelectBelow from '../../../custom/SelectBelow';
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import ROWS_PER_PAGE_OPTIONS from '../../../../constants/navParams'
import { COOKIES } from '../../../../constants/cookieDefs'
import DescriptionDialog from './DescriptionDialog'
import ConfirmAction from '../../../dialogs/confirmAction'
import SelectUserDialog from '../../../dialogs/SelectUserDialog'
import { PARENT_TYPES } from '../../../dialogs/SelectUserDialog'
import ShowAccessKeyDialog from './ShowAccessKeyDialog'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
});

class AccessKeys extends Component {
  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      currentPage: '0',
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      accountFilter: "",
      searchString: "",
    }

    this.state = {
      isLoading: true,
      credentialResponse: {},
      accountResponse: {},
      newAccessKey: {},
      currentCredential: {},
      showNewAccessKey: false,
      showDescriptionDialog: false,
      showActionProgress: false,
      confirmDeleteActionOpen: false,
      selectUserDialogOpen: false,
    }

    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.idInAction = null;
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefresh () {
    const { session } = this.props
    this.setState({isLoading: true})

    let response = {ok: true}
    let newAccountFilter = this.navParams.accountFilter

    if (session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
        session.permissions[SERVICES.ACCOUNT][OPERATIONS.READ]) {
      response = await FetchAccountList(this.signal)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      if (response.ok) {
        let { accounts } = response.body
        if (accounts == null) {
          accounts = []
        }

        if (this.navParams.accountFilter.length > 0 && !accounts.find(account => account.id === this.navParams.accountFilter)) {
          newAccountFilter = accounts.length > 0 ? accounts[0].id : ""
        }
      }

      this.setState({ accountResponse: response.ok ? response.body : {} })
    } else {
      newAccountFilter = ""
      this.setState({ accountResponse: {} })
    }

    if (this.navParams.accountFilter !== newAccountFilter) {
      this.navParams.accountFilter = newAccountFilter
    }

    if (response.ok) {
      response = await m2mApi.FetchCredentials(this.signal, this.navParams)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({credentialResponse: response.ok ? response.body : {}})
    } else {
      this.setState({credentialResponse: {}})
    }

    this.setState({isLoading: false})
  };

  handleRequestSearch (event, value) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')

    if (value.length) {
      currentParams.set('searchString', value)
    } else {
      currentParams.delete('searchString')
    }

    window.location.search = currentParams.toString()
  };

  handleChangeAccountFilter (event) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('accountFilter', event.target.value)
    window.location.search = currentParams.toString()
  };

  onAddClick = () => {
    this.idInAction = null
    this.setState({ showDescriptionDialog: true })
  }

  onEditClick = (idInAction) => (event) => {
    event.stopPropagation()

    if (idInAction)
    {
      this.idInAction = idInAction
      this.setState({ showDescriptionDialog: true })
    }
  }

  onAssignClick = (idInAction) => (event) => {
    event.stopPropagation()

    if (idInAction)
    {
      this.setState({
        currentCredential: idInAction,
        selectUserDialogOpen: true,
      })
    }
  }

  async descriptionDialogCallback (userAnswer, credential, value) {
    this.setState({ showDescriptionDialog: false }, async () => {
      if (userAnswer && userAnswer === 'ok') {
        const newCredentials = !(credential && credential.id)
        let response = {}

        if (!newCredentials && credential.description === value) {
          return
        }

        this.setState({ isLoading: true })

        if (newCredentials) {
          let payload = { description: value == null ? '' : value }

          if (this.navParams.accountFilter.length > 0) {
            payload['account_id'] = this.navParams.accountFilter
          }

          response = await m2mApi.CreateCredential(this.signal, payload)
        } else {
          credential.description = value == null ? '' : value
          response = await m2mApi.UpdateCredential(this.signal, credential)
        }

        if (response.error && response.error.name === "AbortError") {
          return;
        }

        await this.handleRefresh()

        if (response.ok && newCredentials) {
          this.setState({ newAccessKey: response.body }, () => this.setState({ showNewAccessKey: true }))
        }
      }
    })
  }

  onDeleteActionClick = (idInAction) => (event) => {
    event.stopPropagation()

    if (idInAction)
    {
      this.idInAction = idInAction
      this.setState({showActionProgress: false})
      this.setState({confirmDeleteActionOpen: true})
    }
  }

  async DeleteActionCallback (groupAnswer, toDelete) {
    if (groupAnswer && groupAnswer === 'ok' && toDelete && toDelete.id) {
      this.setState({showActionProgress: true})

      let response = await m2mApi.DeleteCredential(this.signal, toDelete.id)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      await this.handleRefresh()
    }
    this.setState({confirmDeleteActionOpen: false})
  };


  render () {
    const { classes, session } = this.props
    const { credentialResponse, isLoading, accountResponse, currentCredential } = this.state
    const { currentPage, rowsPerPage, searchString } = this.navParams

    let { accounts } = accountResponse
    if (accounts == null) {
      accounts = []
    }

    let { total, offset, credentials } = credentialResponse
    if (credentials == null)
    {
      credentials = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    let selectedAccount = null
    if (this.navParams.accountFilter.length > 0) {
      selectedAccount = accounts.find(account => account.id === this.navParams.accountFilter)
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Access Keys
              </Title>

              { session.permissions[SERVICES.USER][OPERATIONS.CREATE] &&
                <Tooltip title="Add New Access Key">
                  <span>
                    <IconButton
                      id="add-access-key-icon"
                      disabled={isLoading}
                      onClick={this.onAddClick}
                    >
                      <Add/>
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            <Box display="flex" justifyContent="flex-start">
              <Typography>
                For your protection, you should never share your access key secret with anyone.
                <br />
                As a best practice, we recommend frequent key rotation.
              </Typography>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              { accounts.length > 0 &&
                <FormControl className={classes.formControl} disabled={isLoading}>
                  <InputLabel shrink>
                    Show keys for account
                  </InputLabel>
                  <SelectBelow
                    value={this.navParams.accountFilter}
                    onChange={this.handleChangeAccountFilter.bind(this)}
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="">Your Account</MenuItem>
                    {accounts.map((account) => {
                      return <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                    })}
                  </SelectBelow>
                </FormControl>
              }

              <SearchBox
                className={classes.formControl}
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
            </Box>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <CustomTable>
                  <CustomTableHead>
                    <TableRow selected={true}>
                      <TableCell>Access Key ID</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </CustomTableHead>

                  { credentials.length > 0 ? (
                    <TableBody>
                      { credentials.map((credential) => {
                        return (
                          <TableRow hover key={credential.id}>
                            <TableCell>
                              {credential.key_id}
                            </TableCell>

                            <TableCell>
                              {credential.description}
                            </TableCell>

                            <TableCell>
                              {credential.user ? credential.user.name : "(unassigned)"}
                            </TableCell>

                            <TableCell className={classes.actionsCell}>
                              <Fragment>
                                { session.permissions[SERVICES.USER][OPERATIONS.UPDATE] &&
                                  <Tooltip title="Edit Description">
                                    <span>
                                      <IconButton
                                        disabled={isLoading}
                                        size={'small'}
                                        onClick={this.onEditClick(credential)}
                                      >
                                        <EditIcon fontSize={'small'}/>
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }

                                { session.permissions[SERVICES.USER][OPERATIONS.UPDATE] &&
                                  <Tooltip title="Assign User">
                                    <span>
                                      <IconButton
                                        disabled={isLoading}
                                        size={'small'}
                                        onClick={this.onAssignClick(credential)}
                                      >
                                        <PersonAddIcon fontSize={'small'}/>
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }

                                { session.permissions[SERVICES.USER][OPERATIONS.DELETE] && 
                                  <Tooltip title="Delete Forever">
                                    <span>
                                      <IconButton
                                        disabled={isLoading}
                                        size={'small'}
                                        onClick={this.onDeleteActionClick(credential)}
                                      >
                                        <DeleteForeverIcon fontSize={'small'}/>
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }
                              </Fragment>
                            </TableCell>

                          </TableRow>
                      )})}
                    </TableBody>
                  ):(
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={4}>No Access Keys</TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  <NavTableFooter
                    labelRowsPerPage='Keys per page'
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    count={total}
                    rowsPerPage={parseInt(rowsPerPage, 10)}
                    currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                  />
                </CustomTable>

              </Fragment>
            )}
          </PagePaper>
        </Container>

        <DescriptionDialog
          open={this.state.showDescriptionDialog}
          actionCallback={this.descriptionDialogCallback.bind(this)}
          callbackContext={this.idInAction}
          selectedAccount={selectedAccount}
        />

        <ShowAccessKeyDialog
          open={this.state.showNewAccessKey}
          handleClose={() => this.setState({showNewAccessKey: false, newAccessKey: {} })}
          newAccessKey={this.state.newAccessKey}
        />

        <ConfirmAction
          title="Delete Access Key ?"
          open={this.state.confirmDeleteActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to delete access key..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.idInAction}
          textConfirm="delete"
        >
          {`Are you sure you want to permanently delete access key ${this.idInAction ? this.idInAction.key_id : ""} ${this.idInAction && this.idInAction.description ? "\"" + this.idInAction.description + "\"" : "" } ?`}
          <br/>
          This operation is IRREVERSIBLE!
        </ConfirmAction>

        <SelectUserDialog
          open={this.state.selectUserDialogOpen === true}
          session={session}
          parentType={PARENT_TYPES.ACCESS_KEY}
          parentUserId={currentCredential.user ? currentCredential.user.id : null}
          accountFilter={currentCredential.account_id}
          doneCallback={() => this.setState({selectUserDialogOpen: false}, () => this.handleRefresh())}
          assignUser={async (signal, userId) => await m2mApi.AssignUser(signal, currentCredential.id, userId)}
          deassignUser={async (signal, userId) => await m2mApi.DeassignUser(signal, currentCredential.id, userId)}
        />

      </div>
    )
  }
}

export default withTheme(withCookies(withStyles(useStyles)(AccessKeys)))
