import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles( theme => ({
  button: {
    minWidth: '125px',
  },
}))

export default function DescriptionDialog(props) {
  const { open, actionCallback, callbackContext, selectedAccount } = props
  const classes = useStyles();

  const onCloseClick = () => {
    actionCallback('close', callbackContext)
  }

  const onOkClick = () => {
    const dtf = document.getElementById('key_description')
    actionCallback('ok', callbackContext, dtf.value)
  }

  const onCancelClick = () => {
    actionCallback('cancel', callbackContext)
  }

  const onKeyPressHandler = (event) => {
    event.persist()
    if (event.key === 'Enter') {
      onOkClick()
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='sm'
        disableScrollLock
        open={open}
        onClose={onCloseClick}
      >
        <DialogTitle id="form-dialog-title">
          {callbackContext ? "Edit Key Description" : "Access Key Creation"}
        </DialogTitle>
        <DialogContent>
          {!callbackContext &&
            <DialogContentText>
              {`This new access key will be created in ${selectedAccount ? "\"" + selectedAccount.name + "\"" : "your"} account.`}
              <br/><br/>
              If you wish you can provide a description for this access key (optional).
            </DialogContentText>
          }
          <TextField
            autoFocus
            margin="dense"
            id="key_description"
            label="Description"
            fullWidth
            defaultValue={callbackContext && callbackContext.description ? callbackContext.description : ""}
            onKeyPress={onKeyPressHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="ok-button"
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={onOkClick}
            startIcon={<CheckIcon />}
          >
            Ok
          </Button>
          <Button
            id="cancel-button"
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={onCancelClick}
            startIcon={<ClearIcon />}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
