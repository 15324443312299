import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { NotFound } from '../../errors'
import AccountCreatorRouter from './AccountCreator/AccountCreatorRouter'
import { SERVICES, OPERATIONS } from '../../../constants/permissions'

class OperationsRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>
        { session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
          <Route
            exact path='/mainmenu/operations/accountcreator'
            render={(props) => <AccountCreatorRouter {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default OperationsRouter
