import React from 'react'
import HttpError from './HttpError'

function Forbidden(props) {
  return <HttpError code={403} />
}

function NotFound(props) {
  return <HttpError code={404} />
}

export {
  Forbidden,
  NotFound
}
