import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onNext, onPrevious, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5" style={{ flex: 1 }}>{children}</Typography>

        {onPrevious ? (
          <IconButton id="dialog-previous-icon" aria-label="close" onClick={onPrevious}>
            <NavigateBeforeIcon />
          </IconButton>
        ) : null}

        {onNext ? (
          <IconButton id="dialog-next-icon" aria-label="close" onClick={onNext}>
            <NavigateNextIcon />
          </IconButton>
          ) : null}

        {onClose ? (
          <IconButton id="dialog-close-icon" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>

    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export {
  DialogTitle,
  DialogContent,
  DialogActions
}