import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const OverflowTooltip = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  const checkOverflow = () => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  };

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow);

    return function cleanup() {
      window.removeEventListener('resize', checkOverflow);
    }
  });

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip