import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import { encodeRFC5987ValueChars } from '../Utils'

const baseUrl = appConfig.apiUri + '/api/admin/accounts'

async function FetchAccountList(signal, navParams) {

  let url = baseUrl

  if (navParams) {
    const { currentPage, rowsPerPage, searchString } = navParams
    if (currentPage && rowsPerPage) {
      const offset = (currentPage * rowsPerPage)
      url += '?offset=' + offset + '&limit=' + rowsPerPage
    }

    if (searchString.length > 0) {
      url += '&search_value=' + encodeRFC5987ValueChars(searchString)
    }
  }

  return await BaseApi.DoFetch(signal, url, 'GET');
}

async function FetchAccount(signal, id, flags) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'GET', null, flags)
}

async function UpdateAccount(signal, account) {
  let url = baseUrl + '/' + account.id

  return await BaseApi.DoFetch(signal, url, 'PUT', account)
}

async function CreateAccount(signal, account) {
  let url = appConfig.apiUri + '/api/admin/accounts'

  return await BaseApi.DoFetch(signal, url, 'POST', account)
}

async function DeleteAccount(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function FetchCaseSettings(signal, accountId) {
  let url = baseUrl + '/settings/cases'

  if (accountId) {
    url += "?account_id=" + accountId
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function UpdateCaseSettings(signal, settings, accountId) {
  let url = baseUrl + '/settings/cases'

  if (accountId) {
    url += "?account_id=" + accountId
  }

  return await BaseApi.DoFetch(signal, url, 'PUT', settings)
}

async function FetchAccountSettings(signal, accountId) {
  let url = baseUrl + '/settings/general'

  if (accountId) {
    url += "?account_id=" + accountId
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function UpdateAccountSettings(signal, settings, accountId) {
  let url = baseUrl + '/settings/general'

  if (accountId) {
    url += "?account_id=" + accountId
  }

  return await BaseApi.DoFetch(signal, url, 'PUT', settings)
}

const exportsAccount = {
  FetchAccountList,
  FetchAccount,
  UpdateAccount,
  CreateAccount,
  DeleteAccount,
  FetchCaseSettings,
  UpdateCaseSettings,
  FetchAccountSettings,
  UpdateAccountSettings,
};
export default exportsAccount;

export {
  FetchAccountList,
  FetchAccount,
  UpdateAccount,
  CreateAccount,
  DeleteAccount,
}
