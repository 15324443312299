import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

class SearchBox extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.props.searchString ? this.props.searchString : ''
    }
  }

  submitTextHandler = event => {
    this.props.onSearch(event, this.state.value)
  };

  onKeyPressHandler = event => {
    event.persist()
    if (event.key === 'Enter') {
      this.props.onSearch(event, event.target.value)
    }
  };

  render() {
    const idKey = this.props.idKey ? '-' + this.props.idKey : ''

    return (
      <TextField
        className={this.props.className}
        disabled={this.props.disabled}
        margin={this.props.margin ? this.props.margin : 'none'}
        onKeyPress={this.onKeyPressHandler}
        onChange={(event) => this.setState({value: event.target.value})}
        id={'SearchTextField' + idKey}
        variant='outlined'
        defaultValue={this.props.searchString}
        placeholder='Search...'
        type='search'
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Search">
                <span>
                  <IconButton
                    id={"searchbox-search-icon" + idKey}
                    disabled={this.props.disabled}
                    onClick={this.submitTextHandler}
                  >
                    <SearchIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    )
  }
}

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
};

export default SearchBox