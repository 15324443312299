const CASE_SETTINGS = {
  SHARE_ENCRYPTION: 'share_encryption',
  DEFAULT_EXPIRATION_DAYS: 'default_expiration_days',
  ENFORCE_DEFAULT_EXPIRATION_DAYS: 'enforce_default_expiration_days',
  RESTRICT_TEMPORARY_USER: 'restrict_temporary_user',
}

const ACCOUNT_SETTINGS = {
  PWD_EXPIRATION_DAYS: 'password_expiration_days',
  ACTIVITY_LOGS_RETENTION_TIME: 'activity_logs_retention_time'
}

export {
  CASE_SETTINGS,
  ACCOUNT_SETTINGS
}
