import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import SelectBelow from '../../../custom/SelectBelow';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PublishIcon from '@material-ui/icons/Publish';
import LicensesApi from '../../../../libs/EdgeVMSCloudApi/Licenses'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import LICENSE_DEFS from '../../../../constants/licenseDefs'
import { GetLicenseSetting, GetLicenseDefinition } from '../../../../libs/Utils'
import { ConfirmGoBack, ConfirmAction } from '../../../dialogs'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function BuildSelectList(licenseDef, currentSetting) {
  // Load all of the standard options in the license definition.
  let selectList = licenseDef.options.map((option) => {
    return (
      <MenuItem key={option.id} value={option.value}>{option.name}</MenuItem>
    )
  });

  // If the current setting is not in the license definition,
  // add it to the end of the list as a "custom" option.
  if (currentSetting) {
    let found = false;
    licenseDef.options.forEach(element => {
      found = (found || (element.value === currentSetting));
    });
    if (!found) {
      let optionName = "Custom (" + currentSetting + ")";
      selectList.push(<MenuItem key={licenseDef.options.length} value={currentSetting}>{optionName}</MenuItem>);
    }
  }

  return selectList;
}

function LicenseEditField (props) {
  const { licenseDef, licenseSetting, newLicense } = props
  const license = newLicense ? newLicense : licenseSetting;

  switch (licenseDef.uiType) {
    case "spinner":
      let textFieldInputProps = {
        inputProps: {
          min: licenseDef.min,
          max: licenseDef.max
        },
        endAdornment: licenseDef.suffix ? <InputAdornment position="end">{licenseDef.suffix}</InputAdornment> : null,
      }

      return (
        <TextField
          margin="normal"
          type="number"
          InputProps={textFieldInputProps}
          name={licenseDef.key}
          onChange={props.onChange}
          fullWidth
          value={license.value_max}
        />
      );

    case "list":
      let currentSetting = "";
      if (licenseSetting) {
        currentSetting = (licenseDef.type === "selection" ? license.value_current : license.value_max);
      }

      return (
        <SelectBelow
          name={licenseDef.key}
          disabled={props.disabled}
          onChange={props.onChange}
          fullWidth
          value={currentSetting}
        >
          {BuildSelectList(licenseDef, currentSetting)}
        </SelectBelow>
      );

    default:
      console.log("Unknown license type!!!")
      return (
        <Fragment></Fragment>
      );
  }
}

class LicenseEditor extends Component {
  constructor (props) {
    super(props)
    this.dialogVariant = !!(props.variant && props.variant === "dialog")

    this.state = {
      isLoading: true,
      currentLicenses: [],
      newLicenses: [],
      confirmModifyActionOpen: false,
      showActionProgress: false,
      hasChanged: false,
      hasDataError: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    if (!this.dialogVariant) {
      window.onbeforeunload = null;
    }

    this.abortController.abort();
  }

  async handleRefreshTable () {
    let account = null
    if (this.dialogVariant) {
      account = this.props.dialogContext.accountId
    } else {
      account = this.props.match.params.account
    }

    this.setState({isLoading: true})

    let response = await LicensesApi.FetchAccountLicenses(this.signal, account)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({
      currentLicenses: response.ok ? response.body : [],
      newLicenses: [],
      hasChanged: false,
      hasDataError: response.error ? true : false,
    })

    if (this.props.dialogCallback) {
      this.props.dialogCallback({ok: !!response.ok, objectId: account})
    }

    this.setState({isLoading: false})
  };

  translateEventValue(licenseKey, newValue) {
    let licenseDef = GetLicenseDefinition(licenseKey);
    if (licenseDef && (licenseDef.type === "counter")) {
      return parseInt(newValue, 10);
    }
    return newValue;
  }

  handleChange(event) {
    let newLicenses = [...this.state.newLicenses];
    let newLicense  = {}

    newLicense['key']       = event.target.name
    newLicense['value_max'] = this.translateEventValue(newLicense.key, event.target.value);

    let index = newLicenses.findIndex(license => license.key === newLicense.key)
    if (index > -1) {
      newLicenses[index] = newLicense
    } else {
      newLicenses.push(newLicense)
    }

    this.setState({
      newLicenses: newLicenses,
      hasChanged: true
    });

    if (this.props.dialogCallback) {
      this.props.dialogCallback({hasChanged: true})
    }
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({showActionProgress: false})
    this.setState({confirmModifyActionOpen: true})
  }

  async SubmitActionCallback(userAnswer) {
    let account = null
    if (this.dialogVariant) {
      account = this.props.dialogContext.accountId
    } else {
      account = this.props.match.params.account
    }

    if (userAnswer && userAnswer === 'ok') {
      this.setState({showActionProgress: true})

      const newLicenses = this.state.newLicenses.map(newLicense => {
        let retVal = {...newLicense}
        let index = this.state.currentLicenses.findIndex(currentLicense => currentLicense.key === newLicense.key)
        if (index > -1) {
          const { id, value_min, value_max } = this.state.currentLicenses[index]
          retVal['id']        = id
          retVal['value_min'] = retVal.value_min ? retVal.value_min : value_min
          retVal['value_max'] = retVal.value_max ? retVal.value_max : value_max
        }

        return retVal
      })

      const response = await LicensesApi.UpdateAccountLicenses(this.signal, account, newLicenses)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({
        currentLicenses: response.ok && response.body ? response.body : this.state.currentLicenses,
        newLicenses: response.ok ? [] : this.state.newLicenses,
        hasChanged: response.ok ? false : true,
        hasDataError: response.ok ? false : true,
      })

      if (this.props.dialogCallback) {
        this.props.dialogCallback({ok: !!response.ok, hasChanged: response.ok ? false : true, objectId: account})
      }
    }

    this.setState({confirmModifyActionOpen: false})
  };

  CreateSubmitWarningMessage() {
    return (
      <Fragment>
        {"Are you sure you want to make the following changes?"}
        <br />
        <br />
        {this.state.newLicenses.map((licenseSetting, key) => {
          let licenseDef = GetLicenseDefinition(licenseSetting.key);
          let currentSetting = GetLicenseSetting(this.state.currentLicenses, licenseSetting.key);
          let fromText = currentSetting.value_max;
          let toText = licenseSetting.value_max;

          if (licenseSetting.convertFunc) {
            fromText = licenseSetting.convertFunc(fromText);
            toText   = licenseSetting.convertFunc(toText);
          }

          return (
            <Fragment key={key}>
            {licenseDef.name} from {fromText} to {toText}
            <br/>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  render() {
    const { classes, session } = this.props
    const { isLoading, hasChanged, hasDataError } = this.state
    const hasWritePermission = session.permissions[SERVICES.LICENSE][OPERATIONS.CREATE] || session.permissions[SERVICES.LICENSE][OPERATIONS.UPDATE]
    const submitButtonText = 'Submit Changes'

    if (!this.dialogVariant) {
      if (hasChanged) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    }

    if (!session.isLoggedIn){
      return null
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Licenses
              </Title>

              {!this.dialogVariant &&
                <Tooltip title="Back">
                  <span>
                    <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                      <ArrowBackIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              { hasWritePermission &&
                <Tooltip title={submitButtonText}>
                  <span>
                    <IconButton id="submit-icon" disabled={isLoading || !hasChanged} onClick={() => document.getElementById('submit-button').click()}>
                      <PublishIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                  <fieldset className={classes.fieldset} disabled={hasDataError || !hasWritePermission}>

                    <CustomTable>
                      <colgroup>
                        <col style={{width: '75%'}}/>
                        <col style={{width: '25%'}}/>
                      </colgroup>
                      <CustomTableHead>
                        <TableRow selected={true}>
                          <TableCell>Name</TableCell>
                          <TableCell>Limit/Selection</TableCell>
                        </TableRow>
                      </CustomTableHead>

                      {LICENSE_DEFS.length ? (
                        <TableBody>
                          {LICENSE_DEFS.map((licenseDef, key) => {
                            const licenseSetting = GetLicenseSetting(this.state.currentLicenses, licenseDef.key);
                            const newLicense = GetLicenseSetting(this.state.newLicenses, licenseDef.key);

                            if (licenseDef.uiType === "none") {
                              return null
                            }

                            return (
                              <TableRow hover key={licenseDef.key}>
                                <TableCell> {licenseDef.name} </TableCell>
                                <TableCell>
                                  <LicenseEditField
                                    licenseDef={licenseDef}
                                    licenseSetting={licenseSetting}
                                    newLicense={newLicense}
                                    onChange={this.handleChange.bind(this)}
                                    disabled={hasDataError || !hasWritePermission}
                                  />
                                </TableCell>
                              </TableRow>
                            )})}

                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell align='center' colSpan={2}>(no licenses!)</TableCell>
                          </TableRow>
                        </TableBody>
                      )}

                    </CustomTable>

                    { hasWritePermission &&
                      <div className={classes.form}>
                        <Button
                          id="submit-button"
                          startIcon={<PublishIcon/>}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={!hasChanged}
                        >
                          {submitButtonText}
                        </Button>
                      </div>
                    }

                  </fieldset>
                </form>
              </Fragment>
            )}
          </PagePaper>
        </Container>

        { !this.dialogVariant &&
          <ConfirmGoBack
            open={hasChanged}
          />
        }

        <ConfirmAction
          title="Change License"
          open={this.state.confirmModifyActionOpen}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to change license..."
          actionCallback={this.SubmitActionCallback.bind(this)}
        >
          {this.CreateSubmitWarningMessage()}
        </ConfirmAction>

      </div>
    )
  }
}

export default withStyles(useStyles)(LicenseEditor)
