import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { DialogTitle, DialogContent, DialogActions } from '../../components/custom/CustomDialogs'
import ie_logo from '../../assets/images/ie-logo.jfif'
import edge_logo from '../../assets/images/edge-logo.png'
import chrome_logo from '../../assets/images/chrome-logo.svg'
import firefox_logo from '../../assets/images/firefox-logo.png'
import Bowser from "bowser"

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  logo: {
    minHeight: 64,
    maxHeight: 64,
  },
}));


export default function UnsupportedBrowsersDialog(props) {
  const theme = useTheme();
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const browser = Bowser.getParser(window.navigator.userAgent)

  const isValidBrowser = browser.satisfies({
    ie: ">=11",
    edge: ">0",
    chrome: ">0",
    firefox: ">0",
  });

  useEffect(() => {
    if (!isValidBrowser) {
      setOpen(true)
    }
  }, [isValidBrowser]);

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose}>
          Unsupported Browser
        </DialogTitle>
        <DialogContent dividers>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">

          <Typography variant="h6" component="div">
            <Box fontWeight={theme.typography.fontWeightBold}>
              Improve Your Experience
            </Box>
          </Typography>

          <br/>

          <Typography>
            You are using a browser that we do not support.
          </Typography>

          <Typography>
            Try one of these options to get a better experience on EdgeVMS Cloud.
          </Typography>

          <br/>

          <Typography>
            Download the latest version of one of these options.
          </Typography>

          <Box display="flex">

            <Link href="https://www.microsoft.com/en-us/download/internet-explorer.aspx" className={classes.link} noWrap>
              <img src={ie_logo} alt="IE logo" className={classes.logo} />
              Internet Explorer
            </Link>

            <Link href="https://www.microsoft.com/en-us/edge" className={classes.link} noWrap>
              <img src={edge_logo} alt="Edge logo" className={classes.logo} />
              Microsoft Edge
            </Link>

            <Link href="https://www.google.com/chrome/" className={classes.link} noWrap>
              <img src={chrome_logo} alt="Chrome logo" className={classes.logo} />
              Google Chrome
            </Link>

            <Link href="https://www.mozilla.org/en-US/firefox/" className={classes.link} noWrap>
              <img src={firefox_logo} alt="Firefox logo" className={classes.logo} />
              Mozilla Firefox
            </Link>
          </Box>
        </Box>

        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


