import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { Home, LoginCallback } from './pages'
import { NotFound } from './components/errors'
import { createBrowserHistory } from 'history'
import Chart from 'chart.js'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import "typeface-muli"
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const history = createBrowserHistory()

class App extends Component {
  render () {
    const VerintCESTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#007aff'
        },
        secondary: {
          main: '#05ce7c'
        },
        // Note that tertiary is *not* a standard palette intention key,
        // so it will only work like primary/secondary/error in some cases.
        tertiary: {
          main: '#fa6e1e'
        },
        type: this.props.paletteType
      },
      typography: {
        fontFamily: 'Muli, sans-serif',
      }
    });
    Chart.defaults.global.defaultFontColor = (this.props.paletteType === "dark" ? "white" : "black");

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={VerintCESTheme}>
          <CssBaseline />
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/signin" component={Home}/>
              <Route exact path="/signout" component={Home}/>
              <Route exact path="/forgotpassword" component={Home}/>
              <Route exact path="/passwordreset/:reset" component={Home}/>
              <Route path="/mainmenu" component={Home}/>
              <Route path="/login_callback" component={LoginCallback}/>
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps (state) {
  return {
    paletteType: state.paletteType
  }
}

export default connect(mapStateToProps)(App)
