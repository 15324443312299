
import React, { Component, Fragment } from 'react'
import { withCookies } from 'react-cookie'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import GroupApi from '../../../../libs/EdgeVMSCloudApi/Groups'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import SearchBox from '../../../custom/SearchBox'
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { NavTableFooter } from '../../../table'
import ROWS_PER_PAGE_OPTIONS from '../../../../constants/navParams'
import { COOKIES } from '../../../../constants/cookieDefs'
import { ConfirmAction, SelectUserDialog } from '../../../dialogs'
import { PARENT_TYPES } from '../../../dialogs/SelectUserDialog'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
});

class GroupUsersTab extends Component {
  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      currentPage: '0',
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      searchString: "",
    }

    this.state = {
      isLoading: true,
      showActionProgress: false,
      confirmDeleteActionOpen: false,
      selectUserDialogOpen: false,
      group: {},
      groupUsers : {},
      hasDataError: false,
    }

    this.userInAction = null
    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount () {
    this.abortController.abort();
  }

  async handleRefresh () {
    if (!this.props.newGroup && this.props.match.params.group) {

      this.setState({isLoading: true})

      let response = await GroupApi.FetchGroup(this.signal, this.props.match.params.group)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState ({group: response.ok ? response.body : {}})

      if (response.ok) {
        response = await GroupApi.FetchGroupUsers(this.signal, this.props.match.params.group, this.navParams)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({groupUsers: response.ok ? response.body : {}})
      } else {
        this.setState({groupUsers: {}})
      }

      this.setState({hasDataError: response.error ? true : false}, () => this.setState({isLoading: false}))
    } else {
      this.setState({isLoading: false})
    }
  };

  handleRequestSearch (event, value) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')

    if (value.length) {
      currentParams.set('searchString', value)
    } else {
      currentParams.delete('searchString')
    }

    window.location.search = currentParams.toString()
  };

  onDeleteActionClick (userInAction) {
    if (userInAction)
    {
      this.userInAction = userInAction
      this.setState({showActionProgress: false})
      this.setState({confirmDeleteActionOpen: true})
    }
  }

  async DeleteActionCallback (userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete && toDelete.id) {
      this.setState({showActionProgress: true})

      let response = await GroupApi.DeassignUser(this.signal, this.props.match.params.group, toDelete.id)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      await this.handleRefresh()
    }
    this.setState({confirmDeleteActionOpen: false})
  };


  render () {
    const { classes, session, newGroup } = this.props
    const { isLoading, group, groupUsers, hasDataError } = this.state
    const { currentPage, rowsPerPage, searchString } = this.navParams
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    const hasUpdatePermission = session.permissions[SERVICES.USER][OPERATIONS.UPDATE]

    if (newGroup) {
      return null
    }

    let { total, offset, users } = groupUsers
    if (users == null) {
      users = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Toolbar>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
              <Link component="button" variant="h6" href="/" color="inherit" title='Back To Groups'
                onClick={() => originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/admin/groups')}
              >
                Groups
              </Link>
              { group.name &&
                <Link component="button" variant="h6" href="/" color="inherit" title='Back to Details'
                  onClick={() => this.props.history.push(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')), this.props.location.state)}
                >
                  { group.name }
                </Link>
              }
              { group.name &&
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Users
                </Typography>
              }
            </Breadcrumbs>
            <Tooltip title="Back">
              <span>
                <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                  <ArrowBackIcon />
                </IconButton>
              </span>
            </Tooltip>

            { hasUpdatePermission &&
              <Tooltip title={"Add User To Group"}>
                <span>
                  <IconButton id="add-user-icon" disabled={isLoading || hasDataError} onClick={() => this.setState({selectUserDialogOpen: true})}>
                    <PersonAddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }

            <Tooltip title="Refresh">
              <span>
                <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
          <Box display="flex" justifyContent="flex-end">
            <SearchBox
              className={classes.formControl}
              disabled={isLoading}
              onSearch={this.handleRequestSearch.bind(this)}
              searchString={searchString}
            />
          </Box>
          { isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Fragment>
              <CustomTable>
                <CustomTableHead>
                  <TableRow selected={true}>
                    <TableCell>Name</TableCell>
                    <TableCell>Username</TableCell>
                    { hasUpdatePermission &&
                      <TableCell align="center">Actions</TableCell>
                    }
                  </TableRow>
                </CustomTableHead>
                {users.length ? (
                  <TableBody>
                    {users.map((user) => {
                      return (
                        <TableRow hover key={user.id}>
                          <TableCell title="Click To View User Details" onClick={() => this.props.history.push("/mainmenu/admin/users/details/" + user.id)}>
                            {user.name}
                          </TableCell>
                          <TableCell title="Click To View User Details" onClick={() => this.props.history.push("/mainmenu/admin/users/details/" + user.id)}>
                            {user.username}
                          </TableCell>
                          { hasUpdatePermission &&
                            <TableCell className={classes.actionsCell}>
                              {!user.federated &&
                                <Tooltip title="Remove From Group">
                                  <IconButton
                                    size={'small'}
                                    onClick={this.onDeleteActionClick.bind(this, user)}
                                  >
                                    <ClearIcon fontSize={'small'} />
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                        </TableRow>
                    )})}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={3}>No Users Assigned</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <NavTableFooter
                  labelRowsPerPage='Users per page'
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  count={total}
                  rowsPerPage={parseInt(rowsPerPage, 10)}
                  currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                />
              </CustomTable>
            </Fragment>
          )}
        </Container>

        <SelectUserDialog
          open={this.state.selectUserDialogOpen === true}
          session={session}
          parentType={PARENT_TYPES.GROUP}
          parentGroup={group}
          accountFilter={group.account_id}
          doneCallback={() => this.setState({selectUserDialogOpen: false}, () => this.handleRefresh())}
          assignUser={async (signal, userId) => await GroupApi.AssignUser(signal, group.id, userId)}
          deassignUser={async (signal, userId) => await GroupApi.DeassignUser(signal, group.id, userId)}
        />

        <ConfirmAction
          title="Remove User From Group ?"
          open={this.state.confirmDeleteActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to remove user from group..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.userInAction}
        >
          {`Are you sure you want to remove ${this.userInAction ? "\"" + this.userInAction.name + "\"" : "user"} from ${group.name ? "\"" + group.name + "\"" : "this"} group ?`}
        </ConfirmAction>

      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(GroupUsersTab))
