import React from 'react';
import Select from '@material-ui/core/Select';

function SelectBelow(props) {
   const { classes, children, ...other } = props;

   return (
      <Select
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          }
        }}
        {...other}
      >
         {children}
      </Select>
   );
}

export default SelectBelow