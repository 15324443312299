import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SyncIcon from '@material-ui/icons/Sync';
import Add from '@material-ui/icons/Add';
import CustomTableHead from '../../custom/CustomTableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SelectBelow from '../../custom/SelectBelow';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import CaseApi from '../../../libs/EdgeVMSCloudApi/Cases'
import CardView from './CardView'
import ListView from './ListView'
import Title from '../../custom/Title'
import PagePaper from '../../custom/PagePaper'
import 'url-search-params-polyfill'
import CustomTable from '../../custom/CustomTable';
import CustomCircularProgress from '../../custom/CustomCircularProgress';
import SearchBox from '../../custom/SearchBox'
import { GetNavParamsFromLocation } from '../../../libs/Utils'
import { NavTableFooter } from '../../table'
import { SERVICES, OPERATIONS } from '../../../constants/permissions'
import ROWS_PER_PAGE_OPTIONS from '../../../constants/navParams'
import { COOKIES } from '../../../constants/cookieDefs'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  topAlign: {
    verticalAlign: 'top',
    backgroundColor: theme.palette.secondary.main
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
});

const sorts = [
  {id: 0, label: 'Newest Cases',             orderBy: 'incident_date', order: 'desc'},
  {id: 1, label: 'Oldest Cases',             orderBy: 'incident_date', order: 'asc'},
  {id: 2, label: 'Recently Created',         orderBy: 'created_at',    order: 'desc'},
  {id: 3, label: 'Expiring Soon',            orderBy: 'expires_at',    order: 'asc'},
  {id: 4, label: 'Name (A-Z)',               orderBy: 'incident_name', order: 'asc'},
  {id: 5, label: 'Name (Z-A)',               orderBy: 'incident_name', order: 'desc'},
  {id: 6, label: 'Status',                   orderBy: 'status',        order: 'asc'},
  {id: 7, label: 'Best Match',               orderBy: 'score',         order: 'desc'},
]

class VideoExchange extends Component {

  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      currentPage: '0',
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      listView: cookies.get(COOKIES.USE_LIST_VIEW.name),
      sortIdx: '0',
      searchString: '',
    }

    this.state = {
      caseListResp: {},
      isLoading: true,
      confirmDeleteActionOpen: false,
      confirmEmailActionOpen: false,
      showActionProgress: false,
    }

    this.idInAction = null
    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
}

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async onConfirmDelete() {
    await this.handleRefreshTable();
  }

  async handleRefreshTable () {
    this.setState({isLoading: true})
    let navParams = {...this.navParams}
    let sortIdx = parseInt(navParams.sortIdx, 10)

    if (sortIdx < sorts.length) {
      navParams.orderBy = sorts[sortIdx].orderBy
      navParams.order   = sorts[sortIdx].order
    } else {
      navParams.orderBy = sorts[0].orderBy
      navParams.order   = sorts[0].order
    }

    const response = await CaseApi.FetchCaseList(this.signal, navParams)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({caseListResp: response.ok ? response.body : {}})
    this.setState({isLoading: false})
  };

  handleChangeView (event) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('listView', event.target.checked === true ? "true" : "false")
    window.location.search = currentParams.toString()
  };

  handleRequestSearch (event, value) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')

    if (value.length) {
      currentParams.set('searchString', value)
    } else {
      currentParams.delete('searchString')
    }

    window.location.search = currentParams.toString()
  };

  onDetailsActionClick (caseId) {
    this.props.history.push(this.props.location.pathname + '/details/' + caseId)
  };

  handleChangeSortBy (event) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')
    currentParams.set('sortIdx', event.target.value)
    window.location.search = currentParams.toString()
  };

  submitTextHandler = event => {
    this.handleRequestSearch(event, document.getElementById('SearchTextField').value)
  };

  onKeyPressHandler = event => {
    event.persist()
    if (event.key === 'Enter') {
      this.handleRequestSearch(event, event.target.value)
    }
  };

  render () {
    const { classes, session } = this.props
    const { caseListResp, isLoading } = this.state
    const { currentPage, rowsPerPage, searchString } = this.navParams
    const isListView = this.navParams.listView === 'true'

    let { cases, total } = caseListResp
    if (cases == null) {
      cases = []
    }

    if (total == null) {
      total = 0
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Video Xchange
              </Title>

              <FormControlLabel
                control={<Switch checked={isListView} color='primary' onChange={this.handleChangeView.bind(this)} />}
                label="List View"
              />

              { session.permissions[SERVICES.CASE][OPERATIONS.CREATE] &&
                <Tooltip title="Create new case">
                  <span>
                    <IconButton id="new-case-icon" disabled={isLoading} onClick={ () => this.props.history.push(this.props.location.pathname + '/new') }>
                      <Add/>
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            <Box display="flex" justifyContent="flex-end">
              <FormControl className={classes.formControl} disabled={isLoading}>
                <InputLabel shrink>
                  Sort By
                </InputLabel>
                <SelectBelow
                  value={this.navParams.sortIdx}
                  onChange={this.handleChangeSortBy.bind(this)}
                  className={classes.selectEmpty}
                >
                  {sorts.map((sort) => {
                    return <MenuItem key={sort.id} value={sort.id}>{sort.label}</MenuItem>
                  })}
                </SelectBelow>
              </FormControl>

              <SearchBox
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
            </Box>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <div style={isListView ? {overflow: 'auto'} : {}}>
                  <CustomTable>
                    { isListView &&
                      <Fragment>
                        <CustomTableHead>
                          <TableRow selected={true}>
                            <TableCell align='left'   className={classes.topAlign}>Identifier</TableCell>
                            <TableCell align='left'   className={classes.topAlign}>Name</TableCell>
                            <TableCell align='left'   className={classes.topAlign}>Occurred On</TableCell>
                            <TableCell align='left'   className={classes.topAlign}>Expires On</TableCell>
                            <TableCell align='left'   className={classes.topAlign}>Status</TableCell>
                            <TableCell align='center' className={classes.topAlign}>Actions</TableCell>
                          </TableRow>
                        </CustomTableHead>

                        <ListView
                          caseListResp={caseListResp}
                          session={session}
                          onDetailsActionClick={this.onDetailsActionClick.bind(this)}
                          onConfirmDelete={this.onConfirmDelete.bind(this)}
                        />
                      </Fragment>
                    }
                    <NavTableFooter
                      labelRowsPerPage='Cases per page'
                      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                      count={total}
                      rowsPerPage={parseInt(rowsPerPage, 10)}
                      currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                    />
                  </CustomTable>
                </div>

                { !isListView &&
                  <CardView
                    cases={cases}
                    session={session}
                    onDetailsActionClick={this.onDetailsActionClick.bind(this)}
                    onConfirmDelete={this.onConfirmDelete.bind(this)}
                  />
                }
              </Fragment>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(VideoExchange))
