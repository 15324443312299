import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import MaintenanceNotification from './MaintenanceNotification'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

class Notifications extends Component {

  render () {
    const { classes, session } = this.props

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Notifications
              </Title>
            </Toolbar>

            { session.permissions[SERVICES.MAINTENANCE][OPERATIONS.READ] &&
              <MaintenanceNotification session={session}/>
            }
          </PagePaper>
        </Container>

      </div>
    )
  }
}

export default withStyles(useStyles)(Notifications)
