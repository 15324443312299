import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import { TableCell, Toolbar, Typography, Select, InputBase, MenuItem, Tooltip } from '@material-ui/core'
import { KeyboardDateTimePicker } from "@material-ui/pickers"

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: 'auto',
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
    },
  },
  actions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  toolbar: {
    minHeight: 52,
    paddingRight: 2,
  },
  spacer: {
    flex: '1 1 100%',
  },
  caption: {
    flexShrink: 0,
  },
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: 'right',
    textAlignLast: 'right', // Align <select> on Chrome.
  },
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
    marginRight: 32,
    marginLeft: 8,
  },
  menuItem: {},
  picker: {
    marginBottom: 15,
    marginTop: 0,
  },

}));

function KeysetPagination(props) {
  const classes = useStyles();
  const { nextkey, prevkey } = props;
  const { labelRowsPerPage, rowsPerPage, rowsPerPageOptions, onChangePage, onChangeRowsPerPage, SelectProps = {} } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, null, null);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, prevkey, null);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, null, nextkey);
  };

  const handleStartDateTimeChange = date => {
    const isValidDate  = date instanceof Date && !isNaN(date.valueOf())

    if (isValidDate) {
      onChangePage(null, null, date.toISOString())
    }
  };


  const MenuItemComponent = SelectProps.native ? 'option' : MenuItem;

  return (
    <TableCell className={classes.root} colSpan={1000}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.spacer} />

        {rowsPerPageOptions.length > 1 && (
          <Typography color="inherit" variant="body2" className={classes.caption} id="rows-per-page-label">
            {labelRowsPerPage}
          </Typography>
        )}

        {rowsPerPageOptions.length > 1 && (
          <Select
            classes={{
              select: classes.select
            }}
            input={<InputBase className={classes.input} />}
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
            id="rows-per-page-select"
            {...SelectProps}
          >
            {rowsPerPageOptions.map((rowsPerPageOption) => (
              <MenuItemComponent
                className={classes.menuItem}
                key={rowsPerPageOption.value ? rowsPerPageOption.value : rowsPerPageOption}
                value={rowsPerPageOption.value ? rowsPerPageOption.value : rowsPerPageOption}
              >
                {rowsPerPageOption.label ? rowsPerPageOption.label : rowsPerPageOption}
              </MenuItemComponent>
            ))}
          </Select>
        )}

        <KeyboardDateTimePicker
          className={classes.picker}
          disableFuture
          margin="normal"
          name="start_date_time"
          label="Starting At"
          id="start_date_time"
          autoComplete="start_date_time"
          fullWidth
          value={null}
          onChange={(dateTime) => handleStartDateTimeChange(dateTime, "start_date_time")}
          format="MM/dd/yyyy, hh:mm a"
          mask="__/__/____, __:__ _M"
        />

        <div className={classes.actions}>
          <Tooltip title="Restart">
            <span>
              <IconButton
                id="table-first-page-icon"
                onClick={handleFirstPageButtonClick}
                disabled={prevkey === null}
                aria-label="first page"
              >
                <FirstPageIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Previous">
            <span>
              <IconButton
                id="table-previous-page-icon"
                onClick={handleBackButtonClick}
                disabled={prevkey === null}
                aria-label="previous page"
              >
                <KeyboardArrowLeft />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Next">
            <span>
              <IconButton
                id="table-next-page-icon"
                onClick={handleNextButtonClick}
                disabled={nextkey === null}
                aria-label="next page"
              >
                <KeyboardArrowRight />
              </IconButton>
            </span>
          </Tooltip>
        </div>

      </Toolbar>
    </TableCell>
  );
}

KeysetPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func,
  labelRowsPerPage: PropTypes.string,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array,
};

export default KeysetPagination