import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { NotFound } from '../../errors'
import AccountRouter from './Accounts/AccountRouter'
import UserRouter from './Users/UserRouter'
import RoleRouter from './Roles/RoleRouter'
import GroupRouter from './Groups/GroupRouter'
import Licenses from './Licenses/Licenses'
import ActivityLog from './ActivityLog/ActivityLog'
import AccessKeys from './AccessKeys/AccessKeys'
import Notifications from './Notifications/Notifications'
import { SERVICES, OPERATIONS } from '../../../constants/permissions'

class AdminRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>

        <Route
          path='/mainmenu/admin/accounts'
          render={(props) => <AccountRouter {...props} session={session}/>}
        />

        <Route
          path='/mainmenu/admin/users'
          render={(props) => <UserRouter {...props} session={session}/>}
        />

        <Route
          path='/mainmenu/admin/roles'
          render={(props) => <RoleRouter {...props} session={session}/>}
        />

        <Route
          path='/mainmenu/admin/groups'
          render={(props) => <GroupRouter {...props} session={session}/>}
        />

        { session.permissions[SERVICES.USER][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/accesskeys'
            render={(props) => <AccessKeys {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.LICENSE][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/licenses'
            render={(props) => <Licenses {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.MAINTENANCE][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/notifications'
            render={(props) => <Notifications {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.USERACTIVITY][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/activities'
            render={(props) => <ActivityLog {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default AdminRouter
