import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import PagePaper from '../../../custom/PagePaper';
import UsersApi from '../../../../libs/EdgeVMSCloudApi/Users'
import PwdResetApi from '../../../../libs/EdgeVMSCloudApi/PasswordReset'
import { Forbidden } from '../../../errors'
import { InfoDialog } from '../../../dialogs';
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'


const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class UserResetPassword extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      hasDataError: false,
      alertSuccess: false,
      user: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount () {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  async handleRefresh () {
    let response = {ok: true}

    this.setState({isLoading: true})

    if (this.props.match.params.user) {
      let user = {}
      response = await UsersApi.FetchUser(this.signal, this.props.match.params.user)
      if (response.error && (response.error.name === "AbortError")) {
        return;
      }

      if (response.ok) {
        user = response.body
      }

      this.setState ({user: user})
    }

    if (response.error) {
      this.setState({hasDataError: true})
    }

    this.setState({isLoading: false})
  };

  async handleResetRequest() {
    const { user } = this.state

    this.setState({isLoading: true})
    const response = await PwdResetApi.CreateResetRequest(this.signal, user.email, true)

    if (response.error) {
      if (response.error.name === "AbortError") {
        return;
      } else if (response.error.message) {
        this.setState({error: response.error.message})
      } else {
        this.setState({error: 'Something went wrong.'})
      }
    } else {
      this.setState({alertSuccess: true})
    }

    this.setState({isLoading: false})
  }

  successAck() {
    this.setState({alertSuccess: false})
  }

  render () {
    const { classes, federated, session } = this.props
    const { isLoading, user } = this.state
    const username = user.name

    if (!isLoading && user.temporary !== undefined && user.temporary !== 0) {
      return <Forbidden/>
    }
    if (federated ||
        !session.permissions[SERVICES.USER][OPERATIONS.UPDATE])
    {
      return <Forbidden/>
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Reset Password
                </Typography>
                { username &&
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    {username}
                  </Typography>
                }
              </Breadcrumbs>

              <Tooltip title="Back">
                <span>
                  <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                    <ArrowBackIcon />
                  </IconButton>
                </span>
              </Tooltip>

            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <div className={classes.form}>
                <Grid container spacing={1} justify="center" alignItems="center">
                  <Grid item xs={false} sm={1} md={3} lg={4}>
                  </Grid>
                  <Grid item xs={12} sm={10} md={6} lg={4}>
                    <Typography>
                      Click the button below to start the password reset process for {username}.
                    </Typography>
                  </Grid>
                  <Grid item xs={false} sm={1} md={3} lg={4}>
                  </Grid>
                </Grid>

                <Button
                  id="pwd-reset-button"
                  onClick={this.handleResetRequest.bind(this)}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Request Password Reset
                </Button>
              </div>
            )}
          </PagePaper>
        </Container>

        <InfoDialog
          open={this.state.alertSuccess}
          actionCallback={this.successAck.bind(this)}
        >
          Password reset email sent to {username}.
        </InfoDialog>
      </div>
    )
  }
}

export default withStyles(useStyles)(UserResetPassword)
