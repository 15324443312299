import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PagePaper from '../../../custom/PagePaper';
import ChangePasswordContents from '../../../custom/ChangePasswordContents'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

class UserChangePassword extends Component {
  constructor (props) {
    super(props)

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  render () {
    const { classes, session, variant } = this.props
    const passwordExpiredVariant = variant === "passwordExpired"
    const userid = session.accessToken.decoded.sub.id
    const username = session.accessToken.decoded.sub.username

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  {passwordExpiredVariant ? "Password Expired" : "Change Password"}
                </Typography>
                { username &&
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    {username}
                  </Typography>
                }
              </Breadcrumbs>
            </Toolbar>

            <ChangePasswordContents
              userid={userid}
              session={session}
              variant={variant}
            />
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(UserChangePassword)
