import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FaceApi from '../../../libs/EdgeVMSCloudApi/Face'
import Title from '../../custom/Title'
import PagePaper from '../../custom/PagePaper'
import CustomTable from '../../custom/CustomTable';
import CustomTableHead from '../../custom/CustomTableHead';
import CustomCircularProgress from '../../custom/CustomCircularProgress';

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

class Face extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      faceCollections: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefreshTable () {
    this.setState({isLoading: true})
    const response = await FaceApi.FetchCollectionList(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({faceCollections: response.ok ? response.body : {}})
    this.setState({isLoading: false})
  };

  render () {
    const { classes, session } = this.props
    const { isLoading, faceCollections } = this.state
    let total_faces = 'N/A'
    let collections = []

    if (faceCollections.total_faces) {
      total_faces = faceCollections.total_faces
    }

    if (faceCollections.collections) {
      collections = faceCollections.collections
    }

    if (!session.isLoggedIn){
      return null
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Face Collection List
              </Title>

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <CustomTable>
                <CustomTableHead>
                  <TableRow selected={true}>
                    <TableCell></TableCell>
                    <TableCell>Collection Name</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell align='center'>Face Model Version</TableCell>
                    <TableCell align='right'>Enrolled Faces</TableCell>
                  </TableRow>
                </CustomTableHead>
                {collections.length ? (
                  <TableBody>
                    {collections.map((collection, key) => {
                      let created_at  = new Date(collection.created_at)
                      return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">{key+1}</TableCell>
                          <TableCell> {collection.name} </TableCell>
                          <TableCell> {created_at.toLocaleString('en-US')} </TableCell>
                          <TableCell align='center'> {collection.model_version} </TableCell>
                          <TableCell align='right'> {collection.face_count} </TableCell>
                        </TableRow>
                    )})}
                    <TableRow>
                      <TableCell colSpan={3}></TableCell>
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right">{total_faces}</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={5}> No Collections</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </CustomTable>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(Face)
