import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

const useStyles = () => ({
   table: {
      minWidth: 100,
   },
});

function CustomTable(props) {
   const { classes, children, ...other } = props;

   return (
      <Table className={classes.table} size="small" {...other}>
         {children}
      </Table>
   );
}

export default withStyles(useStyles)(CustomTable)