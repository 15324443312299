import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


const useStyles = (theme) => ({
  page: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
  },
  code: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  details: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(4),
  },
});

const errorDefs = [
  {
    code: 403,
    text: "Forbidden",
    details: "Access to the page or resource you are trying to reach is forbidden."
  },
  {
    code: 404,
    text: "Page not found",
    details: "This is usually due to a broken link or an invalid URL entered in the browser address bar."
  }
];

class HttpError extends Component {

  render () {
    const { classes, code } = this.props;

    const error = errorDefs.find(def => def.code === code)

    return (
      <div className={classes.page}>
        { error ? (
          <Fragment>
            <Typography variant="h2" className={classes.code}>
              {error.code}
            </Typography>
            <Typography variant="h4" className={classes.code}>
              {error.text}
            </Typography>
            <Typography variant="h6" className={classes.details}>
              {error.details}
            </Typography>
          </Fragment>
        ) : (
          <Typography variant="h4" className={classes.code}>
            Got undefined error code={code}
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {this.props.history.goBack()}}
        >
          Go Back
        </Button>
      </div>
    );
  }
};

HttpError.propTypes = {
  code: PropTypes.number.isRequired,
};

export default withRouter(withStyles(useStyles)(HttpError))
