import React, { Component, Fragment } from 'react'
import { withCookies } from 'react-cookie'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import UserApi from '../../../../libs/EdgeVMSCloudApi/Users'
import GroupApi from '../../../../libs/EdgeVMSCloudApi/Groups'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import SearchBox from '../../../custom/SearchBox'
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { NavTableFooter } from '../../../table'
import ROWS_PER_PAGE_OPTIONS from '../../../../constants/navParams'
import { COOKIES } from '../../../../constants/cookieDefs'
import { ConfirmAction, SelectGroupDialog } from '../../../dialogs'
import { PARENT_TYPES } from '../../../dialogs/SelectGroupDialog'
import { Forbidden } from '../../../errors'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
});

class UserGroupTab extends Component {
  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      currentPage: '0',
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      searchString: "",
    }

    this.dialogVariant = !!(props.variant && props.variant === "dialog")

    this.state = {
      isLoading: true,
      showActionProgress: false,
      confirmDeleteActionOpen: false,
      selectGroupDialogOpen: false,
      user: {},
      userGroups : {},
      hasDataError: false,
    }

    this.idInAction = null
    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount () {
    this.abortController.abort();
  }

  async handleRefresh () {
    let userId = null
    if (this.dialogVariant) {
      if (this.state.user && this.state.user.id) {
        userId = this.state.user.id
      } else {
        userId = this.props.dialogContext.userId
      }
    } else {
      userId = this.props.match.params.user
    }

    if (!this.props.newUser && userId) {
      this.setState({isLoading: true})

      let response = await UserApi.FetchUser(this.signal, userId)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState ({ user: response.ok ? response.body : {}})

      if (response.ok) {
        response = await UserApi.FetchUserGroups(this.signal, userId, !this.dialogVariant ? this.navParams : { currentPage: 0, rowsPerPage: 100 })
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        this.setState({userGroups: response.ok ? response.body : {}})
      } else {
        this.setState({userGroups:  {}})
      }

      this.setState({hasDataError: response.error ? true : false}, () => this.setState({isLoading: false}))

      if (this.props.dialogCallback) {
        this.props.dialogCallback({ok: !!response.ok, objectId: userId})
      }
    } else {
      this.setState({isLoading: false})
    }
  };

  handleRequestSearch (event, value) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')

    if (value.length) {
      currentParams.set('searchString', value)
    } else {
      currentParams.delete('searchString')
    }

    window.location.search = currentParams.toString()
  };

  onDeleteActionClick (idInAction) {
    if (idInAction)
    {
      this.idInAction = idInAction
      this.setState({showActionProgress: false})
      this.setState({confirmDeleteActionOpen: true})
    }
  }

  async DeleteActionCallback (userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete && toDelete.id) {
      let userId = null
      if (this.dialogVariant) {
        if (this.state.user && this.state.user.id) {
          userId = this.state.user.id
        } else {
          userId = this.props.dialogContext.userId
        }
      } else {
        userId = this.props.match.params.user
      }

      this.setState({showActionProgress: true})

      let response = await GroupApi.DeassignUser(this.signal, toDelete.id, userId)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      await this.handleRefresh()
    }
    this.setState({confirmDeleteActionOpen: false})
  };


  render () {
    const { classes, session, readOnly } = this.props
    const { isLoading, user, userGroups, hasDataError } = this.state
    const newUser = this.props.newUser || (this.dialogVariant && !(user && user.id))
    const { currentPage, rowsPerPage, searchString } = this.navParams
    const originLocation = this.props.location.state ? this.props.location.state.originLocation : undefined
    const hasUpdatePermission = !readOnly && !user.federated && session.permissions[SERVICES.USER][OPERATIONS.UPDATE]

    if (newUser) {
      return null
    }

    if (!newUser && !isLoading && user.temporary !== undefined && user.temporary !== 0) {
      return <Forbidden/>
    }

    let { total, offset, groups } = userGroups
    if (groups == null) {
      groups = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Toolbar>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
              { !this.dialogVariant ? (
                <Link component="button" variant="h6" href="/" color="inherit" title='Back To Users'
                  onClick={() => originLocation ? window.location.href = originLocation : this.props.history.push('/mainmenu/admin/users')}
                >
                  Users
                </Link>
              ) : (
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Users
                </Typography>
              )}

              { user.name && !this.dialogVariant &&
                <Link component="button" variant="h6" href="/" color="inherit" title='Back to Details'
                  onClick={() => this.props.history.push(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')), this.props.location.state)}
                >
                  { user.name }
                </Link>
              }

              { user.name && this.dialogVariant &&
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  { user.name }
                </Typography>
              }

              { user.name &&
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Groups
                </Typography>
              }
            </Breadcrumbs>

            { !this.dialogVariant &&
              <Tooltip title="Back">
                <span>
                  <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                    <ArrowBackIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }

            { hasUpdatePermission &&
              <Tooltip title={"Add User To Group"}>
                <span>
                  <IconButton id="add-to-group-icon" disabled={isLoading || hasDataError} onClick={() => this.setState({selectGroupDialogOpen: true})}>
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }

            <Tooltip title="Refresh">
              <span>
                <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>

          { !this.dialogVariant &&
            <Box display="flex" justifyContent="flex-end">
              <SearchBox
                className={classes.formControl}
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
            </Box>
          }

          { isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Fragment>
              <CustomTable>
                <CustomTableHead>
                  <TableRow selected={true}>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    { hasUpdatePermission &&
                      <TableCell align="center">Actions</TableCell>
                    }
                  </TableRow>
                </CustomTableHead>
                {groups.length ? (
                  <TableBody>
                    {groups.map((group) => {
                      return (
                        <TableRow hover key={group.id}>
                          <TableCell title={this.dialogVariant ? "" : "Click To View Group Details"} onClick={this.dialogVariant ? null : () => this.props.history.push("/mainmenu/admin/groups/details/" + group.id)}>
                            {group.name}
                          </TableCell>
                          <TableCell title={this.dialogVariant ? "" : "Click To View Group Details"} onClick={this.dialogVariant ? null : () => this.props.history.push("/mainmenu/admin/groups/details/" + group.id)}>
                            {group.description}
                          </TableCell>
                          { hasUpdatePermission &&
                            <TableCell className={classes.actionsCell}>
                              <Tooltip title="Remove From User">
                                <IconButton
                                  size={'small'}
                                  onClick={this.onDeleteActionClick.bind(this, group)}
                                >
                                  <ClearIcon fontSize={'small'} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          }
                        </TableRow>
                    )})}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={hasUpdatePermission ? 3 : 2}>No Group Assignation</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                { !this.dialogVariant &&
                  <NavTableFooter
                    labelRowsPerPage='Groups per page'
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    count={total}
                    rowsPerPage={parseInt(rowsPerPage, 10)}
                    currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                  />
                }
              </CustomTable>
            </Fragment>
          )}
        </Container>

        <SelectGroupDialog
          open={this.state.selectGroupDialogOpen === true}
          session={session}
          parent={user}
          parentType={PARENT_TYPES.USER}
          doneCallback={() => this.setState({selectGroupDialogOpen: false}, () => this.handleRefresh())}
          assignGroup={async (signal, groupId) => await GroupApi.AssignUser(signal, groupId, user.id)}
          deassignGroup={async (signal, groupId) => await GroupApi.DeassignUser(signal, groupId, user.id)}
        />

        <ConfirmAction
          title="Remove Group Assignment ?"
          open={this.state.confirmDeleteActionOpen === true}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to remove group assignment..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={this.idInAction}
        >
          {`Are you sure you want to remove ${user.name ? "\"" + user.name + "\"" : "this"} user assignment from ${this.idInAction ? "\"" + this.idInAction.name + "\"" : "this"} group ?`}
        </ConfirmAction>

      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(UserGroupTab))
