import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import AccountCreator from './AccountCreator'
import { NotFound } from '../../../errors'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

class AccountCreatorRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>
        { session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
          <Route
            path='/mainmenu/operations/accountcreator'
            render={(props) => <AccountCreator {...props} session={session} />}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default AccountCreatorRouter
