import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { clearSession, setSession } from '../../actions/session'
import { setPasswordExpired } from '../../actions/passwordExpired'
import Session from '../../libs/Session'
import { COOKIES, GetDefaultCookiesOptions } from '../../constants/cookieDefs'

class LoginCallback extends Component {

  componentDidMount () {
    this.props.setPasswordExpired(false)

    if (this.props.location.search) {
      let searchParams = new URLSearchParams(this.props.location.search)

      if (searchParams.has('access_token') && searchParams.has('refresh_token')) {
        const { cookies } = this.props
        Object.values(COOKIES).forEach(cookie => {
          const currentValue = cookies.get(cookie.name)
          cookies.set(cookie.name, currentValue ? currentValue : cookie.default, GetDefaultCookiesOptions())
        })
        this.props.setSession(new Session(searchParams.get('access_token'), searchParams.get('refresh_token')))
      } else {
        this.props.clearSession()
      }
    }
  }

  render () {
    return <Redirect to="/" />
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearSession: () => dispatch(clearSession()),
    setSession: session => dispatch(setSession(session)),
    setPasswordExpired: passwordExpired => dispatch(setPasswordExpired(passwordExpired))
  }
}

export default connect(null, mapDispatchToProps)(withCookies(withRouter(LoginCallback)))
