import React from 'react';
import StepperDialog from "./StepperDialog"
import LicenseEditor from '../../views/Admin/Accounts/LicenseEditor'

function LicenseEditorDialog(props) {
  return (
    <StepperDialog
       {...props}
       StepContent={LicenseEditor}
    />
  );
}

export default LicenseEditorDialog
