import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import ExchangeActions from './ExchangeActions'

const styles = {
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
};

class ListView extends Component {

  render () {
    const { caseListResp, classes, session, onConfirmDelete, onDetailsActionClick } = this.props

    let { cases, offset } = caseListResp
    if (cases == null) {
      cases = []
    }

    if (offset == null) {
      offset = 0
    }

    return (
      <Fragment>
        { cases.length ? (
          <TableBody>
            {cases.map((fields, key) => {
              let expires_at  = new Date(fields.expires_at)

              let occurred_on = 'N/A'
              if (fields.incident_date != null) {
                occurred_on = (new Date(fields.incident_date)).toLocaleString('en-US')
              }

              return(
                <TableRow hover key={fields.id}>
                  <TableCell onClick={() => onDetailsActionClick(fields.id)} title="Click for details" component="th" scope="row">{fields.descriptor}</TableCell>
                  <TableCell onClick={() => onDetailsActionClick(fields.id)} title="Click for details">{fields.incident_name}</TableCell>
                  <TableCell onClick={() => onDetailsActionClick(fields.id)} title="Click for details">{occurred_on}</TableCell>
                  <TableCell onClick={() => onDetailsActionClick(fields.id)} title="Click for details">{expires_at.toLocaleString('en-US')}</TableCell>
                  <TableCell onClick={() => onDetailsActionClick(fields.id)} title="Click for details">{fields.status}</TableCell>
                  <TableCell className={classes.actionsCell}>
                    <ExchangeActions
                      dense
                      caseId={fields.id}
                      session={session}
                      onConfirmDelete={onConfirmDelete}
                    />
                  </TableCell>
              </TableRow>
            )})}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align='center' colSpan={6}>No Cases</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Fragment>
    )
  }
}

ListView.propTypes = {
  caseListResp: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListView);
