import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

function PasswordRequirements(props) {
   return (
      <Fragment>
         <Typography variant="h6" color="inherit">
            Password requirements:
         </Typography>
         <ul>
            <li>
               <Typography variant="body1" color="inherit">
                  Minimum length of 8
               </Typography>
            </li>
            <li>
               <Typography variant="body1" color="inherit">
                  Must contain one of each of the following:
               </Typography>
            </li>
            <ul>
               <li>upper-case letter</li>
               <li>lower-case letter</li>
               <li>number</li>
               <li>special character [choices: !#$%]</li>
            </ul>
            <li>
               <Typography variant="body1" color="inherit">
                  Cannot be a previously used password
               </Typography>
            </li>
         </ul>
      </Fragment>
   );
}

export default PasswordRequirements;