import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { DatePicker } from "@material-ui/pickers";
import { DialogTitle, DialogContent, DialogActions } from '../custom/CustomDialogs'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: '150px',
  },
  leftMargin: {
    marginLeft: theme.spacing(4),
  },
  datePicker: {
    width: '200px',
    marginLeft: theme.spacing(4),
  }
}));

const REQUEST_TYPE = {
  ALL: "all",
  LIMITED: "limited"
}

function DownloadActivityLogsDialog(props) {
  const { open } = props
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false)
  const [requestType, setRequestType] = useState(REQUEST_TYPE.LIMITED)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [startDate, setStartDate] = useState(new Date(Date.now()))

  useEffect(() => {
    if (open) {
      setRequestType(REQUEST_TYPE.LIMITED)
      setStartDate(new Date(Date.now()))
      setOpenDialog(true)
    } else {
      setOpenDialog(false)
    }
  }, [open]);

  const handleDownloadClick = async () => {
    if (props.handleClose) {
      await props.handleClose("ok", requestType === REQUEST_TYPE.LIMITED ? startDate : null)
    }
  }

  const handelCancelClick = async () => {
    if (props.handleClose) {
      await props.handleClose("cancel", null)
    }
  }

  const handleChange = (event) => {
    setRequestType(event.target.value);
  };

  return (
    <Dialog
      open={openDialog}
      maxWidth='xs'
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle onClose={handelCancelClick}>
        Download Activity Logs
      </DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset">
          <RadioGroup aria-label="request_type" name="request_type" value={requestType} onChange={handleChange}>
            <FormControlLabel id="download-with-limit" value={REQUEST_TYPE.LIMITED} control={<Radio/>} label="Download Activity Logs From"/>

              <DatePicker
                className={classes.datePicker}
                open={openDatePicker}
                autoOk
                disableFuture
                margin="dense"
                variant="dialog"
                okLabel={null}
                inputVariant="outlined"
                name="start_date"
                label="Start Date"
                id="start_date"
                autoComplete="start_date"
                value={startDate}
                onChange={setStartDate}
                onClose={() => setOpenDatePicker(false)}
                onOpen={() => {
                  setRequestType(REQUEST_TYPE.LIMITED)
                  setOpenDatePicker(true)
                }}
                format="MM/dd/yyyy"
                InputProps={{
                  endAdornment:
                    <InputAdornment position={'end'}>
                      <Tooltip title="Select Start Date">
                        <span>
                          <IconButton id="select-start-date" size="small">
                            <EventIcon/>
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>,
                }}
              />

            <Box pt={3}>
              <FormControlLabel id="download-all" value={REQUEST_TYPE.ALL} control={<Radio/>} label="Download All Activity Logs" />
            </Box>
            <Typography className={classes.leftMargin}>
              Downloading all activity logs may take a long time.
              <br/>
              Instead, download activity logs starting from a specific date.
            </Typography>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          id="download-button"
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={handleDownloadClick}
          startIcon={<CheckIcon />}
        >
          Download
        </Button>

        <Button
          id="cancel-button"
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={handelCancelClick}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadActivityLogsDialog

