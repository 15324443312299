import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/sso'

async function FetchSSOConfig(signal, account) {
  let url = baseUrl
  if (account) {
    url += '?account_id=' + account
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function CreateSSOConfig(signal, ssoConfig) {
  return BaseApi.DoFetch(signal, baseUrl, 'POST', ssoConfig)
}

async function UpdateSSOConfig(signal, ssoConfig) {
  let url = baseUrl + '/' + ssoConfig.id

  return BaseApi.DoFetch(signal, url, 'PUT', ssoConfig)
}

async function DeleteSSOConfig(signal, ssoConfigID) {
  let url = baseUrl + '/' + ssoConfigID

  return BaseApi.DoFetch(signal, url, 'DELETE')
}

async function GetMetadata(signal) {
  let url = baseUrl + '/metadata'

  return await BaseApi.DoFetch(signal, url, 'GET')
}

const exportsSSO = {
  FetchSSOConfig,
  CreateSSOConfig,
  UpdateSSOConfig,
  DeleteSSOConfig,
  GetMetadata,
};
export default exportsSSO;
