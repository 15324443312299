import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Fragment>
      {value === index && <Fragment>{children}</Fragment>}
    </Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel