import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import MainMenu from '../../components/layouts/MainMenu'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { clearSession, setSession } from '../../actions/session'
import { setPasswordExpired } from '../../actions/passwordExpired'
import { setShowNotifications, setNotificationCount } from '../../actions/notifications'
import IdleChecker from './IdleChecker'
import UnsupportedBrowsersDialog from './UnsupportedBrowsersDialog'
import AuthApi from '../../libs/EdgeVMSCloudApi/Auth'
import appConfig from '../../config/AppConfig.js'

const styles = theme => ({
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})

class Home extends Component {

  constructor (props) {
    super(props)

    this.state = {
      showBackdrop: false
    }

    this.tokenRefreshTimerID = null
    this.tokenRefreshDPC = this.tokenRefreshDPC.bind(this)
  }

  componentDidMount () {
    this.installTokenRefreshDPC ()
  }

  componentWillUnmount () {
    if (this.tokenRefreshTimerID) {
      clearTimeout(this.tokenRefreshTimerID)
      this.tokenRefreshTimerID = null
    }
  }

  async tokenRefreshDPC () {
    const response = await AuthApi.RefreshSession(this.props.session)
    if (response.ok) {
      this.props.setSession(response.session)
      this.installTokenRefreshDPC()
    } else {
      await this.doSignOut()
      this.props.history.push('/signin')
    }
  }

  installTokenRefreshDPC () {
    const { session } = this.props

    if (this.tokenRefreshTimerID) {
      clearTimeout(this.tokenRefreshTimerID)
      this.tokenRefreshTimerID = null
    }

    if (session.isLoggedIn) {
      const now = new Date()
      const secondsSinceEpoch = Math.round(now.getTime() / 1000)
      const refreshDelaymSec = ((session.accessToken.decoded.exp-secondsSinceEpoch-300)*1000) // Refresh the token 5 minutes before it expires
      this.tokenRefreshTimerID = setTimeout(this.tokenRefreshDPC, refreshDelaymSec)
    }
  }

  async doSignOut () {
    this.setState({showBackdrop: true}, async () => {
      if (this.tokenRefreshTimerID) {
        clearTimeout(this.tokenRefreshTimerID)
        this.tokenRefreshTimerID = null
      }

      if (this.props.session.isLoggedIn)
      {
        const response = await AuthApi.SignOut()
        if (response.ok && response.body && response.body.redirect_url) {
          window.location.href = response.body.redirect_url;
        }
      }

      this.props.clearSession()
      this.props.setPasswordExpired(false)
      this.props.setShowNotifications(0)
      this.props.setShowNotifications(true)
      this.props.history.push('/')
      this.setState({showBackdrop: false})
    })
  }

  render () {
    const { classes } = this.props

    if (!this.props.session.isLoggedIn || (this.props.session.isLoggedIn && this.tokenRefreshTimerID === null)) {
      this.installTokenRefreshDPC()
    }

    return (
      <div>
        { this.props.session.isLoggedIn && !this.props.uploadState &&
          <IdleChecker
            logout
            idleTimeoutSec={appConfig.idleTimeoutSec}
            promptTimeout={30}  // 30 seconds
            onPromptTimerElapsed={async () => await this.doSignOut()}
          />
        }

        <MainMenu
          session={this.props.session}
          doSignOut={this.doSignOut.bind(this)}
        />

        <UnsupportedBrowsersDialog />

        <Backdrop className={classes.backdrop} open={this.state.showBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

      </div>
      )
    }
  }

function mapStateToProps (state) {
  return {
    session: state.session,
    uploadState: state.uploadState,
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearSession: () => dispatch(clearSession()),
    setSession: session => dispatch(setSession(session)),
    setPasswordExpired: passwordExpired => dispatch(setPasswordExpired(passwordExpired)),
    setShowNotifications: showNotifications => dispatch(setShowNotifications(showNotifications)),
    setNotificationCount: notificationCount => dispatch(setNotificationCount(notificationCount)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Home)))
