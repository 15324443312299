import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'

const baseUrl = appConfig.apiUri + '/api/admin/notification'

async function FetchNotification(signal) {
  return await BaseApi.DoFetch(signal, baseUrl, 'GET')
}

const exportsNotification = {
  FetchNotification,
};
export default exportsNotification;