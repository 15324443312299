import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChangePasswordContents from '../custom/ChangePasswordContents'
import IdleChecker from '../../pages/Home/IdleChecker'

const useStyles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

class ChangePasswordDialog extends Component {

  onCloseClick () {
    this.props.handleClose()
  }

  render () {
    const { classes, open, session } = this.props

    return (
      <Dialog
        disableScrollLock
        maxWidth='sm'
        fullWidth
        open={open}
        onClose={this.onCloseClick.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography id="alert-dialog-title">
          <Typography variant="h5">
            Change Password
          </Typography>
          <IconButton
            id="dialog-close-icon"
            aria-label="close"
            className={classes.closeButton}
            onClick={this.onCloseClick.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ChangePasswordContents
            userid={session.accessToken.decoded.sub.id}
            session={session}
            onClose={this.onCloseClick.bind(this)}
          />
        </DialogContent>

        <IdleChecker
          idleTimeoutSec={1*60}  // 1 minute
          promptTimeout={10}  // 10 seconds
          onPromptTimerElapsed={this.onCloseClick.bind(this)}
        />
      </Dialog>
    )
  }
}

export default withStyles(useStyles)(ChangePasswordDialog)
