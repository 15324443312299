import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PublishIcon from '@material-ui/icons/Publish';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import PagePaper from '../../../custom/PagePaper';
import AccountsApi from '../../../../libs/EdgeVMSCloudApi/Accounts'
import SSOApi from '../../../../libs/EdgeVMSCloudApi/SSO'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import { ConfirmGoBack, ConfirmAction } from '../../../dialogs'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import copy from 'clipboard-copy'
import { downloadBlob } from '../../../../libs/Utils'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  actionsDropDown: {
    marginRight: theme.spacing(6),
  },
  actionsMenuIcon: {
    minWidth: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    overflow: 'auto',
    width: '100%',
  },
  infoIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

const X509_CERTIFICATE = {
  header: "-----BEGIN CERTIFICATE-----",
  footer: "-----END CERTIFICATE-----"
}

class SSODetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      hasChanged: false,
      hasDataError: false,
      userMenuAnchor: null,
      showDeleteActionProgress:  false,
      confirmDeleteActionOpen: false,
      ssoConfig: {},
      account: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount () {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  async handleRefresh () {
    if (this.props.match.params.account) {
      let response = {}

      this.setState({isLoading: true})
      response = await AccountsApi.FetchAccount(this.signal, this.props.match.params.account)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState ({ account: response.ok ? response.body : {} })


      if (response.ok) {
        let ssoConfig = {}

        response = await SSOApi.FetchSSOConfig(this.signal, this.props.match.params.account)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        if (response.ok) {
          let ssoConfigs = response.body
          if (ssoConfigs.length > 0) {
            // For now only use the one SSO provider
            ssoConfig  = ssoConfigs[0]
          }
        }
        this.setState ({ ssoConfig })
      }

      this.setState({hasDataError: response.error ? true : false}, () => this.setState({isLoading: false}))
    }
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      hasChanged: true
    });
  }

  async handleSubmit(event) {
    event.preventDefault()

    let values = {idp_sls_url: ""}

    if (this.state.domain != null) {
      values.domain = this.state.domain
    }

    if (this.state.idp_clientid != null) {
      values.idp_clientid = this.state.idp_clientid
    }

    if (this.state.idp_entity != null) {
      values.idp_entity = this.state.idp_entity
    }

    if (this.state.idp_sso_url != null) {
      values.idp_sso_url = this.state.idp_sso_url
    }

    if (this.state.idp_slo_url != null) {
      values.idp_slo_url = this.state.idp_slo_url
    }

    if (this.state.idp_x509_cert != null) {
      values.idp_x509_cert = this.state.idp_x509_cert.replace(X509_CERTIFICATE.header, "")
                                                     .replace(X509_CERTIFICATE.footer, "")
                                                     .replace(/\r/g, "")
                                                     .replace(/\n/g, "")
                                                     .trim()
    }

    this.setState({isLoading: true})

    let response = {}
    if (this.state.ssoConfig.id) {
      values.id = this.state.ssoConfig.id
      response = await SSOApi.UpdateSSOConfig(this.signal, values)
    } else {
      values.account_id = this.state.account.id
      response = await SSOApi.CreateSSOConfig(this.signal, values)
    }

    if (response.error && response.error.name === "AbortError") {
      return;
    }

    if (response.ok && response.body) {
      this.setState({
        hasChanged: false,
        domain: null,
        idp_clientid: null,
        idp_entity: null,
        idp_sso_url: null,
        idp_slo_url: null,
        idp_x509_cert: null,
        ssoConfig: response.body
      })
    }

    this.setState({isLoading: false})
  }

  onDeleteActionClick() {
    this.setState({
      userMenuAnchor: null,
      showDeleteActionProgress: false,
      confirmDeleteActionOpen: true
    })
  }

  async DeleteActionCallback (userAnswer, toDelete) {
    if (userAnswer && userAnswer === 'ok' && toDelete) {
      this.setState({showActionProgress: true})

      const response = await SSOApi.DeleteSSOConfig(this.signal, toDelete)
      if (response.error && response.error.name === "AbortError") {
        return;
      }

      this.setState({
        hasChanged: false,
        domain: null,
        idp_clientid: null,
        idp_entity: null,
        idp_sso_url: null,
        idp_slo_url: null,
        idp_x509_cert: null,
        ssoConfig: {}
      })
    }

    this.setState({confirmDeleteActionOpen: false})
  };

  IsValidHttpUrl (string) {
    let url;

    try {
      url = new URL(string);
    } catch (e) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  setValidity = (event) => {
    const element = document.getElementById(event.target.id);
    if (!element.value) {
      element.setCustomValidity('Please fill in this field.');
    }
    else if (!this.IsValidHttpUrl(element.value)) {
      element.setCustomValidity('Must be a valid URL.');
    } else {
      element.setCustomValidity('');
    }
  }

  onDownloadClick = async () => {
    const response = await SSOApi.GetMetadata(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    if (response.ok && response.blob) {
      downloadBlob(new Blob([response.blob]), 'metadata.xml')
    }
  }

  render () {
    const { classes, session } = this.props
    const { isLoading, hasChanged, hasDataError, userMenuAnchor, ssoConfig, account } = this.state
    const { domain, idp_clientid, idp_entity, idp_sso_url, idp_slo_url, idp_x509_cert } = this.state
    const hasWritePermission = session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] || session.permissions[SERVICES.ACCOUNT][OPERATIONS.UPDATE]
    const submitButtonText = 'Submit Changes'
    const ssoConfigEmpty = (Object.keys(ssoConfig).length === 0)

    if (hasChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} color="inherit" aria-label="breadcrumb" className={classes.title}>
                <Link component="button" variant="h6" href="/" color="inherit" title='Back' onClick={this.props.history.goBack.bind(this)}>
                  SSO Configuration
                </Link>
                { account.name &&
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    {account.name}
                  </Typography>
                }
              </Breadcrumbs>

              { hasWritePermission &&
                <Fragment>
                  <Button
                    disabled={ssoConfig.id == null}
                    className={classes.actionsDropDown}
                    variant='outlined'
                    color="inherit"
                    endIcon={Boolean(userMenuAnchor) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    onClick={(event) => this.setState({userMenuAnchor: event.currentTarget})}
                  >
                    ACTIONS
                  </Button>
                  <Menu
                    id="actions-menu"
                    anchorEl={userMenuAnchor}
                    keepMounted
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(userMenuAnchor)}
                    onClose={() => this.setState({userMenuAnchor: null})}
                  >
                    { hasWritePermission &&
                      <MenuItem onClick={this.onDeleteActionClick.bind(this)}>
                        <ListItemIcon className={classes.actionsMenuIcon}>
                          <DeleteForeverIcon fontSize='small' />
                        </ListItemIcon>
                        Delete Configuration
                      </MenuItem>
                    }
                  </Menu>
                </Fragment>
              }

              <Tooltip title="Back">
                <span>
                  <IconButton id="go-back-icon" disabled={isLoading} onClick={this.props.history.goBack.bind(this)}>
                    <ArrowBackIcon />
                  </IconButton>
                </span>
              </Tooltip>

              { hasWritePermission &&
                <Tooltip title={submitButtonText}>
                  <span>
                    <IconButton id="submit-icon" disabled={isLoading || !hasChanged} onClick={() => document.getElementById('submit-button').click()}>
                      <PublishIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefresh.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Fragment>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                  <fieldset className={classes.fieldset} disabled={hasDataError || !hasWritePermission}>

                    <Grid container spacing={2}>
                      { ssoConfig.created_at &&
                        <Grid item xs={12}>
                          { ssoConfig.created_at &&
                            <Typography variant="body1" color="inherit">
                              Created on : {ssoConfig.created_at ? (new Date(ssoConfig.created_at)).toLocaleString('en-US') : ''}
                            </Typography>
                          }
                        </Grid>
                      }

                      <Grid container item xs={12} md={6} lg={6} spacing={1}>
                        <Paper className={classes.paper}>
                          <Toolbar>
                            <Typography variant="h5" color="inherit">
                              Identity Provider Settings
                            </Typography>
                          </Toolbar>

                          <Tooltip title="The account domain name, this should be the users email address domain to be used with this identity server.">
                            <Grid item xs={12}>
                              <TextField
                                margin="normal"
                                required
                                id="domain"
                                label="IDP Domain Name"
                                name="domain"
                                autoComplete="domain"
                                onChange={this.handleChange.bind(this)}
                                fullWidth
                                value = {domain != null ? domain : ssoConfig.domain ? ssoConfig.domain : ''}
                              />
                            </Grid>
                          </Tooltip>
                          <Tooltip title="The client ID for this service provider. This should be provided by the identity provider.">
                            <Grid item xs={12}>
                              <TextField
                                margin="normal"
                                required
                                id="idp_clientid"
                                label="SP Client ID"
                                name="idp_clientid"
                                autoComplete="idp_clientid"
                                onChange={this.handleChange.bind(this)}
                                fullWidth
                                value={idp_clientid != null ? idp_clientid : ssoConfig.idp_clientid ? ssoConfig.idp_clientid : ''}
                              />
                            </Grid>
                          </Tooltip>
                          <Tooltip title="The entity id of the identity provider, this is also the SAML issuer. This should be provided by the identity provider.">
                            <Grid item xs={12}>
                              <TextField
                                margin="normal"
                                required
                                id="idp_entity"
                                label="IDP Entity ID"
                                name="idp_entity"
                                autoComplete="idp_entity"
                                onChange={this.handleChange.bind(this)}
                                fullWidth
                                value = {idp_entity != null ? idp_entity : ssoConfig.idp_entity ? ssoConfig.idp_entity : ''}
                              />
                            </Grid>
                          </Tooltip>
                          <Tooltip title="The identity provider sign-on URL to use for authentication. This should be provided by the identity provider.">
                            <Grid item xs={12}>
                              <TextField
                                margin="normal"
                                required
                                id="idp_sso_url"
                                label="IDP Single Sign-On URL"
                                name="idp_sso_url"
                                autoComplete="idp_sso_url"
                                onChange={this.handleChange.bind(this)}
                                onInput={this.setValidity}
                                fullWidth
                                value = {idp_sso_url != null ? idp_sso_url : ssoConfig.idp_sso_url ? ssoConfig.idp_sso_url : ''}
                              />
                            </Grid>
                          </Tooltip>
                          <Tooltip title="The identity provider logout URL to use for logout. This should be provided by the identity provider.">
                            <Grid item xs={12}>
                              <TextField
                                margin="normal"
                                required
                                id="idp_slo_url"
                                label="IDP Single Logout URL"
                                name="idp_slo_url"
                                autoComplete="idp_slo_url"
                                onChange={this.handleChange.bind(this)}
                                onInput={this.setValidity}
                                fullWidth
                                value = {idp_slo_url != null ? idp_slo_url : ssoConfig.idp_slo_url ? ssoConfig.idp_slo_url : ''}
                              />
                            </Grid>
                          </Tooltip>
                          <Tooltip title="The certificate to use for this identity provider. This should be provided by the identity provider.">
                            <Grid item xs={12}>
                              <TextField
                                multiline
                                rows="12"
                                margin="normal"
                                required
                                id="idp_x509_cert"
                                label="IDP X.509 Certificate"
                                name="idp_x509_cert"
                                autoComplete="idp_x509_cert"
                                onChange={this.handleChange.bind(this)}
                                fullWidth
                                variant="outlined"
                                value = {idp_x509_cert != null ? idp_x509_cert : ssoConfig.idp_x509_cert ? ssoConfig.idp_x509_cert : ''}
                              />
                            </Grid>
                          </Tooltip>
                        </Paper>
                      </Grid>

                      <Grid container item xs={12} md={6} lg={6} spacing={1}>
                        <Paper className={classes.paper}>
                          <Toolbar>
                            <Typography variant="h5" color="inherit">
                              Service Provider Settings
                            </Typography>
                          </Toolbar>

                          { ssoConfigEmpty ? (
                            <Typography component="div">
                              <Box color="orange" fontStyle="italic"  display="flex" justifyContent="left" alignItems="center">
                                <InfoIcon className={classes.infoIcon}/>
                                Available after IdP settings are submitted.
                              </Box>
                            </Typography>
                          ) : (
                            <Fragment>
                              <Grid item xs={12}>
                                <Button
                                  disabled={ssoConfigEmpty}
                                  variant="text"
                                  color="primary"
                                  onClick={this.onDownloadClick}
                                  startIcon={<SaveAltIcon />}
                                  style={{textTransform: 'none'}}
                                >
                                  Download SP Metadata Exchange XML
                                </Button>
                              </Grid>

                              <Tooltip title="The redirect binding URL for assertions. This needs to be shared with identity provider.">
                                <Grid item xs={12}>
                                  <TextField
                                    margin="normal"
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start" title="Copy to Clipboard">
                                          <IconButton
                                            id="copy-assertion-url-to-clipboard-icon"
                                            disabled={!ssoConfig.sp_assertion_binding_url}
                                            size="small"
                                            onClick={async () => await copy(ssoConfig.sp_assertion_binding_url)}
                                            onMouseDown={(event) => {event.preventDefault()}}
                                          >
                                            <FileCopyIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    id="sp_assertion_binding_url"
                                    label="SP Assertion Binding Url"
                                    name="sp_assertion_binding_url"
                                    autoComplete="sp_assertion_binding_url"
                                    onChange={this.handleChange.bind(this)}
                                    fullWidth
                                    value={ssoConfig.sp_assertion_binding_url ? ssoConfig.sp_assertion_binding_url : ""}
                                  />
                                </Grid>
                              </Tooltip>
                              <Tooltip title="The redirect binding URL for single logout service. This needs to be shared with identity provider.">
                                <Grid item xs={12}>
                                  <TextField
                                    margin="normal"
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start" title="Copy to Clipboard">
                                          <IconButton
                                            id="copy-logout-url-to-clipboard-icon"
                                            disabled={!ssoConfig.sp_sls_url}
                                            size="small"
                                            onClick={async () => await copy(ssoConfig.sp_sls_url)}
                                            onMouseDown={(event) => {event.preventDefault()}}
                                          >
                                            <FileCopyIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    id="sp_sls_url"
                                    label="SP Single Logout Service Url"
                                    name="sp_sls_url"
                                    autoComplete="sp_sls_url"
                                    onChange={this.handleChange.bind(this)}
                                    fullWidth
                                    value={ssoConfig.sp_sls_url ? ssoConfig.sp_sls_url : ""}
                                  />
                                </Grid>
                              </Tooltip>
                            </Fragment>
                          )}
                        </Paper>
                      </Grid>
                    </Grid>

                    { hasWritePermission &&
                      <div className={classes.form}>
                        <Button
                          id="submit-button"
                          startIcon={<PublishIcon/>}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={!hasChanged}
                        >
                          {submitButtonText}
                        </Button>
                      </div>
                    }

                  </fieldset>
                </form>
              </Fragment>
            )}
          </PagePaper>
        </Container>

        <ConfirmAction
          title="Delete SSO Configuration ?"
          open={this.state.confirmDeleteActionOpen}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to delete account..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={ssoConfig.id}
        >
          Are you sure you want to permanently delete SSO configuration?<br/>
          This operation is IRREVERSIBLE!
        </ConfirmAction>

        <ConfirmGoBack open={hasChanged} />
      </div>
    )
  }
}

export default withStyles(useStyles)(SSODetails)
