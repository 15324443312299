import appConfig from '../../config/AppConfig.js'
import BaseApi from './Common'
import crypto from 'crypto-browserify'

const baseUrl = appConfig.apiUri + '/api/videoxchange/shares'

async function ShareCreate (signal, caseId, filename, filesize) {
  const body = {
    case_id: caseId,
    file_name: filename,
    size: filesize
  }

  return await BaseApi.DoFetch(signal, baseUrl, 'POST', body)
}

async function ShareUpload (url, caseId, file, encryptionKey, transferStart, updateProgress, transferComplete, transferFailed, transferCanceled) {
  const xhrObj = new XMLHttpRequest()

  if (transferStart != null) {
    xhrObj.upload.addEventListener('loadstart', function (oEvent) { transferStart(oEvent, file) }, false)
  }

  if (updateProgress != null) {
    xhrObj.upload.addEventListener('progress', function (oEvent) { updateProgress(oEvent, file) }, false)
  }

  xhrObj.addEventListener('error', function (oEvent) {
    if (transferFailed != null) {
      transferFailed(oEvent, caseId, file, xhrObj.status)
    }
  })

  xhrObj.addEventListener('loadend', function (oEvent) {
    if (xhrObj.status === 200) {
      if (transferComplete != null) {
        transferComplete(oEvent, caseId, file, encryptionKey)
      }
    } else if (xhrObj.status === 0) {
      if (transferCanceled != null) {
        transferCanceled(oEvent, caseId, file)
      }
    } else {
      if (transferFailed != null) {
        transferFailed(oEvent, caseId, file, xhrObj.status)
      }
    }
  })

  xhrObj.open('PUT', url, true)
  xhrObj.setRequestHeader('Content-type', 'application/octet-stream')

  if (encryptionKey) {
    const encryptionKeyArray = new Buffer(encryptionKey.padEnd(32, '\0'), 'ascii')
    xhrObj.setRequestHeader('x-amz-server-side-encryption-customer-algorithm', 'AES256')
    xhrObj.setRequestHeader('x-amz-server-side-encryption-customer-key', btoa(encryptionKeyArray))
    xhrObj.setRequestHeader('x-amz-server-side-encryption-customer-key-MD5', crypto.createHash('md5').update(encryptionKeyArray).digest('base64'))
  }

  try {
    xhrObj.send(file)
  } catch (error) {
    console.error(error)
    BaseApi.dispatchError(error)
    return { error }
  }

  return { ok: true }
}

async function ShareCreateAndUpload (signal, caseId, file, encryptionKey, transferStart, updateProgress, transferComplete, transferFailed, transferCanceled) {
  let response = await ShareCreate(signal, caseId, file.name, file.size)
  if (response.ok) {
    const upload_url = response.body.upload_url
    response = await ShareUpload(upload_url, caseId, file, encryptionKey, transferStart, updateProgress, transferComplete, transferFailed, transferCanceled)
  }

  return response
}

async function DeleteShare (signal, toDelete) {
  const url = baseUrl + '/' + toDelete

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function GetDownloadLink (signal, share, associated, encryptionKey) {
  let url = baseUrl + '/' + share + '/download'

  if (associated) {
    url += ('?associated=' + associated)
  }

  let response = await BaseApi.DoFetch(signal, url, 'GET')
  if (response.ok) {
    try {
      if (!(response.body && response.body.download_url)) {
        throw new Error('No download url found!')
      }

      // For encrypted files, since we cannot add custom headers to the http request that is made
      // buy the browser when window.location.href is used to download the file we have
      // to perform file download manually as follow.
      if (encryptionKey) {
        const encryptionKeyArray = new Buffer(encryptionKey.padEnd(32, '\0'), 'ascii')

        const options = {
          method: 'GET',
          headers: {
            'x-amz-server-side-encryption-customer-algorithm': 'AES256',
            'x-amz-server-side-encryption-customer-key': btoa(encryptionKeyArray),
            'x-amz-server-side-encryption-customer-key-MD5': crypto.createHash('md5').update(encryptionKeyArray).digest('base64')
          }
        }

        response = await fetch(response.body.download_url, options)

        if (response.ok) {
          const blob = await response.blob()
          response = { ok: true, blob }
        } else if (response.statusText) {
          throw new Error('Got error [' + response.statusText + '] from server!')
        } else {
          throw new Error('Something went wrong ...')
        }
      }
    } catch (error) {
      console.error(error)
      BaseApi.dispatchError(error)
      return { error }
    }
  }

  return response
}

async function FetchShares (signal, caseId) {
  const url = baseUrl + '/case/' + caseId

  return await BaseApi.DoFetch(signal, url, 'GET')
}

function GetStreamLink (shareId, associated) {
  let url = window.location.origin + baseUrl + '/' + shareId + '/stream'

  if (associated) {
    url += ('?associated=' + associated)
  }

  return url
}

const exportsShare = {
  ShareCreateAndUpload,
  DeleteShare,
  GetDownloadLink,
  FetchShares,
  GetStreamLink
}
export default exportsShare
