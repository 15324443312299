
import { SET_ERROR } from '../../constants/actionTypes'
import { BuildErrorMsg } from '../Utils'
import store from '../../store'

function dispatchError(error) {
  // Do not dispatch abort errors!
  if (error.name !== "AbortError") {
    store.dispatch({
      type: SET_ERROR,
      errorMessage: BuildErrorMsg(error)
    })
  }
}

async function DoFetch(signal, url, method, body, flags) {
  try {
    if (!url || !method)
    {
      throw new Error('DoFetch() invalid params!')
    }

    const options = {
      'method' : method,
      'headers' : flags && flags.headers ? flags.headers : { },
      signal
    }

    if (!(flags && flags.noSession)) {
      const state = store.getState()
      const { session } = state

      if (!session || !session.isLoggedIn) {
        throw new Error("Invalid session")
      }

      if (flags && flags.useRefreshToken){
        if (session.refreshToken && session.refreshToken.encoded) {
          options.headers['Authorization'] = 'Bearer ' + session.refreshToken.encoded
        }
      } else {
        if (session.accessToken && session.accessToken.encoded) {
          options.headers['Authorization'] = 'Bearer ' + session.accessToken.encoded
        }
      }
    }

    if (body) {
      options.headers['Content-Type'] = 'application/json'
      options['body'] = JSON.stringify(body)
    }

    const response = await fetch(url, options)
    const contentType = response.headers ? response.headers.get("content-type") : ""
    const contentDisposition = response.headers ? response.headers.get("content-disposition") : ""

    if (response.ok) {
      let filename = null

      if (contentDisposition && contentDisposition.includes("attachment;") && contentDisposition.includes("filename=")) {
        const contentDispositionTokens = contentDisposition.split("filename=")
        if (contentDispositionTokens.length > 1) {
          filename = contentDispositionTokens[1]
        }
      }

      if (contentType && contentType.includes("application/json")) {
        const body = await response.json()
        return {ok: true, body: body}
      }

      if (contentType && contentType.includes("text/xml")) {
        const blob = await response.blob()
        return {ok: true, blob: blob}
      }

      if (contentType && contentType.includes("text/csv")) {
        if (filename == null) {
          throw Error('Attachement filename not provided in response header!')
        }
        const text = await response.text()
        return {ok: true, filename: filename, text: text}
      }

      return {ok: true}
    } else {
      if (contentType && contentType.includes("application/json")) {
        const body = await response.json()
        if (body.message) {
          throw new Error(body.message)
        } else if (body.msg) {
          throw new Error(body.msg)
        } else {
          throw new Error('Something went wrong ...')
        }
      }
      else if (response.statusText) {
        throw new Error('Got error [' + response.statusText + '] from server!')
      } else {
        throw new Error('Something went wrong ...')
      }
    }
  } catch (error) {
    console.error(error)

    if (!(flags && flags.noErrorDispatch)) {
      dispatchError(error)
    }

    return {error: error}
  }
}

const exportsCommon = {
  DoFetch,
  dispatchError,
};
export default exportsCommon;