import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Users from './Users'
import UserDetails from './UserDetails'
import UserResetPassword from './UserResetPassword'
import { NotFound } from '../../../errors'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

class UserRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>

        { (session.permissions[SERVICES.USER][OPERATIONS.READ] ||
           session.permissions[SERVICES.USER][OPERATIONS.UPDATE]) &&
          <Route
            path='/mainmenu/admin/users/details/:user'
            render={(props) => <UserDetails {...props} session={session}/>}
          />
        }

        { (session.permissions[SERVICES.USER][OPERATIONS.CREATE] ||
           session.permissions[SERVICES.USER][OPERATIONS.UPDATE]) &&
          <Route
            path='/mainmenu/admin/users/resetpassword/:user'
            render={(props) => <UserResetPassword {...props} session={session} />}
          />
        }

        { session.permissions[SERVICES.USER][OPERATIONS.CREATE] &&
          <Route
            path='/mainmenu/admin/users/new'
            render={(props) => <UserDetails {...props} session={session} newUser/>}
          />
        }

        { session.permissions[SERVICES.USER][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/users'
            render={(props) => <Users {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default UserRouter
