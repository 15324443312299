import ROWS_PER_PAGE_OPTIONS from './navParams'

export const ONE_YEAR_MS = (365*24*60*60*1000)

export const COOKIES = {
  APP_PALETTE:   { name: 'appPaletteType', default: 'dark' },
  USE_LIST_VIEW: { name: 'useListView',    default: 'false' },
  ROWS_PER_PAGE: { name: 'rowsPerPage',    default: ROWS_PER_PAGE_OPTIONS[0].toString(10) },
}

export function GetDefaultCookiesOptions() {
  return {
    path: '/',
    sameSite: 'lax',
    expires: new Date(Date.now() + ONE_YEAR_MS) }
}