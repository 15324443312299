import React, { Fragment } from 'react';
import { withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import { GetMenuDef } from '../../layouts/MenuItems';
import { GetLicenseSetting, GetFileSize } from '../../../libs/Utils'
import BarChart from '../../custom/BarChart'
import Title from '../../custom/Title'
import ElevatedPaper from '../../custom/ElevatedPaper'
import PagePaper from '../../custom/PagePaper'

function ActiveCaseChart (props) {
  const { cases_open_per_investigator } = props

  let barChartData = {
    datasets: [],
  }

  cases_open_per_investigator.forEach( (item) => {
    barChartData.datasets.push({
      label: item.creator,
      data: item.count,
    })
  })

  return (
    <BarChart
      {...props}
      chartData={barChartData}
      barDirection="horizontal"
      barColor="#4287f5"
      barLabel="Active Case Count"
      chartTitle="Investigator Active Cases"
    />
  )
}

function CasesOpenPerDayChart (props) {
  const { cases_open_per_day } = props

  let barChartData = {
    datasets: [],
  }

  cases_open_per_day.forEach( (item) => {
    barChartData.datasets.push({
      label: item.created_at,
      data: item.count,
    })
  })

  return (
    <BarChart
      {...props}
      chartData={barChartData}
      barDirection="vertical"
      barColor="#05ce7c"
      barLabel="Case Count"
      chartTitle="Open Cases By Day"
    />
  )
}

function CasesExpiringSoonChart (props) {
  const { cases_expiring_soon } = props

  let barChartData = {
    datasets: [],
  }

  cases_expiring_soon.forEach( (item) => {
    barChartData.datasets.push({
      label: item.expires_at,
      data: item.count,
    })
  })

  return (
    <BarChart
      {...props}
      chartData={barChartData}
      barDirection="vertical"
      barColor="#ff0036"
      barLabel="Expiring Cases"
      chartTitle="Expiring Cases By Day"
    />
  )
}


function ExchangeStats (props) {
  const { caseStats, licenseResp, classes } = props
  const menuDef = GetMenuDef('/exchange')

  if (!caseStats || !menuDef || !licenseResp) {
    return null
  }

  let fileSizePctUsed = 0;
  let fileSizeMaxAllowed = 0;
  let openCasesPctUsed = 0
  const maxFileSizeLicenceItem = GetLicenseSetting(licenseResp, 'VE_MAX_FILE_SIZE');
  const maxFilePerCase = GetLicenseSetting(licenseResp, 'VE_MAX_FILE_PER_CASE');
  const maxOpenCasesLicenceItem = GetLicenseSetting(licenseResp, 'VE_MAX_OPEN_CASES');
  if (maxFileSizeLicenceItem) {
    fileSizeMaxAllowed = maxFileSizeLicenceItem.value_max * maxOpenCasesLicenceItem.value_max * maxFilePerCase.value_max;
    fileSizePctUsed = (caseStats.total_size * 100) / fileSizeMaxAllowed;
  }
  if (maxOpenCasesLicenceItem) {
    openCasesPctUsed = (maxOpenCasesLicenceItem.value_current * 100) / maxOpenCasesLicenceItem.value_max;
  }

  return (
    <Fragment>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Title variant="h5">
            {menuDef.name}
          </Title>
        </Grid>

        <Grid item xs={12}>
          <ElevatedPaper>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="inherit">
                  License Limits
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PagePaper>
                  <LinearProgress variant="determinate" value={fileSizePctUsed} />
                  <Typography variant="body1" color="inherit">
                    Total File Size: {GetFileSize(caseStats.total_size)} (max: {GetFileSize(fileSizeMaxAllowed)})
                  </Typography>
                </PagePaper>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PagePaper>
                  <LinearProgress variant="determinate" value={openCasesPctUsed} />
                  <Typography variant="body1" color="inherit">
                    Open Cases: {maxOpenCasesLicenceItem.value_current} (max: {maxOpenCasesLicenceItem.value_max})
                  </Typography>
                </PagePaper>
              </Grid>
            </Grid>
          </ElevatedPaper>
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={12} md={6} lg={4}>
          <ElevatedPaper>
            <CasesOpenPerDayChart
              {...props}
              cases_open_per_day={caseStats.cases_open_per_day}
            />
          </ElevatedPaper>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <ElevatedPaper>
            <CasesExpiringSoonChart
              {...props}
              cases_expiring_soon={caseStats.cases_expiring_soon}
            />
          </ElevatedPaper>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <ElevatedPaper>
            <ActiveCaseChart
              {...props}
              cases_open_per_investigator={caseStats.cases_open_per_investigator}
            />
          </ElevatedPaper>
        </Grid>

        <Grid item xs={12} />
      </Grid>
    </Fragment>
  )
}

export default withTheme(ExchangeStats)
