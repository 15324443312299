import React from 'react';
import StepperDialog from "./StepperDialog"
import UserGroupsTab from '../../views/Admin/Users/UserGroupsTab'

function RolesDialog(props) {
  return (
    <StepperDialog
       {...props}
       StepContent={UserGroupsTab}
    />
  );
}

export default RolesDialog
