import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import DetailsIcon from '@material-ui/icons/Details';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import { ConfirmAction } from '../../../dialogs'
import GroupApi from '../../../../libs/EdgeVMSCloudApi/Groups'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

const useStyles = theme => ({
  actionsDropDown: {
    marginRight: theme.spacing(6),
  },
  actionsMenuIcon: {
    minWidth: theme.spacing(4),
  },
});

function DetailsAction(props) {
  const { groupId, session, disabled, dense, dropDownVariant } = props
  const hasReadPermission = session.permissions[SERVICES.USER][OPERATIONS.READ]
  const hasUpdatePermission = session.permissions[SERVICES.USER][OPERATIONS.UPDATE]

  if (dropDownVariant || !(hasReadPermission || hasUpdatePermission)) {
    return null
  }

  return (
    <Fragment>
      <Tooltip title={hasUpdatePermission ? "Edit" : "Details"}>
        <IconButton
          disabled={disabled}
          size={dense ? 'small' : 'medium'}
          onClick={() => props.history.push('/mainmenu/admin/groups/details/' + groupId, { originLocation: window.location.toString() })}
        >
          { hasUpdatePermission ?
            <EditIcon fontSize={dense ? 'small' : 'default'}/> :
            <DetailsIcon fontSize={dense ? 'small' : 'default'}/>
          }
        </IconButton>
      </Tooltip>
    </Fragment>
  )
}


class DeleteCase extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showActionProgress: false,
      confirmDeleteActionOpen: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  onDeleteActionClick() {
    if (this.props.closeMenu){
      this.props.closeMenu()
    }

    this.setState({showActionProgress: false})
    this.setState({confirmDeleteActionOpen: true})
  }

  async DeleteActionCallback(userAnswer, toDelete) {

    if (userAnswer && userAnswer === 'ok' && toDelete) {
      this.setState({showActionProgress: true})
      const response = await GroupApi.DeleteGroup(this.signal, toDelete)
      this.setState({confirmDeleteActionOpen: false})

      if (response.error && response.error.name === "AbortError") {
        return;
      }

      // Call this last because it will probably result in the component being unmounted.
      if (this.props.onConfirmedDelete) {
        this.props.onConfirmedDelete();
      }
    } else {
      this.setState({confirmDeleteActionOpen: false})
    }
  }

  render() {
    const { classes, groupId, session, dense, dropDownVariant } = this.props

    if (!session.permissions[SERVICES.USER][OPERATIONS.DELETE])
    {
      return null
    }

    return (
      <Fragment>
        { dropDownVariant ? (
          <MenuItem onClick={this.onDeleteActionClick.bind(this)}>
            <ListItemIcon className={classes.actionsMenuIcon}>
              <DeleteForeverIcon fontSize='small' />
            </ListItemIcon>
            Delete Forever
          </MenuItem>
        ) : (
          <Tooltip title="Delete Forever">
            <IconButton
              size={dense ? 'small' : 'medium'}
              onClick={this.onDeleteActionClick.bind(this)}
            >
              <DeleteForeverIcon fontSize={dense ? 'small' : 'default'} />
            </IconButton>
          </Tooltip>
        )}

        <ConfirmAction
          title="Delete Group ?"
          open={this.state.confirmDeleteActionOpen}
          showProgress={this.state.showActionProgress}
          progressMsg="Processing request to delete group..."
          actionCallback={this.DeleteActionCallback.bind(this)}
          callbackContext={groupId}
        >
          Are you sure you want to permanently delete this group?<br/>
          This operation is IRREVERSIBLE!
        </ConfirmAction>
      </Fragment>
    )
  }
}

class GroupActions extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userMenuAnchor: null,
    }
  }

  closeMenu = () => {
    this.setState({userMenuAnchor: null})
  }

  render () {
    const { classes, session, dropDownVariant } = this.props
    const { userMenuAnchor } = this.state

    if (((!session.permissions[SERVICES.USER][OPERATIONS.READ] && !session.permissions[SERVICES.USER][OPERATIONS.UPDATE]) || dropDownVariant) &&
         !session.permissions[SERVICES.USER][OPERATIONS.DELETE]) {
      if (dropDownVariant) {
        return null
      } else {
        return "None"
      }
    }

    if (dropDownVariant) {
      return (
        <Fragment>
          <Button
            className={classes.actionsDropDown}
            variant='outlined'
            color="inherit"
            endIcon={Boolean(userMenuAnchor) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            onClick={(event) => this.setState({userMenuAnchor: event.currentTarget})}
          >
            ACTIONS
          </Button>
          <Menu
            id="actions-menu"
            anchorEl={userMenuAnchor}
            keepMounted
            TransitionComponent={Fade}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(userMenuAnchor)}
            onClose={this.closeMenu}
          >
            <DeleteCase {...this.props} closeMenu={this.closeMenu}/>
          </Menu>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <DetailsAction {...this.props} />
          <DeleteCase {...this.props} />
        </Fragment>
      )
    }
  }
}

export default withRouter(withStyles(useStyles)(GroupActions))
