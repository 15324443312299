import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PublishIcon from '@material-ui/icons/Publish';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import MaintenanceApi from '../../../../libs/EdgeVMSCloudApi/Maintenance'
import Title from '../../../custom/Title'
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import { ConfirmGoBack } from '../../../dialogs'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'


const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: 0,
    padding: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    borderColor: theme.palette.secondary.main,
    borderWidth: "1px",
    borderStyle: "solid",
  },
});

class MaintenanceNotification extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      hasChanged: false,
      hasDataError: false,
      response: {},
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  async handleRefresh () {
    this.setState({isLoading: true})

    const response = await MaintenanceApi.FetchMaintenanceNotification(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({response: response.ok ? response.body : {}})
    this.setState({hasDataError: response.error ? true : false}, () => this.setState({isLoading: false}))
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
      hasChanged: true
    });
  }

  handleDateChange =  (id, date, value) => {
    const isValidDate  = date instanceof Date && !isNaN(date.valueOf())
    const current = document.getElementById(id);

    if (isValidDate) {
      current.setCustomValidity('');
      const other = document.getElementById(id === 'start_at' ? 'end_at' : 'start_at')
      const start_at = Date.parse(id === 'start_at' ? value : other.value)
      const end_at   = Date.parse(id === 'end_at' ? value : other.value)

      if (!isNaN(start_at) && !isNaN(end_at)) {
        if (end_at < start_at) {
          current.setCustomValidity(id === 'start_at' ? 'Start date must be earlier than end date!' : 'End date must be later than start date!');
        } else {
          other.setCustomValidity('')
        }
      }
    } else {
      current.setCustomValidity('Please provide a valid date');
    }

    this.setState({
      [id]: date,
      hasChanged: true
    });
  };

  async handleSubmit(event) {
    event.preventDefault()
    let values = {}

    if (this.state.message != null) {
      values.message = this.state.message
    }

    if (this.state.enabled != null) {
      values.enabled = this.state.enabled
    }

    if (this.state.start_at != null) {
      const isValidDate = this.state.start_at instanceof Date && !isNaN(this.state.start_at.valueOf())
      if (isValidDate) {
        values.start_at = this.state.start_at.toUTCString()
      }
    }

    if (this.state.end_at != null) {
      const isValidDate = this.state.end_at instanceof Date && !isNaN(this.state.end_at.valueOf())
      if (isValidDate) {
        values.end_at = this.state.end_at.toUTCString()
      }
    }

    this.setState({isLoading: true})

    const response = await MaintenanceApi.UpdateMaintenanceNotification(this.signal, values)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    if (response.ok) {
      this.setState({
        hasChanged: false,
        message: null,
        enabled: null,
        start_at: null,
        end_at: null,
        response: response.ok ? response.body : {}
      })
    }

    this.setState({isLoading: false})
  }

  render () {
    const { classes, session } = this.props
    const { isLoading, hasChanged, hasDataError, response } = this.state
    const { message, enabled, start_at, end_at } = this.state
    const hasWritePermission = session.permissions[SERVICES.MAINTENANCE][OPERATIONS.UPDATE]
    const formDisable = hasDataError || !hasWritePermission

    if (hasChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar>
            <Title component="h1" variant="h5">
              Maintenance Notification
            </Title>

            <Tooltip title="Refresh">
              <span>
                <IconButton id="refresh-icon"onClick={this.handleRefresh.bind(this)}>
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>

          { isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Fragment>
              <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                <fieldset className={classes.fieldset} disabled={formDisable}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="enabled"
                            checked={enabled != null ? enabled : response.enabled === true}
                            onChange={this.handleChange.bind(this)}
                          />
                        }
                        label="Enable Maintenance Notification"
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <KeyboardDateTimePicker
                        readOnly={formDisable}
                        autoOk
                        margin="normal"
                        variant="inline"
                        name="start_at"
                        label="Start Date"
                        id="start_at"
                        fullWidth
                        value={start_at != null ? start_at : response.start_at ? new Date(response.start_at) : null}
                        onChange={(date, value) => this.handleDateChange("start_at", date, value)}
                        format="MM/dd/yyyy, hh:mm a"
                        mask="__/__/____, __:__ _M"
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                    <KeyboardDateTimePicker
                        readOnly={formDisable}
                        autoOk
                        margin="normal"
                        variant="inline"
                        name="end_at"
                        label="End Date"
                        id="end_at"
                        fullWidth
                        value={end_at != null ? end_at : response.end_at ? new Date(response.end_at) : null}
                        onChange={(date, value) => this.handleDateChange("end_at", date, value)}
                        format="MM/dd/yyyy, hh:mm a"
                        mask="__/__/____, __:__ _M"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        variant="outlined"
                        rows="2"
                        margin="normal"
                        id="message"
                        label="Additional Message"
                        name="message"
                        onChange={this.handleChange.bind(this)}
                        fullWidth
                        value = {message != null ? message : response.message ? response.message : ''}
                      />
                    </Grid>
                  </Grid>
                  { hasWritePermission &&
                    <div className={classes.form}>
                      <Button
                        id="submit-button"
                        startIcon={<PublishIcon/>}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!hasChanged}
                      >
                        {'Submit Changes'}
                      </Button>
                    </div>
                  }
                </fieldset>
              </form>
            </Fragment>
          )}
        </Paper>

        <ConfirmGoBack open={hasChanged} />
      </div>
    )
  }
}

export default withStyles(useStyles)(MaintenanceNotification)
