import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (theme) => ({
   text: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
   },
   progress: {
      margin: theme.spacing(2),
   },
});

function CustomCircularProgress(props) {
   const { classes, ...other } = props;

   return (
      <div className={classes.text}>
         <CircularProgress className={classes.progress} color="inherit" {...other} />
      </div>
   );
}

export default withStyles(useStyles)(CustomCircularProgress)