import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Roles from './Roles'
import RoleDetails from './RoleDetails'
import { NotFound } from '../../../errors'
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'

class RoleRouter extends Component {

  render () {
    const {session} = this.props

    return (
      <Switch>

        { (session.permissions[SERVICES.USER][OPERATIONS.READ] ||
           session.permissions[SERVICES.USER][OPERATIONS.UPDATE]) &&
          <Route
            path='/mainmenu/admin/roles/details/:role'
            render={(props) => <RoleDetails {...props} session={session}/>}
          />
        }

        { session.permissions[SERVICES.USER][OPERATIONS.CREATE] &&
          <Route
            path='/mainmenu/admin/roles/new'
            render={(props) => <RoleDetails {...props} session={session} newRole/>}
          />
        }

        { session.permissions[SERVICES.USER][OPERATIONS.READ] &&
          <Route
            exact path='/mainmenu/admin/roles'
            render={(props) => <Roles {...props} session={session}/>}
          />
        }

        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default RoleRouter
