import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { DialogTitle, DialogContent, DialogActions } from '../custom/CustomDialogs'
import NotificationApi from '../../libs/EdgeVMSCloudApi/Notification'
import { setNotificationCount } from '../../actions/notifications'

class NotificationDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      notifications: [],
      messageIndex: 0
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefresh()
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  async handleRefresh () {
    const response = await NotificationApi.FetchNotification(this.signal)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    const notifications = response.ok ? response.body : []

    this.props.setNotificationCount(notifications.length)
    this.setState({messageIndex: 0}, () => {
      this.setState({ notifications })
    })
  };

  handleClose = (event) => {
    if (this.props.handleClose) {
      this.props.handleClose()
    }
  };

  render() {
    const { open, showEmpty } = this.props
    const { notifications, messageIndex } = this.state

    if ((!showEmpty && notifications.length === 0) ||
        (notifications.length > 0 && messageIndex >= notifications.length)) {
      return null
    }

    return (
      <div>
        <Dialog
          open={open}
          maxWidth='sm'
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          onClose={this.handleClose}
          onEnter={() => this.setState({messageIndex: 0})}
        >
          <DialogTitle
            onNext={notifications.length > 1 ? () => this.setState({messageIndex: (messageIndex + 1) % notifications.length}) : null}
            onPrevious={notifications.length > 1 ? () => this.setState({messageIndex: ((messageIndex - 1) + notifications.length) % notifications.length}) : null}
          >
            { `Notifications ${notifications.length > 0 ? messageIndex+1 +'/' + notifications.length : ''}` }
          </DialogTitle>
          <DialogContent dividers>
            <Typography style={{whiteSpace: 'pre-line'}}>
              { notifications.length > 0 ? notifications[messageIndex].message : "No notifications."}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClose}
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setNotificationCount: notificationCount => dispatch(setNotificationCount(notificationCount)),
  }
}

export default connect(null, mapDispatchToProps) (NotificationDialog)

