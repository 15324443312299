import React, { Component, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { setSession } from '../../../actions/session'
import { setPasswordExpired } from '../../../actions/passwordExpired'
import AuthApi from '../../../libs/EdgeVMSCloudApi/Auth'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { COOKIES, GetDefaultCookiesOptions } from '../../../constants/cookieDefs'
import { InfoDialog } from '../../dialogs'


const useStyles = theme => ({
  container: {
    marginTop: '15vh',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    minWidth: '100px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
});


class PreAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.defaultUsername,
      isLoading: false,
      error: "",
      doSlide: false,
    };
  }

  componentDidMount () {
    this.setState({doSlide : true})
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onCancel() {
    this.props.history.push("/")
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({isLoading: true})
    const response = await AuthApi.PreAuth(this.state.username)
    this.setState({isLoading: false})

    if (response.ok) {
      if (response.redirect_url) {
        window.location.href = response.redirect_url
      } else {
        this.props.SetUsername(this.state.username)
      }
    } else if (response.error && response.error.message) {
      this.setState({error: response.error.message})
    } else {
      this.setState({error: 'Something went wrong.'})
    }
  }

  render () {
    const { classes } = this.props
    const { isLoading, error } = this.state

    return (
      <Container maxWidth="xs" className={classes.container}>
        <Slide direction="left" in={this.state.doSlide} mountOnEnter unmountOnExit>
          <Paper className={classes.paper}>
            <div className={classes.wrapper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              {isLoading && <CircularProgress size={68} className={classes.fabProgress} color="primary"/>}
            </div>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            { (error && (error.length > 0)) &&
              <Box border={2} color="error.main" borderColor="error.main" fontFamily="h6.fontFamily" p={1} mt={1} mb={1} width={1}>
                {error}
              </Box>
            }
            <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={this.state.username}
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={this.handleChange.bind(this)}
                disabled={isLoading}
              />

              <Link href="http://www.verint.com" variant="body2" disabled={isLoading}>
                  {"Don't have an account? Look here."}
              </Link>

              <Grid container alignItems='flex-start' justify='space-between'>
                <Grid item>
                  <Button
                    id="cancel-button"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    disabled={isLoading}
                    onClick={this.onCancel.bind(this)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="signinNext"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isLoading}
                    endIcon={<ArrowForwardIcon/>}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Slide>
      </Container>
    );
  }
}

class CompleteSignin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      isLoading: false,
      error: "",
      message: null,
      openMessageDialog: false,
      session: null,
      doSlide: false,
    };
  }

  componentDidMount () {
    this.setState({doSlide : true})
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onCancel() {
    this.props.history.push("/")
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({isLoading: true})
    const response = await AuthApi.SignIn(this.props.username, this.state.password)
    this.setState({isLoading: false})

    if (response.ok) {
      const { cookies } = this.props

      Object.values(COOKIES).forEach(cookie => {
        const currentValue = cookies.get(cookie.name)
        cookies.set(cookie.name, currentValue ? currentValue : cookie.default, GetDefaultCookiesOptions())
      })

      this.props.setPasswordExpired(response.passwordExpired === true)

      if (response.message) {
        this.setState({
          message: response.message,
          session: response.session
        }, this.setState({openMessageDialog: true}))
      } else {
        this.props.setSession(response.session)
      }
    } else if (response.error && response.error.message) {
      this.setState({error: response.error.message})
    } else {
      this.setState({error: 'Something went wrong.'})
    }
  }

  onMessageConfirmation () {
    this.props.setSession(this.state.session)

    this.setState({
      openMessageDialog: false
    })
  }

  render () {
    const { classes } = this.props
    const { isLoading, error, message, openMessageDialog } = this.state

    return (
      <Container maxWidth="xs" className={classes.container}>
        <Slide direction="left" in={this.state.doSlide} mountOnEnter unmountOnExit>
          <Paper className={classes.paper}>
            <div className={classes.wrapper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              {isLoading && <CircularProgress size={68} className={classes.fabProgress} color="primary"/>}
            </div>
            <Typography component="h1" variant="h5">
              Enter password
            </Typography>
            { (error && (error.length > 0)) &&
              <Box id="signinError" border={2} color="error.main" borderColor="error.main" fontFamily="h6.fontFamily" p={1} mt={1} mb={1} width={1}>
                {error}
              </Box>
            }

            <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
              <FormControlLabel
                control={
                  <Tooltip title="Back to username entry">
                    <IconButton id="go-back-icon" onClick={this.props.BackToUsername}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                }
                label={this.props.username}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                onChange={this.handleChange.bind(this)}
                disabled={isLoading}
              />

              <Link href="/forgotpassword" variant="body2" disabled={isLoading}>
                {"Forgot password?"}
              </Link>

              <Grid container alignItems='flex-start' justify='space-between' spacing={1}>
                <Grid item>
                  <Button
                    id="signinCancel"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    disabled={isLoading}
                    onClick={this.onCancel.bind(this)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="signinSubmit"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isLoading}
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Slide>
        <InfoDialog
          title="Warning:"
          open={openMessageDialog}
          actionCallback={this.onMessageConfirmation.bind(this)}
        >
          {message}
        </InfoDialog>
      </Container>
    );
  }
}

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      loginStep: "username",
    };
  }

  render () {
    const { username, loginStep } = this.state

    if (this.props.session.isLoggedIn)
    {
      return <Redirect to="/mainmenu" />
    }

    return (
      <Fragment>
        { loginStep === "username" || username.length === 0 ? (
          <PreAuth
            {...this.props}
            defaultUsername = {username}
            SetUsername = {username => this.setState({username, loginStep: "password"})}
          />
        ) : (
          <CompleteSignin
            {...this.props}
            username = {username}
            BackToUsername = {() => this.setState({loginStep: "username"})}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps (state) {
  return { session: state.session }
}

function mapDispatchToProps (dispatch) {
  return {
    setSession: session => dispatch(setSession(session)),
    setPasswordExpired: passwordExpired => dispatch(setPasswordExpired(passwordExpired))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(withStyles(useStyles)(SignIn))))
