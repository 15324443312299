import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import AccountsApi from '../../../../libs/EdgeVMSCloudApi/Accounts'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import SearchBox from '../../../custom/SearchBox'
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { NavTableFooter } from '../../../table'
import Add from '@material-ui/icons/Add';
import { SERVICES, OPERATIONS } from '../../../../constants/permissions'
import ROWS_PER_PAGE_OPTIONS from '../../../../constants/navParams'
import { COOKIES } from '../../../../constants/cookieDefs'
import AccountActions from './AccountActions'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  actionsCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
});

class Accounts extends Component {
  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      currentPage: '0',
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      searchString: '',
    }

    this.state = {
      isLoading: true,
      accountResponse: {},
      confirmDeleteActionOpen: false,
      showActionProgress: false,
    }

    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefreshTable () {
    this.setState({isLoading: true})
    const response = await AccountsApi.FetchAccountList(this.signal, this.navParams)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({accountResponse: response.ok ? response.body : {}})
    let redirect = false;
    let accountId = null;
    if (response.ok) {
      let { accounts } = response.body
      if ((accounts.length <= 1) && (!this.props.session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE])) {
        if (accounts.length === 1) {
          accountId = accounts[0].id
          redirect = true
        }
      }
    }

    this.setState({isLoading: false})
    if (redirect) {
      this.props.history.replace(this.props.location.pathname + '/details/' + accountId)
    }
  };

  handleRequestSearch (event, value) {
    let currentParams = new URLSearchParams(window.location.search)
    currentParams.set('currentPage', '0')

    if (value.length) {
      currentParams.set('searchString', value)
    } else {
      currentParams.delete('searchString')
    }

    window.location.search = currentParams.toString()
  };

  onEditClick (accountId) {
    this.props.history.push(this.props.location.pathname + '/details/' + accountId)
  }

  render () {
    const { classes, session } = this.props
    const { isLoading, accountResponse } = this.state
    const { currentPage, rowsPerPage, searchString } = this.navParams

    let { accounts, total, offset } = accountResponse
    if (accounts == null) {
      accounts = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    if (!session.isLoggedIn){
      return null
    }

    if ((accounts.length <= 1) && (!session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE])) {
      return (
        <div className={classes.root}>
          <Container maxWidth="lg" className={classes.container}>
            <PagePaper>
              <CustomCircularProgress />
            </PagePaper>
          </Container>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="lg" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Account Settings
              </Title>

              { session.permissions[SERVICES.ACCOUNT][OPERATIONS.CREATE] &&
                <Tooltip title="Add New Account">
                  <span>
                    <IconButton id="add-new-account-icon" disabled={isLoading} onClick={ () => this.props.history.push(this.props.location.pathname + '/new') }>
                      <Add/>
                    </IconButton>
                  </span>
                </Tooltip>
              }

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>

            <Box display="flex" justifyContent="flex-end">
              <SearchBox
                className={classes.formControl}
                disabled={isLoading}
                onSearch={this.handleRequestSearch.bind(this)}
                searchString={searchString}
              />
            </Box>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <CustomTable>
                <CustomTableHead>
                  <TableRow selected={true}>
                    <TableCell>Account Name</TableCell>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Contact Email</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </CustomTableHead>

                {accounts.length ? (
                  <TableBody>
                    {accounts.map((account, key) => {
                      const created_at  = new Date(account.created_at)
                      return (
                        <TableRow hover key={account.id}>
                          <TableCell onClick={this.onEditClick.bind(this, account.id)} title="Click row for details"> {account.name} </TableCell>
                          <TableCell onClick={this.onEditClick.bind(this, account.id)} title="Click row for details"> {account.contact} </TableCell>
                          <TableCell onClick={this.onEditClick.bind(this, account.id)} title="Click row for details"> {account.email} </TableCell>
                          <TableCell onClick={this.onEditClick.bind(this, account.id)} title="Click row for details"> {created_at.toLocaleString('en-US')} </TableCell>
                          <TableCell className={classes.actionsCell}>
                            <AccountActions
                              dense
                              accountId={account.id}
                              session={session}
                              onConfirmDelete={async () => await this.handleRefreshTable()}
                            />
                          </TableCell>
                        </TableRow>
                    )})}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={5}> No Accounts</TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <NavTableFooter
                  labelRowsPerPage='Accounts per page'
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  count={total}
                  rowsPerPage={parseInt(rowsPerPage, 10)}
                  currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
                />

              </CustomTable>
            )}
          </PagePaper>
        </Container>
      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(Accounts))
