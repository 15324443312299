import { combineReducers } from 'redux'
import session from './session'
import paletteType from './paletteType'
import showError from './showError'
import uploadState from './uploadState'
import passwordExpired from './passwordExpired'
import { showNotifications, notificationCount } from './notifications'

export default combineReducers({
  session,
  paletteType,
  showError,
  uploadState,
  passwordExpired,
  showNotifications,
  notificationCount
})
