import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = (theme) => ({
   title: {
     flexGrow: 1,
     color: theme.palette.secondary.main,
   },
});
 
function Title(props) {
   const { classes, children, ...other } = props;

   return (
      <Typography className={classes.title} noWrap {...other}>
         {children || 'oops!'}
      </Typography>
   );
}

export default withStyles(useStyles)(Title)