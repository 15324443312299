import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import CloseIcon from '@material-ui/icons/Close';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FiberDvrIcon from '@material-ui/icons/FiberDvr';
import VideocamIcon from '@material-ui/icons/Videocam';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MovieIcon from '@material-ui/icons/Movie';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import { DialogTitle, DialogContent, DialogActions } from '../custom/CustomDialogs'
import CustomCircularProgress from '../custom/CustomCircularProgress';
import { isFilePlayable } from '../../libs/Utils'
import ShareApi from '../../libs/EdgeVMSCloudApi/Shares'
import { setErrorMsg } from '../../actions/showError'

const useStyles = theme => ({
  root: {
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  playerwrapper: {
    width: '480px',
    height: '270px',
    margin: theme.spacing(1),
  },
  reactplayer: {
    background: 'rgba(0, 0, 0, .1)',
  },
  controlBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  menuPaper: {
    width: 300,
    marginRight: theme.spacing(1),
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  rateSelectForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  volumeSlider: {
    width: 100,
    marginRight: theme.spacing(1),
  },
  StreamListItem: {
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
});

const playbackRates = [0.25, 0.5, 1, 2, 4, 8]
const DEFAULT_PLAYBACK_RATE_IDX = 2
const DEFAULT_VOLUME = 0.5

const StreamItem = (props) => {
  const { classes, dvrName, files, selected } = props

  const handleItemClick = async (fileId) => {
    if (props.onListItemClick) {
      await props.onListItemClick(fileId)
    }
  }

  return (
    <Fragment>
      { files.map((file, key) => {
        return (
          <ListItem
            button
            key={file.id}
            className={dvrName ? classes.nested : null}
            selected={selected === file.id}
            onClick={async () => await handleItemClick(file.id)}
          >
            <ListItemIcon size={'small'}>
              { dvrName ? <VideocamIcon fontSize={'small'}/> : <MovieIcon fontSize={'small'}/> }
            </ListItemIcon>
            <ListItemText
              className={classes.StreamListItem}
              primary={file.meta && file.meta.camera_name ? file.meta.camera_name : `Stream ${key+1}`}
              title={file.meta && file.meta.camera_name ? file.meta.camera_name : `Stream ${key+1}`}
            />
          </ListItem>
        )
      })}
    </Fragment>
  )
}

const StreamListItem = withStyles(useStyles)((props) => {
  const { classes, dvrName } = props
  const [open, setOpen] = useState(true);

  const handleExplandClick = () => {
    setOpen(!open);
  }

  return (
    <Fragment>
      { dvrName ? (
        <Fragment>
          <ListItem dense button onClick={handleExplandClick}>
            <ListItemIcon size={'small'}>
              <FiberDvrIcon fontSize={'small'}/>
            </ListItemIcon>
            <ListItemText
              className={classes.StreamListItem}
              primary={dvrName}
              title={dvrName}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              <StreamItem {...props} />
            </List>
          </Collapse>
        </Fragment>
      ) : (
        <StreamItem {...props} />
      )}
    </Fragment>
)})

class VideoPlayerDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      videoSrc: null,
      tsSubsSource: null,
      showTs: true,
      hasAudio: false,
      isFullScreen: false,
      playing: false,
      wasPlaying: false,
      seeking: false,
      playingId : null,
      muted: false,
      volume: DEFAULT_VOLUME,
      playbackRate: playbackRates[DEFAULT_PLAYBACK_RATE_IDX],
      currentTime: 0.0,
      duration: 0.0,
      cardTitle: null,
      cardSubHeader : null,
      sortedPlayable: [],
      showVolumeSlider: false,
    }

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;

    // Check if the browser supports the Fullscreen API
    this.fullScreenSupported = !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || this.player.webkitRequestFullScreen);
  }

  componentDidMount() {
    if (this.fullScreenSupported) {
      // Checks if the document is currently in fullscreen mode
      this.setState({ isFullScreen: !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement) })

      // Listen for fullscreen change events (from other controls, e.g. right clicking on the video itself)
      document.addEventListener('fullscreenchange', (event) => {
        this.setState({ isFullScreen: !!(document.fullScreen || document.fullscreenElement)})
      });
      document.addEventListener('webkitfullscreenchange', () => {
        this.setState({ isFullScreen: !!document.webkitIsFullScreen})
      });
      document.addEventListener('mozfullscreenchange', () => {
        this.setState({ isFullScreen: !!document.mozFullScreen})
      });
      document.addEventListener('MSFullscreenChange', () => {
        this.setState({ isFullScreen: !!document.msFullscreenElement})
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.abortController.abort();
  }

  handleDialogEnter = async () => {
    const { share } = this.props

    // Check if we have associated playable file
    let associatedPlayable = []
    for (let idx = 0; idx < share.files.length; idx++) {
      if (share.files[idx].status === "READY" &&
          share.files[idx].encryption_type === "NONE" &&
          isFilePlayable(share.files[idx].file_name)) {
            associatedPlayable.push(share.files[idx])
      }
    }

    // Regroup playable files by DVR name for display
    let sortedPlayable = []
    for (let idx = 0; idx < associatedPlayable.length; idx++) {
      let dvrName = ""

      if (associatedPlayable[idx].meta && associatedPlayable[idx].meta.unit_name) {
        dvrName = associatedPlayable[idx].meta.unit_name
      }

      const parentIdx = sortedPlayable.findIndex((element) => element.dvrName === dvrName)

      if (parentIdx > -1) {
        sortedPlayable[parentIdx].files.push(associatedPlayable[idx])
      } else {
        const parent = {
          dvrName: dvrName,
          files: [associatedPlayable[idx]]
        }
        sortedPlayable.push(parent)
      }
    }

    // Sort by dvr name for display
    sortedPlayable.sort((a, b) => {
      if (a.dvrName && b.dvrName) {
        if (a.dvrName < b.dvrName) {
          return -1
        } else if (a.dvrName > b.dvrName) {
          return 1
        } else {
          return 0
        }
      }

      return -1
    })

    // Sort files by stream number for display
    for (let idx = 0; idx < sortedPlayable.length; idx++) {
      sortedPlayable[idx].files.sort((a, b) => {
        if (a.meta && a.meta.stream_number && b.meta && b.meta.stream_number) {
          if (a.meta.stream_number < b.meta.stream_number) {
            return -1
          } else {
            return 1
          }
        }

        return 0
      })
    }

    this.setState({sortedPlayable}, async () => {
      if (sortedPlayable.length > 0) {
        await this.playFile(sortedPlayable[0].files[0].id)
      } else {
        if (isFilePlayable(share.file_name)) {
          await this.playFile()
        }
      }
    })
  }

  playFile = async (associated) => {
    const { share, autoplay } = this.props

    this.setState({ videoSrc: null, tsSubsSource: null}, async () => {
      let playingId     = null
      let cardTitle     = null
      let cardSubHeader = null
      let tsSubsSource  = null
      let playingFile   = null

      if (associated) {
        const associatedIdx = share.files.findIndex((file) => file.id === associated)
        cardTitle     = associatedIdx > -1 ? share.files[associatedIdx].meta.unit_name : "Site Name"
        cardSubHeader = associatedIdx > -1 ? share.files[associatedIdx].meta.camera_name : "Camera Name"
        playingId     = associated
        playingFile      = share.files[associatedIdx].file_name
      } else {
        cardTitle     = share.file_name
        playingFile   = share.file_name
      }

      const videoSrc = ShareApi.GetStreamLink(share.id, playingId)

      // Look for subtitles in associated filesToDownload
      const vttFile = share.files.find((file) =>
        file.file_name.includes('.vtt') &&
        file.file_name.split('.')[0] === playingFile.split('.')[0]
      )

      if (vttFile) {
        tsSubsSource = ShareApi.GetStreamLink(share.id, vttFile.id)
      }

      if (videoSrc !== this.state.videoSrc) {
        this.setState({
          isLoading: true,
          playingId,
          cardTitle,
          cardSubHeader,
          currentTime: 0.0,
          duration: 0.0,
          playing: false,
          videoSrc,
          tsSubsSource,
        }, async () => {
            if (autoplay) {
              await this.player.play()
            } else {
              await this.player.load()
            }
          }
        )
      }
    })
  }

  handleClose = (event) => {
    if (this.props.handleClose) {
      this.props.handleClose()
    }

    // Make sure we empty the video player source
    this.player.pause();
    this.player.removeAttribute('src');
    this.player.load();

    this.setState({
      isLoading: false,
      videoSrc: null,
      tsSubsSource: null,
      showTs: true,
      hasAudio: false,
      isFullScreen: false,
      playing: false,
      wasPlaying: false,
      seeking: false,
      playingId : null,
      muted: false,
      volume: DEFAULT_VOLUME,
      playbackRate: playbackRates[DEFAULT_PLAYBACK_RATE_IDX],
      currentTime: 0.0,
      duration: 0.0,
      cardTitle: null,
      cardSubHeader : null,
      sortedPlayable: [],
      showVolumeLabel: false,
    })
  }

  handleLoadedMetadata = () => {
    const { playbackRate, volume, showTs } = this.state
    this.player.playbackRate = playbackRate
    this.player.volume = volume
    this.hasAudioCheck()

    if (this.player.textTracks && this.player.textTracks.length > 0) {
      this.player.textTracks[0].mode = showTs ? "showing" : "disabled"
    }

    this.setState({currentTime: this.player.currentTime, duration: this.player.duration})
  }

  handlePlayPause = async () => {
    const { playing } = this.state
    if (playing) {
      await this.player.pause()
    } else {
      await this.player.play()
    }
  }

  handleSeekMouseDown = async (event) => {
    const { playing } = this.state
    if (playing) {
      await this.player.pause()
    }
    this.setState({ seeking: true, wasPlaying: playing })
  }

  handleSeekChange = (event, newValue) => {
    this.setState({ currentTime: newValue})
  }

  handleSeekMouseUp = async (event) => {
    const { currentTime } = this.state
    this.player.currentTime = currentTime
  }

  handleSeeked = async (event) => {
    const { wasPlaying } = this.state

    if (wasPlaying) {
      await this.player.play()
    } else {
      this.setState({ isLoading: false })
    }

    this.setState({ wasPlaying: false, seeking: false })
  }

  handleTimeUpdate = () => {
    const {playing, seeking } = this.state

    if (playing && !seeking) {
      this.setState({currentTime: this.player.currentTime})
    }
  }

  handleError = (event) => {
    // refer to https://html.spec.whatwg.org/multipage/media.html#mediaerror for media error codes defs
    if (event && event.target && event.target.error && event.target.error.code && event.target.error.message) {
      this.props.setErrorMsg("PLAYER ERROR: Code " + event.target.error.code + " - " + event.target.error.message)
    } else {
      this.props.setErrorMsg("PLAYER ERROR: Failed to load video!")
    }
    this.handleClose()
  }

  handlePlaybackRateChange = async (event) => {
    const { playbackRate } = this.state
    const newPlaybackRate = event.target.value

    if (playbackRate !== newPlaybackRate) {
      this.setState({isLoading: true})

      this.player.playbackRate = newPlaybackRate
      this.setState({ playbackRate: newPlaybackRate })
    }
  }

  handleVolumeChange = () => {
    this.setState({ muted: this.player.muted, volume: this.player.volume })
  }

  handlePlaybackEnded = async (event) => {
    const { autoplay } = this.props
    const { sortedPlayable, playingId } = this.state

    await this.player.pause()
    this.setState({currentTime: 0})

    // autoplay mode --> find next file to play
    if (autoplay && sortedPlayable.length > 0) {
      for (let sortedIdx = 0; sortedIdx < sortedPlayable.length; sortedIdx++) {
        // Find the index of the current file playing
        const nextPlayingIdx = sortedPlayable[sortedIdx].files.findIndex((file) => file.id === playingId) + 1
        if (nextPlayingIdx > 0 && nextPlayingIdx < sortedPlayable[sortedIdx].files.length) {
          await this.playFile(sortedPlayable[sortedIdx].files[nextPlayingIdx].id)
        }
      }
    }
  }

  handleCameraChange = async (fileId) => {
    const {playingId} = this.state
    if (playingId !== fileId) {
      this.setState({isLoading: true, currentTime: 0.0})
      await this.player.pause()
      await this.playFile(fileId)
    }
  }

  handleFullscreen = () => {
    const { isFullScreen } = this.state

    if (isFullScreen) {
      // exit full screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.setState({isFullScreen: false})
    } else {
      // enter fullscreen mode
      if (this.player.requestFullscreen) {
        this.player.requestFullscreen();
      } else if (this.player.mozRequestFullScreen) {
        this.player.mozRequestFullScreen();
      } else if (this.player.webkitRequestFullscreen) { /* Safari */
        this.player.webkitRequestFullscreen();
      } else if (this.player.msRequestFullscreen) { /* IE11 */
        this.player.msRequestFullscreen();
      }
      this.setState({isFullScreen: true})
    }
  }

  hasAudioCheck = () => {
    const hasAudio = this.player.mozHasAudio ||
                      Boolean(this.player.webkitAudioDecodedByteCount) ||
                      Boolean(this.player.audioTracks && this.player.audioTracks.length)

    this.setState({ hasAudio })
  }

   handleTsClick = () => {
    const { showTs } = this.state

    this.setState({showTs: !showTs}, () => {
      if (this.player.textTracks && this.player.textTracks.length > 0) {
        this.player.textTracks[0].mode = showTs ? "disabled" : "showing"
      }
    })
  }

  playerRef = player => {
    this.player = player
  }

  render() {
    const { classes, open, share } = this.props
    const { isLoading, playing, videoSrc, currentTime, duration, playbackRate, volume, muted, hasAudio } = this.state
    const { cardTitle, cardSubHeader, sortedPlayable, showVolumeSlider, playingId, isFullScreen } = this.state
    const { tsSubsSource, showTs } = this.state

    if (!open || !share) {
      return null
    }

    const currentTimeStr = new Date(currentTime * 1000).toISOString().substr(11, 8)
    const durationStr    = new Date(duration * 1000).toISOString().substr(11, 8)

    return (
      <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.handleClose}
        onEnter={this.handleDialogEnter}
        className={classes.root}
      >
        <DialogTitle>
          Video Player
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="row">
            { playingId && sortedPlayable.length > 0 &&
              <Paper className={classes.menuPaper}>
                <List
                  dense
                  component="nav"
                  subheader={
                    <ListSubheader component="div" disableSticky>
                      {share.file_name}
                    </ListSubheader>
                  }
                >
                  {sortedPlayable.map((playable) => {
                    return(
                      <StreamListItem
                        key={playable.dvrName}
                        dvrName={playable.dvrName}
                        files={playable.files}
                        selected={playingId}
                        onListItemClick={async (fileId) => this.handleCameraChange(fileId)}
                      />
                  )})}
                </List>
              </Paper>
            }

            <Card id="player-card">
              <CardHeader
                avatar={
                  <Avatar fontSize={'small'} aria-label="Video Player">
                    <OndemandVideoIcon fontSize={'small'}/>
                  </Avatar>
                }
                title={cardTitle}
                subheader={cardSubHeader}
              />
              <div className={classes.playerwrapper}>
                <video
                  ref={this.playerRef}
                  className={classes.reactplayer}
                  width='100%'
                  height='100%'
                  src={videoSrc}
                  muted={muted}
                  controls={isFullScreen}
                  controlsList={isFullScreen ? "nodownload noremoteplayback" : null}
                  onTimeUpdate={this.handleTimeUpdate}
                  onError={this.handleError}
                  onWaiting={() => this.setState({isLoading: true})}
                  onPlaying={() => this.setState({isLoading: false})}
                  onRateChange={() => this.setState({isLoading: false})}
                  onLoadedData={() => this.setState({isLoading: false})}
                  onLoadedMetadata={this.handleLoadedMetadata}
                  onProgress={this.hasAudioCheck}
                  onPlay={() => {
                    this.player.playbackRate = playbackRate // for IE11 support!
                    this.setState({playing: true})
                  }}
                  onPause={() => this.setState({playing: false})}
                  onSeeking={() =>this.setState({ isLoading: true, seeking: true })}
                  onSeeked={async () => await this.handleSeeked()}
                  onEnded={this.handlePlaybackEnded}
                  onVolumeChange={this.handleVolumeChange}
                >
                  { tsSubsSource &&
                    <track label="Timestamps" kind="subtitles" srcLang="en" src={tsSubsSource} default/>
                  }
                </video>
              </div>
              <CardActions>
                <Box className={classes.controlBox}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
                      <Tooltip title={playing ? "Pause"  : "Play"}>
                        <IconButton id="play-pause-button" onClick={this.handlePlayPause}>
                          {playing ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                        </Tooltip>
                      <Typography>
                        {`${currentTimeStr}/${durationStr}`}
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Fade in={showVolumeSlider}
                        onMouseEnter={() => this.setState({showVolumeSlider: true})}
                        onMouseLeave={() => this.setState({showVolumeSlider: false})}
                      >
                        <Slider
                          id="volume-slider"
                          className={classes.volumeSlider}
                          value={muted ? 0 : volume}
                          min={0}
                          max={1}
                          step={.01}
                          onChange={(event, newValue) => {
                            this.player.volume = newValue
                            this.setState({muted: muted && newValue > 0 ? false : muted, volume: newValue})
                          }}
                          valueLabelDisplay={"on"}
                          valueLabelFormat={`${muted ? 0 : parseInt(volume*100)}%`}
                        />
                      </Fade>
                      <Tooltip title={muted ? "Unmute"  : "Mute"} disableHoverListener={!hasAudio}>
                        <span>
                          <IconButton
                            id="muted-button"
                            size="small"
                            disabled={!hasAudio}
                            onClick={() => this.setState({muted: !this.state.muted})}
                            onMouseEnter={() => this.setState({showVolumeSlider: true})}
                            onMouseLeave={() => this.setState({showVolumeSlider: false})}
                          >
                            {hasAudio ? muted ? <VolumeOffIcon fontSize="inherit"/> : <VolumeUpIcon fontSize="inherit"/> : <VolumeOffIcon fontSize="inherit"/>}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                    <Tooltip title={showTs ? "Hide Timestamp"  : "Show Timestamp"} disableHoverListener={!tsSubsSource}>
                      <span>
                        <IconButton
                          id="ts-button"
                          size="small"
                          disabled={!tsSubsSource}
                          onClick={this.handleTsClick}
                        >
                          {showTs ? <AlarmOffIcon fontSize="inherit"/> : <AlarmOnIcon fontSize="inherit"/>}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={isFullScreen ? "Exit Full Screen"  : "Full Screen"} disableHoverListener={!this.fullScreenSupported}>
                      <span>
                        <IconButton
                          id="fullScreen-button"
                          size="small"
                          disabled={!this.fullScreenSupported}
                          onClick={this.handleFullscreen}
                        >
                          {isFullScreen ? <FullscreenExitIcon fontSize="inherit"/> : <FullscreenIcon fontSize="inherit"/>}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Select
                      id="playback-rate-select"
                      title={"Playback Rate"}
                      value={playbackRate}
                      onChange={async (event) => await this.handlePlaybackRateChange(event)}
                    >
                      {playbackRates.map((value, key) => {
                        return <MenuItem dense key={key} value={value}>{`x ${value}`}</MenuItem>
                      })}
                    </Select>
                  </Box>

                  <Box m={1}>
                    <Slider
                      id="seek-slider"
                      value={currentTime}
                      min={0}
                      max={duration}
                      step={0.005}
                      onMouseDown={async (event) => await this.handleSeekMouseDown(event)}
                      onChange={this.handleSeekChange}
                      onMouseUp={async (event) => await this.handleSeekMouseUp(event)}
                    />
                  </Box>
                </Box>
              </CardActions>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            id="close-button"
            variant="outlined"
            color="primary"
            onClick={this.handleClose}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogActions>

        <Backdrop className={classes.backdrop} open={isLoading}>
          <CustomCircularProgress/>
        </Backdrop>
      </Dialog>
    );
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setErrorMsg: (errorMessage) => dispatch(setErrorMsg(errorMessage)),
  }
}

export default connect(null, mapDispatchToProps)(withStyles(useStyles)(VideoPlayerDialog))

