import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie'
import Container from '@material-ui/core/Container';
import SyncIcon from '@material-ui/icons/Sync';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import UserApi from '../../../../libs/EdgeVMSCloudApi/Users'
import Title from '../../../custom/Title'
import PagePaper from '../../../custom/PagePaper'
import CustomTable from '../../../custom/CustomTable';
import CustomTableHead from '../../../custom/CustomTableHead';
import CustomCircularProgress from '../../../custom/CustomCircularProgress';
import { GetNavParamsFromLocation } from '../../../../libs/Utils'
import { NavTableFooter } from '../../../table'
import ROWS_PER_PAGE_OPTIONS from '../../../../constants/navParams'
import { COOKIES } from '../../../../constants/cookieDefs'
import { downloadBlob } from '../../../../libs/Utils'
import { ConfirmAction, DownloadActivityLogsDialog } from '../../../dialogs'

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  descriptionCell: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

class ActivityLog extends Component {
  constructor (props) {
    super(props)

    const { cookies } = props;
    const DefaultNavParams = {
      rowsPerPage: cookies.get(COOKIES.ROWS_PER_PAGE.name),
      nextKey: null,
      prevKey: null
    }

    this.state = {
      isLoading: true,
      response: {},
      openSelectDateDialog: false,
      isDownloading: false,
    }

    this.navParams = GetNavParamsFromLocation(DefaultNavParams)
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async componentDidMount () {
    await this.handleRefreshTable()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  async handleRefreshTable () {
    this.setState({isLoading: true})

    const response = await UserApi.GetUserActivities(this.signal, this.navParams)
    if (response.error && response.error.name === "AbortError") {
      return;
    }

    this.setState({response: response.ok ? response.body : {}})
    this.setState({isLoading: false})
  };

  handleDownloadClick = () => {
    this.setState({openSelectDateDialog: true})
  }

  handleSelectDateDialogClose = async (answer, selectedDate) => {
    this.setState({openSelectDateDialog: false, isDownloading: true}, async () => {
      if (answer && answer === "ok") {
        const response = await UserApi.DownloadActivityLog(this.signal, selectedDate)
        if (response.error && response.error.name === "AbortError") {
          return;
        }

        if (response.ok) {
          downloadBlob(new Blob([response.text], {type : 'text/csv;charset=utf-8'}), response.filename)
        }
      }
      this.setState({isDownloading: false})
    })
  }

  handleDownloadCancel = () => {
    this.setState({isDownloading: false})
  }

  render () {
    const { classes } = this.props
    const { isLoading, response, isDownloading } = this.state
    const { currentPage, rowsPerPage } = this.navParams

    let { activities, total, offset, paging } = response
    let after = null, before = null

    if (activities == null) {
      activities = []
    }

    if (total == null) {
      total = 0
    }

    if (offset == null) {
      offset = 0
    }

    if (paging == null) {
      paging = {}
    } else {
      after = paging.after
      before = paging.before
    }

    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.container}>
          <PagePaper>
            <Toolbar>
              <Title component="h1" variant="h4">
                Activity Log
              </Title>

              <Tooltip title="Download">
                <span>
                  <IconButton id="download-icon" disabled={isLoading} onClick={this.handleDownloadClick}>
                    <SaveAltIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Refresh">
                <span>
                  <IconButton id="refresh-icon" disabled={isLoading} onClick={this.handleRefreshTable.bind(this)}>
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>

            </Toolbar>

            { isLoading ? (
              <CustomCircularProgress />
            ) : (
              <CustomTable>
                <CustomTableHead>
                  <TableRow selected={true}>
                    <TableCell>Date</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Activity</TableCell>
                      <TableCell>Description</TableCell>
                  </TableRow>
                </CustomTableHead>

                {activities.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={4}>No activity log</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {activities.map((activity, key) => {
                      return (
                        <TableRow hover key={key}>
                          <TableCell title={activity.description} className={classes.tableCell} component="th" scope="row">{(new Date(activity.timestamp)).toLocaleString('en-US')}</TableCell>
                          <TableCell title={activity.description} className={classes.tableCell}>{activity.name}</TableCell>
                          <TableCell title={activity.description} className={classes.tableCell}>{activity.activity}</TableCell>
                          <TableCell title={activity.description} className={classes.descriptionCell}>{activity.description}</TableCell>
                        </TableRow>
                    )})}
                  </TableBody>
                )}

               <NavTableFooter
                 variant='keyset'
                 labelRowsPerPage='Entries per page'
                 rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                 count={total}
                 nextkey={after}
                 prevkey={before}
                 rowsPerPage={parseInt(rowsPerPage, 10)}
                 currentPage={total > 0 ? parseInt(currentPage, 10) : 0}
               />
              </CustomTable>
            )}
          </PagePaper>
        </Container>

        <DownloadActivityLogsDialog
          open={this.state.openSelectDateDialog}
          handleClose={this.handleSelectDateDialogClose}
        />

        <ConfirmAction
          allowProgressCancel
          open={isDownloading}
          showProgress={true}
          progressMsg="Downloading activity logs"
          progressCancelCallback={this.handleDownloadCancel}
        />

      </div>
    )
  }
}

export default withCookies(withStyles(useStyles)(ActivityLog))
