import LICENSE_DEFS from '../constants/licenseDefs'

function GetFileSize(bytes, si=false) {
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
  }
/*
  var units = si
      ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
*/

// Always use SI notation for file size to avoid confusion
var units = ['KB','MB','GB','TB','PB','EB','ZB','YB'];

var u = -1;
  do {
      bytes /= thresh;
      ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

function GetLicenseSetting(licenseSettingsList, key) {
  let license = null

  if (Array.isArray(licenseSettingsList)) {
    for (let index = 0; index < licenseSettingsList.length; index++) {
      if (licenseSettingsList[index].key === key) {
        license = licenseSettingsList[index];
        break;
      }
    }
  }

  return license;
}

function GetLicenseDefinition(key) {
  let licenseDef = null

  for (let index = 0; index < LICENSE_DEFS.length; index++) {
    if (LICENSE_DEFS[index].key === key) {
      licenseDef = LICENSE_DEFS[index];
      break;
    }
  }

  return licenseDef;
}

function HexToRgb(hexString, alphaNum) {
  var strippedStr = hexString.replace('#', '');
  var hexInt = parseInt(strippedStr, 16);
  var r = (hexInt >> 16) & 0xff;
  var g = (hexInt >> 8) & 0xff;
  var b = hexInt & 0xff;

  return "rgba(" + r + "," + g + "," + b + "," + alphaNum + ")"
}

function GetNavParamsFromLocation(DefaultNavParams) {
  const currentParams = new URLSearchParams(window.location.search)
  let retVal = {...DefaultNavParams}

  Object.entries(DefaultNavParams).forEach(([key, dfltValue]) => {
    if (currentParams.has(key)) {
      retVal[key] = currentParams.get(key)
    }
  })

  return retVal
}

function BuildErrorMsg(error) {
  return error.name + " - " + error.message;
}

function encodeRFC5987ValueChars(str) {

    // Note that although RFC3986 reserves "!", RFC5987 does not,
    // so we do not need to escape it
    // i.e., %27 %28 %29 %2a (Note that valid encoding of "*" is %2A
    // which necessitates calling toUpperCase() to properly encode)
    // The following are not required for percent-encoding per RFC5987,
    // so we can allow for a little better readability over the wire: |`^
    return encodeURIComponent(str).replace(/['()*]/g, c => "%" + c.charCodeAt(0).toString(16)).replace(/%(7C|60|5E)/g, (str, hex) => String.fromCharCode(parseInt(hex, 16)));
}

function SetPasswordCustomValidity(passwordId, confirmId) {
  const password1 = document.getElementById(passwordId);
  const password2 = document.getElementById(confirmId);

  if (!password1.value) {
    password1.setCustomValidity('Password cannot be empty!');
  }
  else if (!password2.value) {
    password2.setCustomValidity('Password cannot be empty!');
  }
  else if (password1.value !== password2.value) {
    password1.setCustomValidity('Passwords must match');
    password2.setCustomValidity('Passwords must match');
  } else {
    password1.setCustomValidity('');
    password2.setCustomValidity('');
  }
}

function isFilePlayable(file) {
  const extensions = ['.mp4']

  for (let idx = 0; idx < extensions.length; idx++) {
    if (file.includes(extensions[idx])) {
      return true
    }
  }

  return false
}

function isSharePlayable(share) {
  if (share && share.status === "complete" && share.encryption_type === "NONE") {
    if (isFilePlayable(share.file_name)) {
      return true
    } else {
      // Check if associated files are playable
      if (share.files) {
        for (let idx = 0; idx < share.files.length; idx++) {
          if (isFilePlayable(share.files[idx].file_name)) {
            return true
          }
        }
      }
    }
  }

  return false
}

function hasAssociatedFileType(share, fileType) {
  if (share.files) {
    for (let idx = 0; idx < share.files.length; idx++) {
      if (share.files[idx].file_name.includes(fileType)) {
        return true
      }
    }
  }

  return false
}

function downloadBlob(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob && window.Blob)
  {
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = window.URL.createObjectURL(blob)
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }
}

export {
  GetFileSize,
  GetLicenseSetting,
  GetLicenseDefinition,
  HexToRgb,
  GetNavParamsFromLocation,
  BuildErrorMsg,
  encodeRFC5987ValueChars,
  SetPasswordCustomValidity,
  isFilePlayable,
  isSharePlayable,
  hasAssociatedFileType,
  downloadBlob,
}