import appConfig from  '../../config/AppConfig.js'
import BaseApi from './Common'
import { encodeRFC5987ValueChars } from '../Utils'

const baseUrl = appConfig.apiUri + '/api/videoxchange/cases'

async function FetchCaseList(signal, navParams) {
  let url = baseUrl

  if (navParams) {
    const { currentPage, rowsPerPage, order, orderBy, searchString } = navParams
    const offset = (currentPage * rowsPerPage)
    url += '?offset=' + offset + '&limit=' + rowsPerPage + '&sorting_column='+ orderBy + '&sorting_order=' + order;

    if (searchString.length > 0) {
      url += '&search_value=' + encodeRFC5987ValueChars(searchString)
    }
  }

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function FetchCase(signal, id) {
  let url = baseUrl + '/' + id

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function DeleteCase(signal, toDelete) {
  const url = baseUrl + '/' + toDelete

  return await BaseApi.DoFetch(signal, url, 'DELETE')
}

async function SendCaseEmail (signal, toEmail) {
  const url = baseUrl + '/email/reset/' + toEmail;

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function FetchCaseStats(signal) {
  let url = baseUrl + '/stats'

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function CreateCase(signal, caseObj) {
  return await BaseApi.DoFetch(signal, baseUrl, 'POST', caseObj)
}

async function UpdateCase(signal, caseObj) {
  let url = baseUrl + '/' + caseObj.id

  return await BaseApi.DoFetch(signal, url, 'PUT', caseObj)
}

async function FetchSettings(signal) {
  let url = baseUrl + '/settings'

  return await BaseApi.DoFetch(signal, url, 'GET')
}

async function DownloadAudit(signal, caseId) {
  let url = baseUrl + '/' + caseId +'/audit/download'

  return await BaseApi.DoFetch(signal, url, 'GET')
}

const exportsCase = {
  FetchCaseList,
  FetchCase,
  DeleteCase,
  SendCaseEmail,
  FetchCaseStats,
  CreateCase,
  UpdateCase,
  FetchSettings,
  DownloadAudit,
};
export default exportsCase;