const SERVICES = {
  ANY: 'any',
  ACCOUNT: 'account',
  CASE: 'case',
  FACE: 'face',
  LICENSE: 'license',
  USER: 'user',
  MAINTENANCE: 'maintenance',
  USERACTIVITY: 'useractivity'
}

const OPERATIONS = {
  ANY: 'any',
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
}

export {
  SERVICES,
  OPERATIONS
}
